import React from "react";
import { capitalizeFirstLetter, dateValue, formatDate } from "./FormatDate";
import PhotoData from "./PhotoData";
import { Toolbar, Tooltip } from "@mui/material";
import moment from "moment/moment";

const CommercialDetails = ({ assetDetails, imageUrl, NoImage , handleBottomTab, setPhotoDataTab}) => {

  let numb = 0
  if (assetDetails?.lease_status?.startsWith("expiring")) {
    // Match a number within the string "expiring in X days"
    const match = assetDetails?.lease_status.match(/\d+/);

    if (match) {
      numb = parseInt(match[0], 10);
    }
  }
  return (
    <>
      <div className="bg-light-10 rounded-[2px] lg:w-[434px] h-fit pt-3 mb-2 pb-4 px-2 overflow-hidden">
        <div className="flex flex-row-reverse justify-between pl-2 gap-6  text-left max-md:flex-col">
          <PhotoData imageUrl={imageUrl} NoImage={NoImage} handleBottomTab={handleBottomTab} setPhotoDataTab={setPhotoDataTab} title={assetDetails?.asset_no} />
          <div className="flex flex-col text-left">
            <div className="  w-[160px] max-sm:w-full ">
              {/* <Tooltip title="Asset No" placement="top" arrow> */}
              <span className="text-dark-6 text-[11px]" >Asset No.</span>
              {/* </Tooltip> */}
              <span title="Asset Number" className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.asset_no || 'N/A'}
              </span>
            </div>
            <div className="  w-[160px] max-sm:w-full ">
              {/* <Tooltip title="Model" placement="top" arrow> */}
              <span className="text-dark-6 text-[11px]">Model</span>
              {/* </Tooltip> */}
              <span title="Model Details" className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.model || 'N/A'}
              </span>
            </div>
            <div className="  w-[160px] max-sm:w-full">
              {/* <Tooltip title="Serial Number" placement="top" arrow> */}
              <span className="text-dark-6 text-[11px]">Serial No</span>
              {/* </Tooltip> */}
              <span title="Serial Number" className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.serial_no || 'N/A'}
              </span>
            </div>
          
          </div>
        </div>
        <div className="px-2  space-y- mt-0 gap-3">
          {assetDetails?.device_no && (
            <>
              <div className="flex justify-between max-sm:flex-col">
                {/* isntalled device number */}
                <div className="flex flex-col text-left  w-[160px] max-sm:w-full">
                  {/* <Tooltip title="Tracking Device Id" placement="top" arrow> */}

                  <span className="text-dark-6 text-[11px] ">
                    Tracking Device ID
                  </span>
                  {/* </Tooltip> */}
                  <span title="Installed device number" className={`border rounded-[2px] font-bold text-[11px] h-[27px]  items-center bg-white flex justify-start px-2 
                    ${assetDetails?.device_no ? 'text-blue-2':'text-dark-6'}
                    `}>
                    {assetDetails?.device_no ? assetDetails?.device_no : "No device installed"}
                  </span>
                </div>
                {/* installation_date */}
                <div className="flex flex-col text-left  ">
                  {/* <Tooltip title="Tracking Device Installation Date" placement="top" arrow> */}

                  <span className="text-dark-6 text-[11px] ">
                    Tracking Device Installation Date
                  </span>
                  {/* </Tooltip> */}
                  <span title="Device intsallation date" className="border rounded-[2px] overflow-hidden w-[207px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                    {formatDate(assetDetails?.installation_date) || "N/A"}
                  </span>
                </div>
              </div>
            </>
          )}

          <div className="flex justify-between items-center max-sm:flex-col  py-1">
            {/* <div className="flex flex-col text-left  w-[160px] max-md:w-full">
              <Tooltip title="Serial Number" placement="top" arrow>

              <span className="text-dark-6 text-[11px]">Serial No.</span>
              </Tooltip>
              <span className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.serial_no || 'N/A'}
              </span>
            </div> */}
              <div className="flex flex-col text-left  w-[160px] max-md:w-full">
              {/* <Tooltip title="Category" placement="top" arrow> */}
              <span className="text-dark-6 text-[11px]">Category</span>
              {/* </Tooltip> */}
              <span title="Category of the asset" className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.category || 'N/A'}
              </span>
            </div>

            <div className="flex flex-col text-left  w-[207px] max-md:w-full">
              {/* <Tooltip title="ANSI/CD" placement="top" arrow> */}

              <span className="text-dark-6 text-[11px]">ANSI/CD</span>
              {/* </Tooltip> */}
              <span title="ANSI or CD" className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.config_data?.ansi_or_new ? assetDetails?.config_data?.ansi_or_new.toUpperCase() : 'N/A'}
              </span>
            </div>
          </div>

          <div className="flex justify-between py-1 max-sm:flex-col">
            <div className="flex flex-col text-left  w-[160px] max-md:w-full">
              {/* <Tooltip title="Drive Train" placement="top" arrow> */}

              <span className="text-dark-6 text-[11px]">Drive Train</span>
              {/* </Tooltip> */}
              <span title="Drive train" className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2">
                {assetDetails?.config_data?.battery_type || "N/A"}
              </span>
            </div>

            <div className="flex flex-col text-left  w-[207px] max-md:w-full">
              {/* <Tooltip title="Place of Import" placement="top" arrow> */}
              <span className="text-dark-6 text-[11px]">Place of Import</span>
              {/* </Tooltip> */}
              <span title="Place from where the asset is imported" className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.site_location || 'N/A'}
              </span>
            </div>
          </div>

          <div className="flex justify-between py-1 max-sm:flex-col">
            <div className="flex flex-col text-left  w-[160px] max-sm:w-full">
              {/* <Tooltip title="Port of Clearance" placement="top" arrow> */}

              <span className="text-dark-6 text-[11px]">
                Port of Clearance
              </span>
              {/* </Tooltip> */}
              <span title="Port of clearance" className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.revnew_data?.port_of_clearance || "N/A"}
              </span>
            </div>

            <div className="flex flex-col text-left  w-[207px] max-sm:w-full">
              {/* <Tooltip title="Year of Manufacturing" placement="top" arrow> */}

              <span className="text-dark-6 text-[11px]">
                Year of Manufacturing
              </span>
              {/* </Tooltip> */}
              <span title="Year of manufacturing " className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.yom || 'N/A'}
              </span>
            </div>
          </div>

          <div className="flex justify-between py-1 max-sm:flex-col ">
            <div className="flex flex-col text-left  w-[160px] max-sm:w-full">
              {/* <Tooltip title="Drive Type" placement="top" arrow> */}

              <span className="text-dark-6 text-[11px]">Wheel Drive Type</span>
              {/* </Tooltip> */}
              <span title="Wheel drive type" className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.config_data?.two_or_four_wd || "N/A"}
              </span>
            </div>

            <div className="flex flex-col text-left  w-[207px] max-sm:w-full">
              {/* <Tooltip title="Make" placement="top" arrow> */}

              <span className="text-dark-6 text-[11px]">Make</span>
              {/* </Tooltip> */}
              <span title="Make or brand" className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.make || 'N/A'}
              </span>
            </div>
          </div>

          <div className="flex justify-between py-1 max-sm:flex-col">
            <div className="flex flex-col text-left  w-[160px] max-sm:w-full">
              {/* <Tooltip title="Purchased From" placement="top" arrow> */}

              <span className="text-dark-6 text-[11px]">Purchased From</span>
              {/* </Tooltip> */}
              <span title="Purchased from" className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.purchased_from || 'N/A'}
              </span>
            </div>

            <div className="flex flex-col text-left  w-[207px] max-sm:w-full">
              {/* <Tooltip title="Tracker" placement="top" arrow> */}

              <span className="text-dark-6 text-[11px]">Tracker</span>
              {/* </Tooltip> */}
              <span title="Current status of the tracker" 
                className={`border rounded-[2px] font-bold text-[11px] h-[27px]  items-center bg-white flex justify-start px-2 
              ${assetDetails?.device_id ? "text-blue-2" : "text-dark-6"}
              `}
              >
                {assetDetails?.device_id ? "Installed" : "Not Installed"}
              </span>
            </div>
          </div>

          <div className="flex justify-between py-1 max-sm:flex-col">
          <div className="flex justify-between py-1 max-sm:flex-col">
            <div className="flex flex-col text-left  w-[160px] max-sm:w-full">
              {/* <Tooltip title="Lease Status" placement="top" arrow> */}

              <span className="text-dark-6 text-[11px]">Status</span>
              {/* </Tooltip> */}
              <span
              title="Lease Status Info"
                className={`border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 
              ${
                assetDetails?.lease_status === "inactive"
                  ? "text-slate-600"
                  : assetDetails?.lease_status === "active"
                  ? "text-green-500"
                  : assetDetails?.lease_status === "expired"
                  ? "text-red-600"
                  : "text-blue-600"
              }
              `}
              >
                {assetDetails?.lease_status === "inactive" || assetDetails?.lease_status === "never assigned" ? "Free" : assetDetails?.lease_status === "expired" ? "Exceeded" : assetDetails?.lease_status?.startsWith(
                        "expiring"
                      ) ? `Active ${numb} days` : "Active" }
              </span>
            </div>
        
          </div>
            <div className="flex flex-col text-left  w-[207px] max-sm:w-full">
              {/* <Tooltip title="Capacity" placement="top" arrow> */}

              <span className="text-dark-6 text-[11px]">Capacity</span>
              {/* </Tooltip> */}
              <span title="Capacity of the machine" className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.capacity}kg-{assetDetails?.hieght_machine}ft
              </span>
            </div>
         
          </div>

          <div className="flex justify-between py-1">
          <div className="flex flex-col text-left  w-[160px] max-sm:w-full">
              {/* <Tooltip title="Machine Sold" placement="top" arrow> */}

              <span className="text-dark-6 text-[11px]">Machine Sold</span>
              {/* </Tooltip> */}
              <span title="Machine sales overview" className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.is_sold  ? "Yes" : "No"}
              </span>
            </div>
            <div className="flex flex-col text-left  w-[207px] max-sm:w-full">
              {/* <Tooltip title="Machine Sold Date" placement="top" arrow> */}

              <span className="text-dark-6 text-[11px]">Machine Sold Date</span>
              {/* </Tooltip> */}
              <span title="Machhine sold date" className="border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.asset_sold_date  ? moment(assetDetails?.asset_sold_date).format("DD-MM-YYYY") : ""}
              </span>
            </div>
          </div>

         
          <div className="flex justify-between py-1 ">
            <div className="flex flex-col text-left  w-[160px] max-sm:w-full">
              {/* <Tooltip title="Used Machine or New Machine" placement="top" arrow> */}

              <span className="text-dark-6 text-[11px]">Used or New</span>
              {/* </Tooltip> */}
              <span title="Used or New Machine"
                className={`border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 
             
              `}
              >
                {assetDetails?.config_data?.used_or_new? assetDetails?.config_data?.used_or_new.toUpperCase() : 'N/A'}
              </span>
            </div>
            <div className="flex flex-col text-left  w-[207px] max-sm:w-full">
              {/* <Tooltip title="Date of Creation" placement="top" arrow> */}

              <span className="text-dark-6 text-[11px]">Date of Creation</span>
              {/* </Tooltip> */}
              <span
              title="Date of Creation"
             
                className={`border overflow-hidden rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2
             
              `}
              >
                {dateValue(assetDetails?.created_at) || 'N/A'}
              </span>
            </div>
          </div>
            {/* description */}
            <div className="flex flex-col text-left  w-full max-sm:w-full">
              {/* <Tooltip title="Description" placement="top" arrow> */}

              <span className="text-dark-6 text-[11px]">Description</span>
              {/* </Tooltip> */}
              <span
              title="Description"
                className={`border overflow-hidden rounded-[2px] font-bold text-[11px] h-fit text-dark-6 items-center bg-white flex justify-start px-2
             
              `}
              >
                {assetDetails?.description || 'N/A'}
              </span>
            </div>
        </div>
      </div>
    </>
  );
};

export default CommercialDetails;
