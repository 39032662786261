import React from 'react'
import logo from "../../assets/images/logo.png";
import { capitalize } from '@mui/material';
const SalesNavbar = () => {

  const user = sessionStorage.getItem('sales_name')
  return (
    <div>
         <header className="bg-white shadow-md p-2 flex justify-between items-center">
         <div className={`flex justify-start items-center transition-all translate-x-1 duration-300 ease-in-out  w-[300px] `}>
              <img className="w-8  mb-1" src={logo} alt="logo" />
              <p className=" font-semibold  text-dark-6 text-base">
                Asset <span className="font-semibold">Tracker</span>
              </p>
            </div>
          <div className="flex items-center space-x-4 text-xs">
            <span className="text-gray-700">Welcome, {capitalize(user)}</span>
          </div>
        </header>
 
    </div>
  )
}

export default SalesNavbar