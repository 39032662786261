import React, { useState } from "react";

function ConfigDetails({
  assetConfigDetails,
  setAssetConfigDetails,
  updateAssetConfig,
  setUpdateAssetConfig,
}) {
  return (
    <>
      <div className="my-5  ">
        <button className="text-[#5a5959] px-4 py-2 underline text-xs">
          Configuration Details
        </button>
        <div className="border  p-10 mt-2 text-xs gap-4 bg-white dark:bg-slate-900 grid grid-cols-1 md:grid-cols-4 ">
          {/* Used or New */}
          <div   title="Select user of new machine"  className="flex-1 w-[200px]">
            <label className="font-bold">Used or New</label>
            <select
              value={assetConfigDetails?.used_or_new}
              placeholder="Used or New"
              className=" text-black focus:outline-none focus:ring-1 focus:ring-blue-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  used_or_new: e.target.value,
                });
                setUpdateAssetConfig({
                  ...updateAssetConfig,
                  used_or_new: e.target.value,
                });
              }}
            >
            <option value="">Select </option>
              <option value="Used">Used</option>
              <option value="New">New</option>
            </select>
          </div>

          {/* Ansi/ce */}
          <div  title="Select ANSI or CE" className="flex-1 w-[200px] ">
            <label className="font-bold">ANSI/CE</label>
            <select
              type="text"
              value={assetConfigDetails?.ansi_or_new || ""}
              placeholder="ansi or new"
              className=" text-black focus:outline-none focus:ring-1 focus:ring-blue-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  ansi_or_new: e.target.value,
                });

                setUpdateAssetConfig({
                  ...updateAssetConfig,
                  ansi_or_new: e.target.value,
                });
              }}
            >
              <option value="">Select Standards</option>
              <option value="ansi">ANSI</option>
              <option value="ce">CE</option>
            </select>

            
          </div>
          {/* Ship Owner */}
          {/* <div title="Machine Ownership Ship Type" className="flex-1 w-[200px] ">
            <label className="font-bold">Machine Ownership Ship Type</label>
            <select
              type="text"
              value={assetConfigDetails?.machine_ownership_type}
              className=" text-black focus:outline-none focus:ring-1 focus:ring-blue-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  machine_ownership_type: e.target.value,
                });
                setUpdateAssetConfig({
                  ...updateAssetConfig,
                  machine_ownership_type: e.target.value,
                });
              }}
            >
              <option value="">Please select ownership </option>
              <option value="Rental">Rental</option>
              <option value="Sands">Sands</option>
              <option value="Retail">Retail</option>
              <option value="Sold">Sold</option>
            </select>
          </div> */}
          {/* DIESEL/BATTERY */}
          <div title="Drive Train" className="flex-1 w-[200px] ">
            <label className="font-bold">Drive Type </label>
            <select
              type="text"
              value={assetConfigDetails?.battery_type}
              className=" text-black focus:outline-none focus:ring-1 focus:ring-blue-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  battery_type: e.target.value,
                });
                setUpdateAssetConfig({
                  ...updateAssetConfig,
                  battery_type: e.target.value,
                });
              }}
            >
              <option value="">Select Options </option>
              <option value="Diesel">Diesel</option>
              <option value="Battery">Battery</option>
            </select>
          </div>
          {/* Engine Sr No */}
          <div title="Engine Serial No"  className="flex-1 w-[200px] ">
            <label className="font-bold">Engine Serial No</label>
            <input
              type="text"
              value={assetConfigDetails?.engine_serial_no}
              placeholder="Enter engine serial number"
              className="text-black focus:outline-none focus:ring-1 focus:ring-blue-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  engine_serial_no: e.target.value,
                });
                {
                  setUpdateAssetConfig({
                    ...updateAssetConfig,
                    engine_serial_no: e.target.value,
                  });
                }
              }}
            />
          </div>
          {/* 2WD / 4WD */}
          <div title="Wheel Drive Type"  className="flex-1 w-[200px] ">
            <label className="font-bold">Wheel Drive Train</label>
            <select
              type="text"
              value={assetConfigDetails.two_or_four_wd}
              className="text-black focus:outline-none focus:ring-1 focus:ring-blue-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  two_or_four_wd: e.target.value,
                });
                setUpdateAssetConfig({
                  ...updateAssetConfig,
                  two_or_four_wd: e.target.value,
                });
              }}
            >
              <option value="">Select wheels</option>
              <option value="2WD">2WD</option>
              <option value="4WD">4WD</option>
            </select>
          </div>
          {/* Others */}
          <div  title="Accessories (if any)" className="flex-1 w-[200px] ">
            <label className="font-bold">Accessories (if any)</label>
            <input
              type="text"
              value={assetConfigDetails?.accessories}
              placeholder="Accessories"
              className="text-black focus:outline-none focus:ring-1 focus:ring-blue-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {

                const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
                if (!emojiRegex.test(e.target.value)) {
                  setAssetConfigDetails({
                    ...assetConfigDetails,
                    accessories: e.target.value,
                  });
                  setUpdateAssetConfig({
                    ...updateAssetConfig,
                    accessories: e.target.value,
                  });

                } else {
                  e.target.value = e.target.value.replace(emojiRegex, "");
                }
              }}
            />
          </div>
          {/* Tires */}
          <div  title="Tires" className="flex-1 w-[200px] ">
            <label className="font-bold">Tires</label>
            <select
              type="text"
              value={assetConfigDetails.tyres}
              className="text-black focus:outline-none focus:ring-1 focus:ring-blue-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  tyres: e.target.value,
                });
                setUpdateAssetConfig({
                  ...updateAssetConfig,
                  tyres: e.target.value,
                });
              }}
            >
             <option value="">Select type of tyres</option>
              <option value='Airfiled'>Air filled</option>
              <option value='Solid'>Solid</option>
              <option value='Foam Filled'>Foam filled</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfigDetails;
