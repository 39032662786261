import React from "react";
import { Tooltip } from "@mui/material";
import { capitalizeFirstLetter } from "./FormatDate";
import Loading from "../../../Loading";



const AssetHeader = ({ assetDetails, loading }) => {

  let numb = 0
  if (assetDetails?.lease_status?.startsWith("expiring")) {
    // Match a number within the string "expiring in X days"
    const match = assetDetails?.lease_status.match(/\d+/);

    if (match) {
      numb = parseInt(match[0], 10);
    }
  }


  
  return (
    <>
      <div className="items-center overflow-hidden text-justify text-xs w-full gap-5 mt-2 rounded pb-1 flex justify-between max-md:flex-col">
        {/* Frame 1 */}
        <div className="bg-white border overflow-hidden py-0 px-3 w-full lg:w-[200px] shadow-md text-[10px] h-[100px] rounded-[6px]">
          <div  title={`Machine No: ${assetDetails?.asset_no || "N/A"}`} className="py-2">
            <span className="text-gray-500">Machine No.</span>
        
              <span 
                className={`block font-semibold text-xs overflow-hidden ${
                  assetDetails.device_no ? "text-green-1 font-extrabold" : ""
                }`}
              >
                {assetDetails?.asset_no}
              </span>
         
          </div>
          <div title={`Make: ${assetDetails?.make || "N/A"}`} className="py-2 ">
            <span className="text-gray-500">Make</span>
            
              <span  className="block font-semibold text-xs">
                {assetDetails?.make}
              </span>
            
          </div>
        </div>

        {/* Frame 2 */}
        <div className="bg-white overflow-hidden b0order py-0 px-3 w-full lg:w-[200px] shadow-md text-[10px] h-[100px] rounded-[6px]">
          <div  title={`Model No: ${assetDetails?.model || "N/A"}`}  className="py-2">
            <span className="text-gray-500">Model No.</span>
            
              <span  className="block font-semibold text-xs">
                {assetDetails?.model}
              </span>
            
          </div>
          <div title={`Serial No: ${assetDetails?.serial_no || "N/A"}`} className="py-2">
            <span className="text-gray-500">Serial No.</span>
           
              <span   className="block font-semibold  text-xs">
                {assetDetails?.serial_no}
              </span>
            
          </div>
        </div>

        {/* Frame 3 */}
        <div className="bg-white border overflow-hidden py-0 px-3 w-full lg:w-[200px] shadow-md text-[10px] h-[100px] rounded-[6px]">
          <div  title={`Category`}  className="py-2">
            <span className="text-gray-500">Category</span>
       
              <span className="block font-semibold text-xs">
                { assetDetails?.category ? (
                  <>
                    {assetDetails?.category === "nill"
                      ? "N/A"
                      : assetDetails?.category}
                  </>
                ) : (
                  "N/A"
                )
              }
              </span>
            
          </div>
          <div  title={`Year of Manufacturing: ${assetDetails?.yom || "N/A"}`} className="py-2">
            <span className="text-gray-500">Year of Manufacturing</span>
          
              <span className="block font-semibold text-xs">
                {assetDetails?.yom}
              </span>
    
          </div>
        </div>

        {/* Frame 4 */}
        <div className="bg-white border overflow-hidden py-0 px-3 w-full lg:w-[200px] shadow-md text-[10px] h-[100px] rounded-[6px]">
          <div   title={`Lease Status: ${capitalizeFirstLetter(
                  assetDetails?.lease_status || "N/A"
                )}`} className="py-2">
            <span className="text-gray-500">Lease Status</span>
        
              <span 
               

                className={`block font-semibold text-xs ${
                  assetDetails?.lease_status === "inactive"
                    ? "text-slate-600"
                    : assetDetails?.lease_status === "active"
                    ? "text-green-500"
                    : assetDetails?.lease_status === "expired"
                    ? "text-red-600"
                    : "text-blue-600"
                }`}
              >
                {assetDetails?.lease_status === "inactive" || assetDetails?.lease_status === "never assigned" ? "Free" : assetDetails?.lease_status === "expired" ? "Exceeded" : assetDetails?.lease_status?.startsWith(
                        "expiring"
                      ) ? `Active ${numb} days` : "Active" }
              </span>
            
          </div>
          <div  title={`Capacity: ${assetDetails?.capacity || "N/A"}kg - ${
                assetDetails?.hieght_machine || "N/A"
              }ft`} className="py-2">
            <span    className="text-gray-500">Capacity</span>
         
              <span className="block font-semibold text-xs text-dark-6">
                {assetDetails?.capacity}kg - {assetDetails?.hieght_machine}
                ft
              </span>
            
          </div>
        </div>

        {/* Frame 5 */}
        <div className="bg-white border overflow-hidden py-0 px-3 w-full lg:w-[200px] shadow-md text-[10px] h-[100px] rounded-[6px]">
          <div  title={`Tracker: ${
                assetDetails?.device_no?.trim() ? "Installed" : "Not Installed"
              }`}className="py-2">
            <span  className="text-gray-500">Tracker</span>
         
              <span
                className={`block font-semibold text-xs ${
                  assetDetails?.device_no?.trim()
                    ? "text-blue-600"
                    : "text-gray-500"
                }`}
              >
                {assetDetails?.device_no?.trim()
                  ? "Installed"
                  : "Not Installed"}
              </span>
            
          </div>
          <div title={`Ratings: ${assetDetails?.rating || "5"}/5`}  className="py-2">
            <span  className="text-gray-500">Ratings</span>
            
              <span className="block font-bold text-xs text-red-600">
                {assetDetails?.rating ? `${assetDetails?.rating}` : "5"}/5
              </span>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetHeader;
