import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import Loader from "../../Loader";
import { TableLoader } from "../../TableLoader";
import { LoaderContext } from "../../../Context/LoaderContext";
import CurrentItems from "./CurrentItems";
import { Pagination } from "../Pagination";
import UpperTab from "./UpperTab";
import HistoryBtn from "../../Buttons/HistoryBtn";
import ExcelReportBtn from "../../Buttons/ExcelReportBtn";
import NewOperatorTable from "./NewOperatorTable";
import * as XLSX from "xlsx";
import { formatDate } from "../../Modals/AssetDetails/Tabs/FormatDate";
import { toast } from "react-toastify";
import NewOperatorTableWithSalary from "./NewOperatorTableWithSalary";

function OperatorTable() {
  // set the item quantity to load page numbers
  // const [items, setItems] = useState(1);
  // set items per page

  const [allOperators, setAllOperators] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [loader, setLoader] = useContext(LoaderContext);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [fetchSearchData, setFetchSearchData] = useState(false);
  const [filteredDataStack, setFilteredDataStack] = useState([]);

  const getAllOperators = async (qry, value = "") => {
    // console.log(qry, value)
    let headers = {};
    if (qry !== null) {
      headers = {
        "Content-Type": "application/json",
        "x-access-tokens": sessionStorage.getItem("token"),
        offset: itemOffset,

        [qry]: value,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
        "x-access-tokens": sessionStorage.getItem("token"),
        offset: itemOffset,
      };
    }
    try {
      setLoader(true);
      const { data } = await axios.get(`${setBaseUrl}/operator/get_all`, {
        headers: headers,
      });
      // console.log(data);
      setAllOperators(data);
      // if (data.length) setItems(data[0].total_data);
    } catch (error) {
      // console.error(error);
    } finally {
      setLoader(false);
    }
  }; //eta thakbe

  useEffect(() => {
    getAllOperators();
  }, [deleted, itemOffset]); //eta thakbe

  const sortHeader = [
    "Name",
    "Aadhar No",
    "Pf Account No",
    "Joining Date",
    "Leaving Date",
    // "RSO No.",
    // "Asset No",

  ];

  const [openFilter, setOpenFilter] = useState(false);


  let tableArray = [10, 20, 30, 50, `${allOperators.length}`];
  const [selectTableData, setSelectTableData] = useState(10);


  // Sort Table
  const [openSort, setOpenSort] = useState(false);
  // sort names are storing
  const [sort, setSort] = useState(null);
  // sorting the data in ascending order using the whole filter
  const [sortOrder, setSortOrder] = useState("asc");
  // for input in sorting
  const [inputSearch, setInputSearch] = useState("");
  // Filtered header based on search input
  const filteredHeaders = sortHeader.filter((header) =>
    header.toLowerCase().includes(inputSearch.toLowerCase())
  );

  let sortedOperatorDetails = []


  const [filteredData, setFilteredData] = useState(allOperators);
  const [selectedRows, setSelectedRows] = useState([]);


  const date = new Date()
  const handleExport = () => {
    // Check if filteredData or selectedRows is available
    if (filteredData) {
      const dataToExport = allOperators.map((item, index) => ({ ...item, id: index + 1 }));

      // Validate rows to ensure all selected data is available
      if (dataToExport.some((row) => !row)) {
        console.error("Error: Some selected rows are missing or invalid.");
        return;
      }

      // Create a worksheet from the data
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Export the workbook as an Excel file
      XLSX.writeFile(workbook, `operators_${formatDate(date)}.xlsx`);
      toast.success("Data exported successfully");
    }
  };


  const [openSalary, setOpenSalary] = useState(false)


  return (
    <div id="main-operator" className="bloc">


      <div className="flex items-center gap-2 ">
        <button
        title="See All Operator Details"
          className={`px-4 py-2 text-xs w-[150px] rounded-[6px] ${openSalary ? "bg-gray-200 text-black border" : "bg-blue-2 text-white"
            }`}
          onClick={() => setOpenSalary(false)}
        >
          Operators
        </button>

        <button
        title="Generate Salaries of Operators "
          className={`px-4 py-2 text-xs w-[150px] rounded-[6px] ${openSalary ? "bg-blue-2 text-white" : "bg-gray-200 text-black border"
            }`}
          onClick={() => setOpenSalary(true)}
        >
          Salary Generate
        </button>

      </div>

      {loader ? (
        <Loader />
      ) : (
        // <CurrentItems
        //   allOperators={allOperators}
        //   deleted={deleted}
        //   setDeleted={setDeleted}
        //   sortHeader={sortHeader}
        //   openFilter={openFilter}
        // setOpenFilter={setOpenFilter}

        // tableArray={tableArray}
        // selectTableData={selectTableData}
        // setSelectTableData={setSelectTableData}
        // openSort={openSort} setOpenSort={setOpenSort}
        // sort={sort}
        // setSort={setSort}
        // sortOrder={sortOrder}
        // setSortOrder={setSortOrder}
        // inputSearch={inputSearch}
        // setInputSearch={setInputSearch}
        // filteredHeaders={filteredHeaders}
        // sortedOperatorDetails={sortedOperatorDetails}
        // />
        <>
          {openSalary ? <NewOperatorTableWithSalary
            allOperators={allOperators}
            handleExport={handleExport}
            setAllOperators={setAllOperators}
            setFilteredData={setFilteredData}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          /> :
          
          <>
          
      <UpperTab
        allOperators={allOperators}
        getAllOperators={getAllOperators}
        setFetchSearchData={setFetchSearchData}
        sortHeader={sortHeader}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}

        openSort={openSort} setOpenSort={setOpenSort}
        sort={sort}
        setSort={setSort}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        inputSearch={inputSearch}
        setInputSearch={setInputSearch}
        filteredHeaders={filteredHeaders}

      />
          <NewOperatorTable
            allOperators={allOperators}
            handleExport={handleExport}
            setAllOperators={setAllOperators}
            setFilteredData={setFilteredData}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            
            />
            </>
            
            }


        </>
      )}

    </div>
  );
}

export default OperatorTable;
