import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
import moment from "moment";

const ViewWallet = ({ operatorDetail, setOperatorDetail }) => {
  const [walletName, setWalletName] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [issueDate, setIssueDate] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  // console.log(operatorDetail.id)

  const [presentWallet, setPresentWallet] = useState([]);

  const getWallet = async () => {
    try {
      const res = await fetch(
        `${setBaseUrl}/operator/get-operator-wallet-options`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );

      const data = await res.json();
      if (res.ok) {
        setPresentWallet(data.msg); // Assuming API response structure
      } else {
        console.error("Error getting wallet", data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getWallet();
  }, []);

  const handleAddWallet = async (e) => {
    e.preventDefault();

    const newWallet = {
      items_name: walletName,
      quantity: parseInt(quantity, 10),
      issue_date: issueDate,
      operator_id: operatorDetail.id,
    };

    // Update UI first
    // setOperatorDetail((prevData) => ({
    //     ...prevData,
    //     wallet_arr: [...prevData.wallet_arr, newWallet],
    // }));

    setWalletName("");
    setQuantity(0);
    setIssueDate("");
    setShowDropdown(false);

    // Prepare data for submission
    const payload = { data_arr: [newWallet] };

    try {
      const res = await fetch(
        `${setBaseUrl}/operator/entry-operator-wallet-list`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await res.json();

      if (res.ok) {
        console.log("Wallet added successfully:", data);
      } else {
        console.error("Error adding wallet:", data);
      }
    } catch (error) {
      console.error("Request failed:", error);
    }
  };

  const handleWalletChange = (e) => {
    setWalletName(e.target.value);
    setShowDropdown(true);
  };

  const handleSelectWallet = (name) => {
    setWalletName(name);
    setShowDropdown(false);
  };
  // console.log(operatorDetail["wallet_arr"]);

  return (
    <>
      {/* Display Existing Wallet Items */}
      {operatorDetail.wallet_arr.length > 0 && (
        <div className="mt-4 border rounded p-2">
          <h3 className="text-xs font-bold mb-2">Wallet Items</h3>
          <table className="w-full text-xs border-collapse">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-2 border">Item Name</th>
                <th className="p-2 border">Quantity</th>
                <th className="p-2 border">Issue Date</th>
              </tr>
            </thead>
            <tbody>
              {operatorDetail?.wallet_arr?.map((item, index) => (
                <tr key={index} className="border-t">
                  <td className="p-2 border">{item.items_name}</td>
                  <td className="p-2 border text-center">{item.quantity}</td>
                  <td className="p-2 border">{moment(item.issue_date).format("DD-MM-YYYY")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ViewWallet;
