import { useState } from "react";

export const AssetDropdown = ({ assets, selectedAssets, setSelectedAssets }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  // Filter options based on search input
  const filteredOptions = assets.filter(asset =>
    asset.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle selection
  const handleSelect = (asset) => {
    if (!selectedAssets.some(selected => selected.value === asset.value)) {
      setSelectedAssets([...selectedAssets, asset]);
    }
  };

  // Remove selected asset
  const removeAsset = (assetToRemove) => {
    setSelectedAssets(selectedAssets.filter(asset => asset.value !== assetToRemove.value));
  };

  return (
    <div className="relative w-[350px]">
      {/* Selected Items */}
      <div
        className="w-full bg-gray-100 border-b border-gray-300 px-3 py-2 rounded-md cursor-pointer"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {selectedAssets.length > 0 ? (
          <div className="flex flex-wrap gap-2 z-[9999]">
            {selectedAssets.map((asset) => (
              <span key={asset.value} className="bg-blue-500 text-white px-2 py-1 text-xs rounded flex items-center gap-1">
                {asset.label}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    removeAsset(asset);
                  }}
                  className="ml-1 text-white hover:text-red-300"
                >
                  ✕
                </button>
              </span>
            ))}
          </div>
        ) : (
          <span className="text-gray-400">Select Asset Number</span>
        )}
      </div>

      {/* Dropdown Menu */}
      {showDropdown && (
        <>
        <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-md shadow-lg z-[10]">
          {/* Search Input */}
          <input
            type="text"
            className="w-full p-2 border-b border-gray-300 focus:outline-none"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {/* Options List */}
          <ul className="max-h-40 overflow-y-auto">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((asset) => (
                <li
                  key={asset.value}
                  className="px-3 py-2 hover:bg-blue-100 cursor-pointer"
                  onClick={() => handleSelect(asset)}
                >
                  {asset.label}
                </li>
              ))
            ) : (
              <li className="px-3 py-2 text-gray-500">No results found</li>
            )}
          </ul>
        </div>
        <div className="bg-[#20202010] z-0 fixed top-0 left-0 bottom-0 right-0" onClick={(e) => setShowDropdown(false)} />
        </>
      )}
    </div>
  );
};