import React, { useState } from 'react'
import CreateComponent from './CreateComponent'


const NewLease = () => {
  const [leaseData, setLeaseData] = useState({
    customer: '',
    lease_type: '',
    // asset_location: '',
    internal_reference_number:'',
    customer_po_number: '',
    currency: 'inr',
    sale_person:'',
    // transport_charges_with_mob: '',
    // transport_charges_without_mob: '',
    
    // reimbursements: '',
    
    machines: [], 

  });

  return (
    <CreateComponent leaseData={leaseData} setLeaseData={setLeaseData}/>
    
  )
}

export default NewLease
