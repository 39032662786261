import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setBaseUrl } from "../config";
import OuterLoader from "./OuterLoder";
import Layout from "../components/Layout";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { toast } from "react-toastify";

export const ChangePassword = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [creds, setCreds] = useState({ email: "", old_password: "", new_password: "" });
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const handleSubmit = async () => {
        try {
            setLoader(true);
            const response = await axios.post(`${setBaseUrl}/change-password`, {
                email: creds.email,
                password: creds.new_password,
            });
    
            if (response.status === 200) {
                toast.success("Password changed successfully!");
                sessionStorage.clear();
                navigate("/settings");
                setIsSuccess(true);
            } else {
                toast.error("Failed to change password!");
            }
        } catch (error) {
            console.error(error);
            toast.error(error.response?.data?.message || "An error occurred");
        } finally {
            setLoader(false);
        }
    };

    if (loader) return <OuterLoader />;

    return (
        <Layout>
            
                <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                        <h1 className="text-xs font-bold text-center text-gray-700 mb-6">Change Password</h1>
                        <div className="flex flex-col space-y-4">
                            <input
                                type="email"
                                required
                                placeholder="Email"
                                className="p-3 border text-xs rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                                onChange={(e) => setCreds({ ...creds, email: e.target.value })}
                            />
                            <div className="relative w-full">
                                <input
                                    type={showOldPassword ? "text" : "password"}
                                    required
                                    placeholder="Old Password"
                                    className="p-3 border text-xs rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    onChange={(e) => setCreds({ ...creds, old_password: e.target.value })}
                                />
                                <button
                                    type="button"
                                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                                    onClick={() => setShowOldPassword(!showOldPassword)}
                                >
                                    {showOldPassword ? <RemoveRedEyeOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </button>
                            </div>
                            <div className="relative w-full">
                                <input
                                    type={showNewPassword ? "text" : "password"}
                                    required
                                    placeholder="New Password"
                                    className="p-3 border text-xs rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    onChange={(e) => setCreds({ ...creds, new_password: e.target.value })}
                                />
                                <button
                                    type="button"
                                    className="absolute  right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                >
                                    {showNewPassword ? <RemoveRedEyeOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </button>
                            </div>
                            <div className="flex justify-between mt-4">
                                <button
                                  className="border buttons text-black bg-gray-200 "
                                    onClick={() => navigate(-1)}
                                >
                                    Back
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-500 text-white buttons hover:bg-blue-600"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            
        </Layout>
    );
};
