// import React, { useEffect, useState } from "react";
// import verified from "../../../assets/images/verified.svg";
// import unverified from "../../../assets/images/unverified-16.svg";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { setBaseUrl } from "../../../config";
// import { Pagination } from "../Pagination";
// import Loader from "../../Loader";
// import { IoAddOutline } from "react-icons/io5";
// function SettingsTable() {
//   const navigate = useNavigate();
//   const [tableAnimation, setTableAnimation] = useState(false);
//   const [allEmployees, setAllEmployees] = useState([]);
//   const [itemsPerPage, setItemsPerPage] = useState(10);
//   const [items, setItems] = useState(0);
//   const [itemOffset, setItemOffset] = useState(0);
//   const [loader, setLoader] = useState(false);

//   const getAllEmployees = async () => {
//     try {
//       setLoader(true);
//       const { data } = await axios.get(`${setBaseUrl}/company/all_employee`, {
//         headers: {
//           "Content-Type": "application/json",
//           "x-access-tokens": sessionStorage.getItem("token"),
//         },
//       });
//       console.log(data);
//       setItems(data.length)
//       setAllEmployees(data);
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoader(false);
//     }
//   };

//   const updateEmployee = async (email) => {
//     navigate(`/settings/update-employee/${email}`);
//   };

//   useEffect(() => {
//     getAllEmployees();
//   }, []);

//   useEffect(() => {
//     setTimeout(() => {
//       setTableAnimation(true);
//     }, 400);
//   }, []);

//   return (
//     <>
//       {loader ? (
//         <Loader />
//       ) : (
//       <>
//       <div className="h-full w-full p-2 ">
//   <div className="flex justify-between items-center mb-6">
//     <p className="text-xs font-semibold text-dark-6 ">
//       Employees
//     </p>
//     <button
//       onClick={() => navigate("addEmployee")}
//       className="bg-blue-2 text-white p-1 px-2 rounded text-xs flex items-center justify-center"
//     >
//        <IoAddOutline className="mr-2 h-3 w-3" />
//      New
//     </button>
//   </div>
//   <div className="overflow-auto p-5 bg-white shadow-md rounded-[4px]">
//     <table className="w-full  divide-y text-left text-xs">
//       <thead className="text-dark-6 text-xs">
//         <tr>
//         <th className="w-[130px] h-10 text-center">
//         <input type="checkbox" className="border " />
//         </th>
//           <th className="w-[130px] h-10 text-center">Employee</th>
//           <th className="w-[130px] h-10 text-center">Email</th>
//           <th className="w-[130px] h-10 text-center">Verified</th>
//           <th className="w-[130px] h-10 text-center">Action</th>
//         </tr>
//       </thead>
//       <tbody className="divide-y">
//         {allEmployees.map((data, index) => (
//           <tr
//             key={index}
//             className="text-xs text-center"
//           >
//             <td>
//               <input type="checkbox" className="border " />
//             </td>
//             <td className="h-10">
//               {data.name}
//             </td>
//             <td className="h-10">
//               {data.email}
//             </td>
//             <td className="h-10">
//               <img
//                 src={data.verified ? verified : unverified}
//                 alt={data.verified ? "Verified" : "Unverified"}
//                 className="w-6 h-6 mx-auto"
//               />
//             </td>
//             <td className="h-10">
//               <button
//                 onClick={() => updateEmployee(data.email)}
//                 className="text-center bg-white border py-0.5 px-2 text-[10px] rounded-[6px]"
//               >
//                 Edit
//               </button>
//             </td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   </div>
//   <div className="mt-10">

//   <Pagination
//     itemsPerPage={itemsPerPage}
//     itemsLength={items}
//     itemOffset={itemOffset}
//     setItemOffset={setItemOffset}
//     />
//     </div>
// </div>

//       </>
//       )}
//     </>
//   );
// }

// export default SettingsTable;

import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import verified from "../../../assets/images/verified.svg";
import unverified from "../../../assets/images/unverified-16.svg";
import axios from "axios";
import { setBaseUrl } from "../../../config";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import { Button, TablePagination } from "@mui/material";
import SalesPersonTable from "./SalesPersonTable";

function SettingsTable() {
  const navigate = useNavigate();
  const [allEmployees, setAllEmployees] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  const hasEmployee = useRef(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  const getAllEmployees = async () => {
    try {
      setLoader(true);
      const { data } = await axios.get(`${setBaseUrl}/company/all_employee`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      setAllEmployees(data.map((item, index) => ({ id: index, ...item }))); // Ensure each row has a unique `id`
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!hasEmployee.current) {
      hasEmployee.current = true;
      getAllEmployees();
    }
  }, []);

  const columns = [
    {
      field: "counter",
      headerName: "#",
      width: 80,
      renderHeader: () => (
        <span title="Row Number" arrow placement="top">
          <span>#</span>
        </span>
      ),
      renderCell: (params) => <>{params.row.counter}</>, // Use the counter field
    },
    {
      field: "name",
      headerName: "Employee",
      flex: 1,
      width: 150,
      renderHeader: () => (
        <span title="Username" arrow placement="top">
          <span>Username</span>
        </span>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      width: 150,
      renderHeader: () => (
        <span title="Email Address of the Employee" arrow placement="top">
          <span>Email</span>
        </span>
      ),
    },
    {
      field: "verified",
      headerName: "Verified",
      width: 120,
      renderHeader: () => (
        <span title="Verification Status" arrow placement="top">
          <span>Verified</span>
        </span>
      ),
      renderCell: (params) => (
        <img
          className="mt-4"
          src={params.value ? verified : unverified}
          alt={params.value ? "Verified" : "Unverified"}
          style={{ width: 20, height: 20 }}
        />
      ),
    },
  ];

  const handleRowClick = (params) => {
    navigate(`/settings/update-employee/${params.row.email}`);
  };

  const [salesTab, setSalesTab] = useState(false);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-start">
          <button
            className={`buttons ${
              salesTab
                ? "bg-light-4 border text-dark-1"
                : "bg-blue-2 text-white "
            }`}
            onClick={() => setSalesTab(false)}
          >
            Employee
          </button>
          <button
            className={`buttons ${
              !salesTab
                ? "bg-light-4 border text-dark-1"
                : "bg-blue-2 text-white "
            }`}
            onClick={() => setSalesTab(true)}
          >
            Sales
          </button>
        </div>

        <button
          onClick={() => navigate("add-employee")}
          title="Add New Employee"
          className="buttons bg-blue-2 text-white"
        >
          New User
        </button>
      </div>

      {salesTab ? (
        <>
          <SalesPersonTable />
        </>
      ) : (
        <>
          {loader ? (
            <Loader />
          ) : (
            <div className="h-full w-full p-2">
              <div className="flex justify-between items-center mb-6"></div>
              <Paper
                style={
                  {
                    // padding: 10,
                    // height: "70vh",
                    // width: "100%",
                  }
                }
                elevation={0}
              >
                <div className="flex items-center justify-end">
                  <TablePagination
                    component="div"
                    count={allEmployees.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 30, 50, allEmployees.length]}
                    labelRowsPerPage="Rows:"
                  />
                </div>
                <DataGrid
                  // rows={allEmployees}
                  rows={
                    pageSize === allEmployees.length
                      ? allEmployees.map((row, index) => ({
                          ...row,
                          counter: index + 1, // Add row number for all rows
                        }))
                      : allEmployees
                          .slice(page * pageSize, page * pageSize + pageSize) // Paginated rows
                          .map((row, index) => ({
                            ...row,
                            counter: page * pageSize + index + 1, // Adjust counter for paginated rows
                          }))
                  }
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 20, 50]}
                  // checkboxSelection
                  paginationMode="server"
                  pagination={false} // Disable built-in pagination
                  onRowClick={handleRowClick} // Navigate on row click
                  sx={{
                    "& .MuiDataGrid-footerContainer": {
                      display: "none", // Hides the footer container
                    },
                    "& .MuiDataGrid-root": { fontSize: "12px", height: 30 },
                    "& .MuiDataGrid-columnHeaders": {
                      fontSize: "14px",
                      backgroundColor: "#000",
                      fontWeight: "bold",
                      color: "#006DB6",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "bold",
                      height: 20,
                    },
                    "& .MuiDataGrid-cell": { fontSize: "12px" },
                    "& .MuiDataGrid-virtualScroller": {
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      "-ms-overflow-style": "none",
                      "scrollbar-width": "none",
                    },
                  }}
                />
              </Paper>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default SettingsTable;
