import React from "react";
import { currency, formatDate } from "./FormatDate";
import moment from "moment/moment";
import { Tooltip } from "@mui/material";

const AssetCommercial = ({
  commercialDetails,
  CustomDutyDateOfClearance,
}) => {
  if (commercialDetails === null) {
    return <div className="text-xs text-red-1">No Commercial Details Added</div>;
  }


  let CommercialData = [];
if (commercialDetails) {

  CommercialData = [
    {
      title: "Total Landed Cost",
      label: `${
        commercialDetails.total_landed_cost === null ||   commercialDetails.total_landed_cost === "nill" || commercialDetails.total_landed_cost === "N/A"
          ? "N/A"
          : `₹${
             currency( commercialDetails?.total_landed_cost)
            }/-`
      }`,
      tooltip: "Total Landed Cost",
    },
    {
      title: "Ex-Works Price",
      label:
        commercialDetails?.exworks_price === null ||  commercialDetails?.exworks_price === "nill" ||  commercialDetails.exworks_price === "N/A"
          ? "N/A"
          : `$${currency(commercialDetails?.exworks_price)}/-`,
      tooltip: "Ex-Works Price",
    },
    {
      title: "CIF Charges",
      label:
        commercialDetails?.cif_charges === null ||  commercialDetails?.cif_charges === "nill" ||  commercialDetails.cif_charges === "N/A"
          ? "N/A"
          : `$${currency(commercialDetails?.cif_charges)}/-`,
      tooltip: "Cost, Insurance, and Freight (CIF) Charges",
    },
    {
      title: "Total Cost (Ex-Works + CIF)",
      label:
        commercialDetails?.total_cost === "nill" || commercialDetails?.total_cost ===  null ||  commercialDetails.total_cost === "N/A"
          ? "N/A"
          : `$${currency(commercialDetails?.total_cost)}/-`,
      tooltip:
        "Total Cost = Ex Works (EXW) price + Cost, Insurance, and Freight (CIF) Charges",
    },
    {
      title: "Port of Clearance",
      label:
        commercialDetails?.port_of_clearance === null ||  commercialDetails?.port_of_clearance === "nill" ||  commercialDetails.port_of_clearance === "N/A"
          ? "N/A"
          : (commercialDetails?.port_of_clearance),
      tooltip: "Port of Clearance",
    },
    
    {
      title: "Custom Duty Date of Clearance",
      label:
        CustomDutyDateOfClearance === "" ||   CustomDutyDateOfClearance === "nill" || CustomDutyDateOfClearance === null ||  CustomDutyDateOfClearance === "N/A" || CustomDutyDateOfClearance === undefined
          ? "N/A"
          : moment(CustomDutyDateOfClearance).format("DD-MM-YYYY"),
      tooltip: "Custom Duty Date of Clearance",
    },
    {
      title: "Purchase Order No",
      label:
        commercialDetails?.purchase_order_no === "nill" || commercialDetails?.purchase_order_no === null ||  commercialDetails.purchase_order_no === "N/A"
          ? "N/A"
          : commercialDetails?.purchase_order_no,
      tooltip: "Purchase Order No",
    },
    {
      title: "Purchase Order Date",
      label: commercialDetails?.purchase_order_date === null ||  commercialDetails?.purchase_order_date === "nill" ||  commercialDetails.purchase_order_date === "N/A"
        ? "N/A"
        : moment(commercialDetails?.purchase_order_date).format("DD-MM-YYYY"),
      tooltip: "Purchase Order Date",
    },
    {
      title: "Insurance Renewal",
      label: commercialDetails?.insurance_renewal
        ? moment(commercialDetails?.insurance_renewal).format("DD-MM-YYYY")
        : "--",
      tooltip: "Insurance Renewal ",
    },
    {
      title: "Exchange Rate (at Remittance)",
      label:
        commercialDetails?.exchange_rate_rem === "nill" ||  commercialDetails.exchange_rate_rem === null ||  commercialDetails.exchange_rate_rem === "N/A"
          ? "N/A"
          : `1 USD = ${parseFloat(commercialDetails?.exchange_rate_rem).toFixed(
              2
            )} INR`,
      tooltip: "Exchange Rate of Remitted OEM",
    },
    {
      title: "Amount of Remitted OEM",
      label:
        commercialDetails?.amount_rem_to_oem === null ||  commercialDetails.amount_rem_to_oem === "nill" ||  commercialDetails.amount_rem_to_oem === "N/A"
          ? "N/A"
          : `₹${currency(
              commercialDetails?.amount_rem_to_oem
            )}/-`,
      tooltip: "Amount of Remitted OEM",
    },
    {
      title: "Date of Remittance to OEM",
      label:
        commercialDetails?.date_of_rem_to_oem === null ||  commercialDetails.date_of_rem_to_oem === "nill" ||  commercialDetails.date_of_rem_to_oem === "N/A"
          ? "N/A"
          : formatDate(commercialDetails?.date_of_rem_to_oem),
      tooltip: "Date of Remittence to OEM",
    },
    {
      title: "BOE No",
      label:
        commercialDetails.boe_no === null ||   commercialDetails.boe_no === "nill" ||  commercialDetails.boe_no === "N/A" || commercialDetails.boe_no === "None"
          ? "N/A"
          : commercialDetails?.boe_no,
      tooltip: "Bill of entry (BOE)",
    },
    {
      title: "Exchange Rate (at BOE)",
      label:
        commercialDetails?.exrate_boe === null ||  commercialDetails.exrate_boe === "nill" ||  commercialDetails.exrate_boe === "N/A"
          ? "N/A"
          : `1 USD = ${parseFloat(commercialDetails?.exrate_boe).toFixed(
              2
            )}  INR`,
      tooltip: "Exchange rate at bill of entry",
    },
    {
      title: "Custom Duty Value",
      label:
        `${
          commercialDetails?.custom_duty_value === "nill" ||
          commercialDetails?.custom_duty_value === "" ||  commercialDetails.custom_duty_value === null ||  commercialDetails.custom_duty_value === "N/A"
            ? "N/A"
            : `₹${currency(commercialDetails?.custom_duty_value)}/-`
        }` || "N/A",
      tooltip: "Custom duty value",
    },
    {
      title: "Clearing Charges",
      label:
        commercialDetails?.clearing_charges === null ||  commercialDetails.clearing_charges === "nill" ||  commercialDetails.clearing_charges === "N/A" 
          ? "N/A"
          : `₹${currency(commercialDetails?.clearing_charges)}/-`,
      tooltip: "Clearing Charges Amount",
    },
    {
      title: "CHA Charge",
      label:
        commercialDetails?.cha_charges === null ||  commercialDetails.cha_charges === "nill" ||  commercialDetails.cha_charges === "N/A"
          ? "N/A"
          : `₹${currency(commercialDetails?.cha_charges)}/-`,
      tooltip: "Customs House Agent (CHA) charge",
    },
    {
      title: "GST Amount",
      label: `${
        commercialDetails?.gst_amount === null ||  commercialDetails.gst_amount === "nill" ||  commercialDetails.gst_amount === "N/A"
          ? "N/A"
          : `₹${currency(commercialDetails?.gst_amount)}/-`
      }`,
      tooltip: "GST Amount",
    },
    {
      title: "Total Landed Cost with GST",
      label: `${
        commercialDetails?.total_landed_cost_with_gst === null  ||  commercialDetails.total_landed_cost_with_gst === "nill" ||   commercialDetails.total_landed_cost_with_gst === "N/A"
          ? "N/A"
          : `₹${currency(
              commercialDetails?.total_landed_cost_with_gst
            )}/-`
      }`,
      tooltip: "Total landed cost with gst",
    },
    {
      title: "Transportation Charges (INR)",
      label:
        commercialDetails?.transportation_charges === null ||  commercialDetails.transportation_charges === "nill" ||  commercialDetails.transportation_charges === "N/A"
          ? "N/A"
          : `₹${currency(commercialDetails?.transportation_charges)}/-`,
      tooltip: "Transportation Charges",
    },
    {
      title: "Value of Sold Asset",
      label: commercialDetails?.value_of_sold_asset === null ||  commercialDetails.value_of_sold_asset === "nill" ||  commercialDetails.value_of_sold_asset === "N/A" ? "N/A" : `₹${currency(commercialDetails?.value_of_sold_asset)}/-`,
       tooltip: "Value of Sold Asset",
    },
    {
      title: "Sold to customer name",
      label:  commercialDetails?.sold_customer_name === null ||  commercialDetails.sold_customer_name === "nill" ||  commercialDetails.sold_customer_name === "N/A" ? "N/A" : commercialDetails?.sold_customer_name,
       tooltip: "Sold to customer name",
    },
    // {
    //   title: "Period of insurance",
    //   label: commercialDetails?.period_of_insurance === null ||  commercialDetails.period_of_insurance === "nill" ||  commercialDetails.period_of_insurance === "N/A" ? "N/A" : commercialDetails?.period_of_insurance,
    //    tooltip: "Period of insurance",
    // },
    // {
    //   title: "Invoice Date",
    //   label: commercialDetails?.invoice_date === null ||  commercialDetails.invoice_date === "nill" ||  commercialDetails.invoice_date === "N/A" ? "N/A" : moment(commercialDetails?.invoice_date).format("DD-MM-YYYY"),
    //    tooltip: "Invoice Date",
    // },
    // {
    //   title: "Payment Terms",
    //   label: commercialDetails?.payment_terms === null ||  commercialDetails.payment_terms === "nill" ||  commercialDetails.payment_terms === "N/A" ? "N/A" : commercialDetails?.payment_terms,
    //    tooltip: "Payment Terms",
    // },

  ];
}

  return (
    <>
      <div className=" text-xs w-full">
        <h1 className="text-[#4b5563] font-extrabold mb-4">
          Asset Commercial Details
        </h1>
        {commercialDetails === null ? (
          "No Commercial Details Added"
        ) : (
          <div className="grid grid-cols-4 gap-2 ">
            
            {/* First column with first 6 items */}
            <div className="space-y-0 bg-white  shadow-md rounded-[4px] p-3">
              {CommercialData?.slice(0, 6).map((item, index) => (
                <div title={item.tooltip}
                  className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start"
                  key={index}
                >
                  
                    <div className="font-normal">{item.title}</div>
                    <div
                      className={`flex justify-start font-semibold text-xs    w-full items-center ${
                        item.cssLabel
                      }`}
                    >
                      {item.label}
                    </div>
                  
                </div>
              ))}
            </div>

            {/* Second column */}
            <div className="space-y-0 bg-white  shadow-md rounded-[4px]">
              {CommercialData.slice(6, 11).map((item, index) => (
                <div title={item.tooltip}
                  className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start"
                  key={index}
                >
                  
                    <div className="font-normal">{item.title}</div>
                    <div
                      className={`flex justify-start  font-semibold text-xs    w-full items-center ${
                        item.cssLabel || ""
                      }`}
                    >
                      {item.label}
                    </div>
                  
                </div>
              ))}
            </div>

            {/* Third column */}
            <div className="space-y-0 bg-white  shadow-md rounded-[4px]">
              {CommercialData.slice(11, 16).map((item, index) => (
                <div title={item.tooltip}
                  className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start"
                  key={index}
                >
                  
                    <div className="font-normal">{item.title}</div>
                    <div
                      className={`flex justify-start  font-semibold text-xs    w-full items-center ${
                        item.cssLabel || ""
                      }`}
                    >
                      {item.label}
                    </div>
                  
                </div>
              ))}
            </div>

            {/* Fourth column */}
            <div className="space-y-0 bg-white  shadow-md rounded-[4px]">
              {CommercialData.slice(16).map((item, index) => (
                <div title={item.tooltip}
                  className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start"
                  key={index}
                >
                  
                    <div className="font-normal">{item.title}</div>
                    <div
                      className={`flex justify-start  font-semibold text-xs    w-full items-center ${
                        item.cssLabel
                      }`}
                    >
                      {item.label}
                    </div>
                  
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AssetCommercial;


// import { Card } from '@mui/material';
// import React from 'react'
// import { currency } from './FormatDate';

// const AssetCommercial = ({
//   commercialDetails,
//   CustomDutyDateOfClearance,
// }) => {

//   console.log('Commercial Details', commercialDetails);
//   console.log('Custom Duration of Clearance', CustomDutyDateOfClearance);


//   if(commercialDetails === null || commercialDetails.length === 0 ) {
//     return <div className='text-xs text-red-1 items-center justify-center  font-normal'>No Commercial Details Available</div>
//   }

//   return (
//     <div>
//       <h1 className="">
//       AssetCommercial Details
//       </h1>


// <div className="flex items-center justify-between text-[12px]">
//     <h1 className="font-normal">
//       Total Landed Cost:
//     </h1>
//       <span>{commercialDetails.total_landed_cost === "nill" || commercialDetails.total_landed_cost === null || commercialDetails.total_landed_cost === "N/A" ? "N/A" : (currency(commercialDetails.total_landed_cost)) }</span>
// </div>
//     </div>
//   )
// }

// export default AssetCommercial