// import React from "react";
// import { MdDelete } from "react-icons/md";
// import { toast } from "react-toastify";

// import { setBaseUrl } from "../../../../config.js";

// const PhotoDataTab = ({ imageArray, assetName }) => {
//   const handleDeleteImage = async (index) => {
//     try {
//       const res = await fetch(`${setBaseUrl}/asset/delete_photo`, {
//         method: "DELETE",
//         headers: {
//           "Content-Type": "application/json",
//           "x-access-tokens": sessionStorage.getItem("token"),
//         },
//         credentials: "include",
//         body: JSON.stringify({ id: index }),
//       });

//       toast.success("Photo has been successfully deleted");
//     } catch (error) {
//       toast.error("Photo deletion failed");
//     }
//   };
//   return (
//     <div className="pb-12">
//       <div className="pb-12 text-xs">
//         {imageArray && imageArray.length > 0 ? (
//           <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 max-w-screen  gap-6">
//             {imageArray.map((image, index) => (
//               <div
//                 key={index}
//                 className=" rounded-[6px]  bg-white shadow-md overflow-hidden  transition-shadow h-fit flex flex-col items-center"
//               >
//                 {/* Image Container */}
//                 <div className="w-full h-fit overflow-hidden ">
//                   <img
//                     src={image.image_uri}
//                     alt={`Asset ${image.id}`}
//                     className="w-full h-full object-cover hover:scale-105 transition-transform"
//                     onError={(e) => {
//                       e.target.src =
//                         "https://via.placeholder.com/150?text=Image+Not+Available"; // Fallback image
//                     }}
//                   />

               
//                 </div>

//                 {/* Caption */}
//                 <div className="w-full flex justify-between items-center bg-white p-2 text-center">
//                   <p className="text-xs text-dark-6 truncate">{`Asset ID`}</p>
//                   <span className="text-xs font-bold text-dark-6">
//                     {assetName}
               
//                   </span>
//                   <span>

//                   <button
//                     className="absolute top-0 right-0 mt-4 mr-4 bg-red-1 text-xs font-medium text-red-1"
//                     >
                  
//                   </button>
                      
//                 <MdDelete className="text-red-1 w-[20px] h-[20px]"
//                 onClick={() => handleDeleteImage(image.id)}
//                 />
//                     </span>
//                 </div>
//               </div>
//             ))}
//           </div>
//         ) : (
//           <p className="text-gray-500 text-center">No images available</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default PhotoDataTab;
import React from "react";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { setBaseUrl } from "../../../../config.js";

const PhotoDataTab = ({ imageArray, assetName }) => {
  const handleDeleteImage = async (imageId) => {
    try {
      const res = await fetch(`${setBaseUrl}/asset/delete_photo`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        credentials: "include",
        body: JSON.stringify({ id: imageId }),
      });

      if (res.ok) {
        toast.success("Photo has been successfully deleted");
      } else {
        toast.error("Photo deletion failed");
      }
    } catch (error) {
      toast.error("Photo deletion failed");
    }
  };

  return (
    <div className="pb-12">
      <div className="pb-12 text-xs">
        {imageArray && imageArray.length > 0 ? (
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 max-w-screen gap-6">
            {imageArray.map((image, index) => (
              <div
                key={index}
                className="rounded-[6px] bg-white shadow-md overflow-hidden transition-shadow h-fit flex flex-col items-center"
              >
                {/* Image Container */}
                <div className="w-full h-fit overflow-hidden">
                  <img
                    src={image.image_uri}
                    alt={`Asset ${image.id}`}
                    className="w-full h-full object-cover hover:scale-105 transition-transform"
                    onError={(e) => {
                      e.target.src =
                        "https://via.placeholder.com/150?text=Image+Not+Available"; // Fallback image
                    }}
                  />
                </div>

                {/* Caption */}
                <div className="w-full flex justify-between items-center bg-white p-2 text-center">
                  <p className="text-xs text-dark-6 truncate">{`Asset ID`}</p>
                  <span className="text-xs font-bold text-dark-6">{assetName}</span>
                  <span>
                    <MdDelete
                      className="text-red-500 w-[20px] h-[20px] cursor-pointer"
                      onClick={() => handleDeleteImage(image.id)} // ✅ Fix: Call function onClick
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500 text-center">No images available</p>
        )}
      </div>
    </div>
  );
};

export default PhotoDataTab;
