// import { Paper, TablePagination, Tooltip } from '@mui/material';
// import React, { useEffect, useRef, useState } from 'react'
// import NewAssetBtn from '../../Buttons/NewAssetBtn';
// import { DataGrid } from '@mui/x-data-grid';
// import { useNavigate } from 'react-router-dom';
// import { formatDate } from '../../Modals/AssetDetails/Tabs/FormatDate';

// const ButtonData = ({ handleExport, selectedRows, filteredData }) => {
//     return (
//         <>
//             {/* <Tooltip  title="Export Data in Excel format" placement="top" arrow> */}

//             {filteredData.length > 0 ? (
//                 <button
//                     title='Export Data in Excel format'
//                     variant="outlined"
//                     sx={{
//                         fontSize: 10,
//                         color: "#000",
//                         width: 104,
//                         fontWeight: 'bold'
//                     }}
//                     onClick={handleExport}
//                     className="border text-black buttons text-xs "
//                 >
//                     Export Excel
//                 </button>
//             ) : (
//                 <button
//                     disabled
//                     title='Export Data in Excel format'
//                     variant="outlined"
//                     sx={{
//                         fontSize: 10,
//                         color: "#000",
//                         width: 180,
//                         fontWeight: 'bold',
//                         textDecoration: 'line-through', // Added line-through
//                     }}
//                     className="text-xs border line-through font-bold buttons"
//                 >
//                     Export Excel
//                 </button>

//             )}
//             {/* </Tooltip> */}
//         </>
//     );
// };
// const NewOperatorTableWithSalary = ({
//     allOperators,
//     setFilteredData,
//     selectedRows,
//     setSelectedRows,
//     handleExport,
// }) => {
//     const [page, setPage] = useState(0);
//     const [pageSize, setPageSize] = useState(10);
//     const [filteredData, setLocalFilteredData] = useState(allOperators);
//     const [searchQuery, setSearchQuery] = useState("");
//     const [suggestions, setSuggestions] = useState([]);

//     const inputRef = useRef(null);
//     const navigate = useNavigate();

//     useEffect(() => {
//         setFilteredData(filteredData);
//     }, [filteredData, setFilteredData]);

//     useEffect(() => {
//         setLocalFilteredData(allOperators);
//     }, [allOperators]);

//     const handleSearch = (query) => {
//         const lowerCaseQuery = query.toLowerCase();
//         const filtered = allOperators.filter((operator) =>
//             Object.values(operator).some((value) =>
//                 value?.toString().toLowerCase().includes(lowerCaseQuery)
//             )
//         );
//         setLocalFilteredData(filtered);
//         setSuggestions([]);
//     };

//     const handleKeyDown = (event) => {
//         if (event.key === "Enter") {
//             handleSearch(searchQuery);
//         }
//     };

//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//         const value = event.target.value;
//         setPageSize(value === 'All data' ? filteredData.length : value);
//         setPage(0);
//     };

//     const handleInputChange = (query) => {
//         setSearchQuery(query);
//         if (query.trim() === "") {
//             setSuggestions([]);
//         } else {
//             const lowerCaseQuery = query.toLowerCase();
//             const matches = allOperators
//                 .filter(
//                     (operator) =>
//                         operator.name?.toLowerCase().includes(lowerCaseQuery) ||
//                         operator.aadhar_no?.toString().includes(lowerCaseQuery) ||
//                         operator.pf_account_no?.toString().includes(lowerCaseQuery)
//                 )
//                 .map((operator) => ({
//                     name: operator.name,
//                     aadhar_no: operator.aadhar_no,
//                     pf_account_no: operator.pf_account_no,
//                 }));
//             setSuggestions(matches.slice(0, 5)); // Limit suggestions to 5
//         }
//     };

//     const preprocessData = (data) =>
//         data.map((item) => ({
//             ...item,
//             pf_account_no: Number.isInteger(item.pf_account_no)
//                 ? item.pf_account_no
//                 : parseInt(item.pf_account_no, 10) || "N/A",
//             aadhar_no: item.aadhar_no
//                 ? item.aadhar_no
//                     .toString()
//                     .replace(/\D/g, "") // Remove non-numeric characters
//                     .replace(/(\d{4})(\d{4})(\d{4})/, "$1 $2 $3")
//                 : "N/A", // Fallback to "n/a" if Aadhaar number is invalid or missing
//             joining_date: item.joining_date ? formatDate(item.joining_date) : "N/A",
//             leaving_date: item.leaving_date ? formatDate(item.leaving_date) : "N/A",
//         }));

//     const columns = [
//         {
//             field: "counter",
//             headerName: "#",
//             width: 50,
//             renderHeader: () => (
//                 <Tooltip title="Row Number" arrow placement="top">
//                     <span>#</span>
//                 </Tooltip>
//             ),
//             renderCell: (params) => <>{params.row.counter}</>, // Use the counter field
//         },
//         {
//             field: "name",
//             headerName: "Name",
//             width: 120,
//             renderHeader: () => (
//                 <Tooltip title="Name of the operator" arrow placement="top">
//                     <span>Name</span>
//                 </Tooltip>
//             ),
//         },
//         {
//             field: "aadhar_no",
//             headerName: "Aadhaar Number",
//             width: 120,
//             renderHeader: () => (
//                 <Tooltip title="Aadhaar Number of the operator" arrow placement="top">
//                     <span>Aadhaar Number</span>
//                 </Tooltip>
//             ),
//         },
//         {
//             field: "pf_account_no",
//             headerName: "Pf Account Number",
//             width: 120,
//             renderHeader: () => (
//                 <Tooltip
//                     title="Provident Fund Account Number of the operator"
//                     arrow
//                     placement="top"
//                 >
//                     <span>Pf Account Number</span>
//                 </Tooltip>
//             ),
//         },
//         {
//             field: "joining_date",
//             headerName: "Joining Date",
//             width: 120,
//             renderHeader: () => (
//                 <Tooltip title="Date the operator joined" arrow placement="top">
//                     <span>Joining Date</span>
//                 </Tooltip>
//             ),
//         },
//         {
//             field: "leaving_date",
//             headerName: "Leaving Date",
//             width: 120,
//             renderHeader: () => (
//                 <Tooltip title="Date the operator left" placement="top" arrow>
//                     <span>Leaving Date</span>
//                 </Tooltip>
//             ),
//         },
//         {
//             field: "month",
//             headerName: "Enter Month",
//             width: 120,
//             renderHeader: () => (
//                 <Tooltip title="Month of the salary and attendance" arrow placement="top">
//                     <span>Enter Month</span>
//                 </Tooltip>
//             ),
//             renderCell: (params) => <>
//                 <input className='focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none' type="month" value={params.row.month} />

//             </>, // Display the month
//         },
//         {
//             field: "net_salary",
//             headerName: "Net Salary",
//             width: 120,
//             renderHeader: () => (
//                 <Tooltip title="Net salary of the operator" arrow placement="top">
//                     <span>Net Salary</span>
//                 </Tooltip>
//             ),
//             renderCell: (params) => <>{params.row.net_salary}</>, // Display the net salary
//         },
//         {
//             field: "advance_inhand_salary",
//             headerName: "Advanced Inhand Salary",
//             width: 120,
//             renderHeader: () => (
//                 <Tooltip title="Advanced inhand salary for the operator" arrow placement="top">
//                     <span>Advanced Inhand Salary</span>
//                 </Tooltip>
//             ),
//             renderCell: (params) => <>{params.row.advance_inhand_salary}</>, // Display advanced salary
//         },
//         {
//             field: "attendance_in_month",
//             headerName: "Attendance in Month",
//             width: 120,
//             renderHeader: () => (
//                 <Tooltip title="Attendance for the month" arrow placement="top">
//                     <span>Attendance in Month</span>
//                 </Tooltip>
//             ),
//             renderCell: (params) => <>
//                 <input type="number" min={0} max={30}
//                     value={params.row.attendance_in_month}
//                     placeholder='Attendance in Month'
//                     className="focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none" />
//             </>, // Display attendance
//         },
//         {
//             field: "deduction",
//             headerName: "Deduction",
//             width: 120,
//             renderHeader: () => (
//                 <Tooltip title="Deductions applied to salary" arrow placement="top">
//                     <span>Deduction</span>
//                 </Tooltip>
//             ),
//             renderCell: (params) => <>{params.row.deduction}</>, // Display deductions
//         },
//         {
//             field: "overtime_amount",
//             headerName: "Overtime Amount",
//             width: 120,
//             renderHeader: () => (
//                 <Tooltip title="Overtime amount earned by the operator" arrow placement="top">
//                     <span>Overtime Amount</span>
//                 </Tooltip>
//             ),
//             renderCell: (params) => <>

//                 {params.row.overtime_amount}

//             </>, // Display overtime amount
//         },
//     ];

//     return (
//         <div className="fixed right-3 left-[5%] bg-white">
//             <Paper
//                 style={{
//                     padding: 10,
//                     height: "70vh",
//                     width: "100%",
//                     border: "hidden",
//                 }}
//                 elevation={0}
//             >
//                 <div className="flex justify-between items-center">
//                     <div className="relative flex gap-2 items-center">
//                         <div className="flex gap-2 border rounded-[4px]">
//                             <input
//                                 ref={inputRef}
//                                 type="text"
//                                 placeholder="Search operators"
//                                 value={searchQuery}
//                                 onChange={(e) => handleInputChange(e.target.value)}
//                                 onKeyDown={handleKeyDown}
//                                 className="border-light-5 border bg-gray-50 h-[30px] w-[200px] p-[5px] rounded-[4px] text-[12px] items-center"
//                             />
//                             {suggestions.length > 0 && (
//                                 <ul
//                                     className="absolute z-10 bg-white border border-gray-300 rounded-md shadow-md max-h-40 overflow-auto"
//                                     style={{
//                                         top: "35px",
//                                         left: 0,
//                                         width: "300px",
//                                     }}
//                                 >
//                                     {suggestions.map((suggestion, index) => (
//                                         <li
//                                             key={index}
//                                             className="p-2 hover:bg-gray-200 cursor-pointer text-sm"
//                                             onClick={() => {
//                                                 setSearchQuery(
//                                                     suggestion.name ||
//                                                     suggestion.aadhar_no ||
//                                                     suggestion.pf_account_no
//                                                 );
//                                                 handleSearch(
//                                                     suggestion.name ||
//                                                     suggestion.aadhar_no ||
//                                                     suggestion.pf_account_no
//                                                 );
//                                             }}
//                                         >
//                                             {suggestion.name ||
//                                                 suggestion.aadhar_no ||
//                                                 suggestion.pf_account_no}
//                                         </li>
//                                     ))}
//                                 </ul>
//                             )}
//                         </div>
//                     </div>

//                     <div className="flex items-center gap-2">
//                         <TablePagination
//                             component="div"
//                             count={filteredData.length}
//                             page={page}
//                             onPageChange={handleChangePage}
//                             rowsPerPage={pageSize}
//                             onRowsPerPageChange={handleChangeRowsPerPage}
//                             rowsPerPageOptions={[10, 30, 50, 'All data']}
//                             labelRowsPerPage="Operators:"
//                             SelectProps={{
//                                 renderValue: (value) => (value === 'All data' ? 'All data' : value), // Display "All data" if selected
//                             }}
//                             className="bg-white rounded-lg"

//                         />
//                         <NewAssetBtn tabName="operator" />
//                         <ButtonData
//                             handleExport={handleExport}
//                             selectedRows={selectedRows}
//                             filteredData={filteredData}
//                         />
//                     </div>
//                 </div>

//                 {filteredData.length > 0 ? (
//                     <DataGrid
//                         rows={
//                             pageSize === filteredData.length
//                                 ? filteredData.map((row, index) => ({
//                                     ...row,
//                                     counter: index + 1, // Add row number for all rows
//                                 }))
//                                 : filteredData
//                                     .slice(page * pageSize, page * pageSize + pageSize) // Paginated rows
//                                     .map((row, index) => ({
//                                         ...row,
//                                         counter: page * pageSize + index + 1, // Adjust counter for paginated rows
//                                     }))
//                         }
//                         // onRowClick={(params) => {
//                         //     const rowId = params.row.id; // Use the row ID or another property to navigate
//                         //     navigate(`/operators/details/${rowId}`); // Adjust the route as needed
//                         // }}
//                         columns={columns}
//                         // checkboxSelection
//                         paginationMode="server"
//                         pagination={false}
//                         disableSelectionOnClick
//                         // sx={{
//                         //   "& .MuiDataGrid-footerContainer": {
//                         //     display: "none", // Hides the footer container
//                         //   },
//                         //   "& .MuiDataGrid-root": { fontSize: "12px", height: 30 },
//                         //   "& .MuiDataGrid-columnHeaders": {
//                         //     fontSize: "14px",
//                         //     backgroundColor: "#000",
//                         //     fontWeight: "bold",
//                         //     color: "#006DB6",
//                         //   },
//                         //   "& .MuiDataGrid-columnHeaderTitle": {
//                         //     fontWeight: "bold",
//                         //     height: 20,
//                         //   },
//                         //   "& .MuiDataGrid-cell": { fontSize: "12px" },
//                         //   "& .MuiDataGrid-virtualScroller": {
//                         //     "&::-webkit-scrollbar": {
//                         //       display: "none",
//                         //     },
//                         //     "-ms-overflow-style": "none",
//                         //     "scrollbar-width": "none",
//                         //   },
//                         // }}
//                         sx={{
//                             "& .MuiDataGrid-footerContainer": {
//                                 display: "none", // Hides the footer container
//                             },
//                             "& .MuiDataGrid-root": { fontSize: "12px", height: 30 },
//                             "& .MuiDataGrid-columnHeaders": {
//                                 fontSize: "12px",
//                                 backgroundColor: "#000",
//                                 fontWeight: "bold",
//                                 color: "#000",
//                             },
//                             "& .MuiDataGrid-columnHeaderTitle": {
//                                 fontWeight: "bold",
//                                 height: 20,
//                             },
//                             "& .MuiDataGrid-cell": { fontSize: "12px", cursor: "pointer" },
//                             "& .MuiDataGrid-virtualScroller": {
//                                 "&::-webkit-scrollbar": {
//                                     display: "none",
//                                 },
//                                 "-ms-overflow-style": "none",
//                                 "scrollbar-width": "none",
//                             },
//                             "& .MuiDataGrid-cell:focus": {
//                                 outline: "none",
//                             },
//                         }}
//                         onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
//                     />
//                 ) : (
//                     <>
//                         <div className="text-xs items-center flex justify-center p-10 border">
//                             No Assets Found in your search area
//                         </div>
//                     </>
//                 )}
//             </Paper>
//         </div>)
// }

// export default NewOperatorTableWithSalary
// import React, { useEffect, useState } from "react";
// import { setBaseUrl } from "../../../config";

// const NewOperatorTableWithSalary = () => {
//   const [operators, setOperators] = useState([]);
//   const [selectedMonth, setSelectedMonth] = useState("");
//   const [loader, setLoader] = useState(false);
//   const [workingDaysMap, setWorkingDaysMap] = useState({}); // Store working days separately
//     const [form,setForm] = useState({
//         working_days: 0,
//         month_days: selectedMonth.length,
//         id: null
//     })
//   const getOperators = async () => {
//     if (!selectedMonth) return;

//     try {
//       const res = await fetch(
//         `${setBaseUrl}/operator/get-operator-list-by-month`,
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             "x-access-tokens": sessionStorage.getItem("token"),
//             "year-month": selectedMonth,
//           },
//         }
//       );

//       const data = await res.json();

//       if (Array.isArray(data)) {
//         setOperators(data);

//         // Initialize working days state with default values from API
//         const initialWorkingDays = {};
//         data.forEach((operator) => {
//           initialWorkingDays[operator.id] = operator.working_days || 0; // Set default
//         });
//         setWorkingDaysMap(initialWorkingDays);
//       } else {
//         console.error("Unexpected API response:", data);
//       }
//     } catch (error) {
//       console.log("Error fetching operators:", error);
//     }
//   };

//   useEffect(() => {
//     if (selectedMonth) {
//       getOperators();
//     }
//   }, [selectedMonth]);

// //   console.log(workingDaysMap)

//   const handleGetSalary = (operatorId, oldWorkingDays, newWorkingDays) => {
//     if (!selectedMonth) {
//       alert("Please select a month");
//       return;
//     }

//     if (newWorkingDays === 0) {
//       alert("Please enter the working days");
//       return;
//     }

//     if (operators.length === 0) {
//       alert("No data found for the selected month");
//       return;
//     }

//     setWorkingDaysMap((prev) => ({
//         ...prev,
//         [operatorId]: newWorkingDays
//     }))
//     const updatedOperators = operators.map((operator) =>
//       operator.id === operatorId
//        ? {...operator, working_days: newWorkingDays }
//         : operator
//     );
//     setForm({...form, working_days: updatedOperators.working_days, id: updatedOperators.id});
//   };

//   console.log(form)

//   return (
//     <div className="flex flex-col items-start my-6 gap-5">
//       <input
//         type="month"
//         className="border border-gray-300 p-2 rounded"
//         onChange={(e) => setSelectedMonth(e.target.value)}
//       />

//       {selectedMonth ? (
//         operators.length > 0 ? (
//           <table className="text-[10px] border">
//             <thead className="border bg-light-8">
//               <tr>
//                 <th className="p-2">#</th>
//                 <th className="p-2">Name</th>
// <th className="p-2">Aadhar No</th>
// <th className="p-2">Account No</th>
// <th className="p-2">Pf Account No</th>
// <th className="p-2">IFSC Code</th>
// <th className="p-2">Net Salary</th>
// <th className="p-2">Advanced Inhand Salary</th>
//                 <th className="p-2">Enter Month</th>
//                 <th className="p-2">Attendance in Month</th>
//                 <th className="p-2">Deduction</th>
//                 <th className="p-2">Overtime Amount</th>
//               </tr>
//             </thead>
//             <tbody className="divide-y text-[10px]">
//               {operators.map((operator, index) => (
//                 <tr key={operator.id}>
//                   <td className="p-2">{index + 1}</td>
//                   <td className="p-2">{operator.name}</td>
//   <td className="p-2">{operator.aadhar_no}</td>
//   <td className="p-2">{operator.account_no}</td>
//   <td className="p-2">{operator.pf_account_no}</td>
//   <td className="p-2">{operator.ifsc_code}</td>
//   <td className="p-2">{operator.net_inhand_salary}</td>
//   <td className="p-2">
//     <input
//       type="number" min={0} maxLength={25}
//       value={operator.advance_paid_amount || 0}
//       className="text-[10px]"
//       readOnly
//     />
//   </td>
//   <td className="p-2">
//     <input type="month" value={selectedMonth} className="text-[10px]" readOnly />
//   </td>
//                   <td className="p-2">
//                     <input
//                       type="number" min={0} maxLength={25}
//                       min={0}
//                       max={30}
//                       value={workingDaysMap[operator.id] || 0}
//                       className="text-[10px] border p-1 rounded text-center w-16"
//                       onChange={(e) => {
//                         const newValue = parseInt(e.target.value) || 0;

//                         // Update only the selected operator's working days
//                         setWorkingDaysMap((prev) => ({
//                           ...prev,
//                           [operator.id]: newValue,
//                         }));

//                         handleGetSalary(operator.id, operator.working_days, newValue);
//                       }}
//                     />
//                   </td>
//                   <td className="p-2">{operator.deduction}</td>
//                   <td className="p-2">{operator.overtime_amount}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         ) : (
//           <p className="text-red-500 mt-4">No data found for the selected month.</p>
//         )
//       ) : (
//         <p className="text-gray-500 mt-4">Please Select a Month</p>
//       )}
//     </div>
//   );
// };

// export default NewOperatorTableWithSalary;

import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import moment from "moment";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { TablePagination } from "@mui/material";
import { FaSortUp, FaSortDown } from "react-icons/fa";

const NewOperatorTableWithSalary = () => {
  const [operators, setOperators] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [workingDaysMap, setWorkingDaysMap] = useState({}); // Store working days separately
  const [salaryData, setSalaryData] = useState({}); // Store calculated salaries
  const [loadingOperators, setLoadingOperators] = useState({});

  const [updateSalary, setUpdateSalary] = useState({});
  const [sortBy, setSortBy] = useState("name"); // Default sorting by "name"
  const [sortOrder, setSortOrder] = useState("asc");

  // Fetch Operators Data
  const getOperators = async () => {
    if (!selectedMonth) return;

    try {
      const res = await fetch(
        `${setBaseUrl}/operator/get-operator-list-by-month`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
            "year-month": selectedMonth,
          },
        }
      );

      const data = await res.json();

      setOperators(data);

      // Initialize working days state with default values from API
      const initialWorkingDays = {};
      data["all_data_of_arr"].forEach((operator) => {
        initialWorkingDays[operator.id] = operator.working_days || 0;
      });
      setWorkingDaysMap(initialWorkingDays);
    } catch (error) {
      console.log("Error fetching operators:", error);
    }
  };

  useEffect(() => {
    if (selectedMonth) {
      getOperators();
    }
  }, [selectedMonth]);

  function getDaysInMonth(time) {
    const [year, month] = time.split("-");
    return moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
  }
  // console.log(getDaysInMonth(selectedMonth));
  // Generate Salary API Call
  const handleGenerateSalary = async (operator) => {
    const operatorId = operator.id;
    const workingDays =
      workingDaysMap[operatorId] || operator.working_days || 0;
    const monthDays = getDaysInMonth(selectedMonth); // Get total days in the selected month

    if (!operatorId || workingDays === 0) {
      alert("Please provide valid working days.");
      return;
    }

    setLoadingOperators((prev) => ({ ...prev, [operatorId]: true }));

    try {
      const res = await fetch(`${setBaseUrl}/operator/get-salary-by-entry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify({
          id: operatorId,
          working_days: workingDays,
          month_days: monthDays,
        }),
      });

      const data = await res.json();

      if (data && data.takehome) {
        const days_ratio = workingDays / monthDays;
        const overtime_amount = salaryData[operatorId]?.overtime_amount || 0;

        // Get advance payment details from operator data
        const operatorDetails =
          operators["all_data_of_arr"]?.find((op) => op.id === operatorId) ||
          {};
        // const advancedPaid = operatorDetails.advance_paid_amount || 0;
        // const netInHand = operatorDetails.net_inhand_salary || 0;
        // const advanced_money_detuced = netInHand - advancedPaid;
        // Update the salary data for this operator
        setSalaryData((prev) => ({
          ...prev,
          [operatorId]: data, // Store salary details mapped by operator ID
        }));

        // setUpdateSalary((prev) => ({
        //   // Keep previous data and update the salary data for this operator
        //  ...prev,
        //   [operatorId]: {
        //     ...prev[operatorId],
        //     operator_id: operatorId, // Ensure operator_id is always included
        //     []: value,
        //   }
        // }))
        setUpdateSalary((prev) => ({
          ...prev,
          [operatorId]: {
            ...data,
            actual_days: monthDays,
            attend_days: workingDays,
            days_ratio,
            month_year: selectedMonth,
            overtime_amount,
            // advanced_money_detuced,
          },
        }));
      } else {
        console.error("Unexpected Salary Response:", data);
      }
    } catch (error) {
      console.log("Error generating salary:", error);
    } finally {
      setLoadingOperators((prev) => ({ ...prev, [operatorId]: false }));
    }
  };

  let alreadyHaveData = {};
  if (operators["already_salaried"]?.length > 0) {
    for (let i = 0; i < operators["already_salaried"].length; i++) {
      const operator = operators["already_salaried"][i];
      alreadyHaveData[operator.operator_id] = operator;
    }
  }
  // console.log(alreadyHaveData);
  // if(operators) {

  //   for (let i = 0; i < operators["already_salaried"].length; i++) {
  //     const operator = operators["already_salaried"][i];
  //     alreadyHaveData[operator.operator_id] = operator;
  //   }
  // }

  //   console.log(alreadyHaveData);

  // const handleExport = () => {
  //   // Map operators with their salary data
  //   const dataToExport = operators["all_data_of_arr"]?.map((operator) => ({
  //     "#": operator.id,
  //     Name: operator.name,
  //     AadharNo: operator.aadhar_no,
  //     AccountNo: operator.account_no,
  //     PfAccountNo: operator.pf_account_no,
  //     IFSCCode: operator.ifsc_code,
  //     NetSalary: operator.net_inhand_salary,
  //     AdvanceInhandSalary: operator.advance_paid_amount || 0,
  //     SelectedMonth: selectedMonth,
  //     WorkingDays: workingDaysMap[operator.id] || 0,
  //     Allowances: updateSalary[operator.id]?.allowances || "--",
  //     BasicPay: updateSalary[operator.id]?.basic_pay || "--",
  //     CTC: updateSalary[operator.id]?.ctc || "--",
  //     TakeHome: updateSalary[operator.id]?.takehome || "--",
  //     EmployeeESI: updateSalary[operator.id]?.employee_esi || "--",
  //     EmployeePF: updateSalary[operator.id]?.employee_pf || "--",
  //     EmployerESI: updateSalary[operator.id]?.employer_esi || "--",
  //     EmployerPF: updateSalary[operator.id]?.employer_pf || "--",
  //     Gross: updateSalary[operator.id]?.gross || "--",
  //     GST: updateSalary[operator.id]?.gst || "--",
  //     NetPayment: updateSalary[operator.id]?.net_payment || "--",
  //     Remittance: updateSalary[operator.id]?.remittable_3p || "--",
  //     ServiceCharge: updateSalary[operator.id]?.service_charge || "--",
  //     Subtotal: updateSalary[operator.id]?.sub_total || "--",
  //     TDS: updateSalary[operator.id]?.tds || "--",
  //   }));

  //   // Create a worksheet from the data
  //   const worksheet = XLSX.utils.json_to_sheet(dataToExport);

  //   // Create a new workbook and append the worksheet
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Operators with Salary");

  //   // Export the workbook as an Excel file
  //   XLSX.writeFile(workbook, `operators_with_salary_${selectedMonth}.xlsx`);
  // };
  const handleExport = () => {
    // Create a map for quick lookup of operator details from all_data_of_arr
    const operatorMap = new Map();
    operators["all_data_of_arr"]?.forEach((operator) => {
      operatorMap.set(operator.id, operator);
    });

    // Combine both datasets into a single array
    const allOperatorsData = [
      ...(operators["all_data_of_arr"]?.map((operator) => {
        const salaryDetails = operators["already_salaried"]?.find(
          (salary) => salary.operator_id === operator.id
        );

        return {
          Type: salaryDetails ? "Already Salaried" : "Pending Salary", // Label based on whether salary details exist
          Name: operator.name,
          AadharNo: operator.aadhar_no ? `${operator.aadhar_no}` : "", // Add leading apostrophe
          AccountNo: operator.account_no ? `${operator.account_no}` : "",
          PfAccountNo: operator.pf_account_no ? `${operator.pf_account_no}` : "",
          IFSCCode: operator.ifsc_code,
          NetSalary: parseFloat(operator.net_inhand_salary) || 0,
          AdvanceInhandSalary: parseFloat(operator.advance_paid_amount) || 0,
          SelectedMonth: selectedMonth,
          WorkingDays: parseFloat(workingDaysMap[operator.id]) || 0,
          Allowances: parseFloat(updateSalary[operator.id]?.allowances) || 0,
          BasicPay: parseFloat(updateSalary[operator.id]?.basic_pay) || 0,
          CTC: parseFloat(updateSalary[operator.id]?.ctc) || 0,
          TakeHome: parseFloat(updateSalary[operator.id]?.takehome) || 0,
          EmployeeESI: parseFloat(updateSalary[operator.id]?.employee_esi) || 0,
          EmployeePF: parseFloat(updateSalary[operator.id]?.employee_pf) || 0,
          EmployerESI: parseFloat(updateSalary[operator.id]?.employer_esi) || 0,
          EmployerPF: parseFloat(updateSalary[operator.id]?.employer_pf) || 0,
          Gross: parseFloat(updateSalary[operator.id]?.gross) || 0,
          GST: parseFloat(updateSalary[operator.id]?.gst) || 0,
          NetPayment: parseFloat(updateSalary[operator.id]?.net_payment) || 0,
          Remittance: parseFloat(updateSalary[operator.id]?.remittable_3p) || 0,
          ServiceCharge: parseFloat(updateSalary[operator.id]?.service_charge) || 0,
          Subtotal: parseFloat(updateSalary[operator.id]?.sub_total) || 0,
          TDS: parseFloat(updateSalary[operator.id]?.tds) || 0,

          // Include salary details if available
          ...(salaryDetails && {
            NetSalary: parseFloat(salaryDetails.net_payment) || 0,
            AdvanceInhandSalary: parseFloat(salaryDetails.advanced_money_detuced) || 0,
            SelectedMonth: salaryDetails.month_year || selectedMonth,
            WorkingDays: parseFloat(salaryDetails.actual_days) || 0,
            Allowances: parseFloat(salaryDetails.allowances) || 0,
            BasicPay: parseFloat(salaryDetails.basic_pay) || 0,
            CTC: parseFloat(salaryDetails.ctc) || 0,
            TakeHome: parseFloat(salaryDetails.takehome) || 0,
            EmployeeESI: parseFloat(salaryDetails.employee_esi) || 0,
            EmployeePF: parseFloat(salaryDetails.employee_pfo) || 0,
            EmployerESI: parseFloat(salaryDetails.employer_esi) || 0,
            EmployerPF: parseFloat(salaryDetails.employer_pf) || 0,
            Gross: parseFloat(salaryDetails.gross) || 0,
            GST: parseFloat(salaryDetails.gst) || 0,
            NetPayment: parseFloat(salaryDetails.net_payment) || 0,
            Remittance: parseFloat(salaryDetails.remittable_3p) || 0,
            ServiceCharge: parseFloat(salaryDetails.service_charge) || 0,
            Subtotal: parseFloat(salaryDetails.sub_total) || 0,
            TDS: parseFloat(salaryDetails.tds) || 0,
          }),
        };
      }) || []),
    ];

    // Create a worksheet from the combined data
    const worksheet = XLSX.utils.json_to_sheet(allOperatorsData);

    // Explicitly set number format for relevant columns
    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const col = XLSX.utils.encode_col(C);
      for (let R = range.s.r + 1; R <= range.e.r; ++R) {
        // Skip header row
        const cellRef = col + R;
        if (worksheet[cellRef] && !isNaN(worksheet[cellRef].v)) {
          worksheet[cellRef].t = "n"; // Force number type
        }
      }
    }

     // Force string format for specific columns
  ["C", "D", "E"].forEach((col) => { // Columns B (Aadhar), C (Account), D (PF)
    for (let R = 1; R <= allOperatorsData.length; R++) {
      const cellRef = `${col}${R + 1}`;
      if (worksheet[cellRef]) {
        worksheet[cellRef].t = "s"; // Force text format
      }
    }
  });

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Operators with Salary");

    // Export the workbook as an Excel file
    XLSX.writeFile(workbook, `operators_with_salary_${selectedMonth}.xlsx`);
  };




  // const handleSalaryChange = (operatorId, field, value) => {
  //   setUpdateSalary((prev) => ({
  //     ...prev,
  //     [operatorId]: {
  //       ...prev[operatorId],
  //       [field]: value,
  //     },
  //   }));
  // };

  // const handleSalaryChange = (operatorId, field, value) => {
  //   setUpdateSalary((prev) => ({
  //     ...prev,
  //     // [operatorId]: {
  //     ...prev[operatorId],
  //     operator_id: operatorId, // Ensure operator_id is always included
  //     [field]: value,
  //     // },
  //   }));
  // };
  // const handleSalaryChange = (operatorId, field, value) => {
  //   setUpdateSalary((prev) => ({
  //     ...prev,
  //     [operatorId]: {
  //       ...prev[operatorId],
  //       operator_id: operatorId, // Ensure operator_id is always included
  //       [field]: value,
  //     },
  //   }));
  // };

  const handleSalaryChange = (operatorId, field, value) => {
    setUpdateSalary((prev) => ({
      ...prev,
      [operatorId]: {
        ...prev[operatorId],
        operator_id: operatorId, // Ensure operator_id is always included
        [field]: value ? parseFloat(value) : 0, // Convert to number
      },
    }));
  };

  // const getFormattedUpdatedSalary = () => {
  //   return Object.keys(salaryData).map((operatorId) => {
  //     return {
  //       ...salaryData[operatorId], // Keep existing fields
  //       ...updateSalary[operatorId], // Override with updated fields
  //       operator_id: operatorId, // Ensure operator_id is present
  //     };
  //   });
  // };

  // console.log(updateSalary)

  // console.log(advancePaid)

  const getFormattedUpdatedSalary = (operatorId) => {
    // return Object.keys(salaryData).map((operatorId) => {
    //   const attend_days =  workingDaysMap[operatorId] || 31; // Default 31
    //   const actual_days = salaryData[operatorId]?.actual_days || 31; // Default 31
    //   const days_ratio = actual_days ? attend_days / actual_days : 1;

    //   const advancedPaid = operators["all_data_of_arr"]?.map(operator => {
    //     if(operator.id === operatorId) {
    //       return operator.advance_paid_amount
    //     }
    //   })

    //   const netInHand = operators["all_data_of_arr"]?.map(operator => {
    //     if(operator.id === operatorId) {
    //       return operator.net_inhand_salary
    //     }
    //   })

    //   const advancedPaidDeduct = netInHand - advancedPaid
    //   return {
    //     ...salaryData[operatorId], // Keep existing fields
    //     ...updateSalary[operatorId], // Override with updated fields
    //     operator_id: operatorId, // Ensure operator_id is present
    //     month_year: selectedMonth, // Assign selected month
    //     days_ratio, // Compute days_ratio
    //     attend_days, // Keep attend_days
    //     actual_days, // Keep actual_days
    //     overtime_amount: salaryData[operatorId]?.overtime_amount || 0, // Default to 0
    //     advanced_money_detuced: salaryData[operatorId]?.advanced_money_detuced || advancedPaidDeduct || 0, // Default to 0
    //   };
    // });

    const operatorDetails =
      operators["all_data_of_arr"]?.find(
        (operator) => operator.id === operatorId
      ) || {};

    const attend_days = workingDaysMap[operatorId] || 31;
    const actual_days = updateSalary[operatorId]?.actual_days || 31;
    const days_ratio = actual_days ? attend_days / actual_days : 1;
    const overtime = updateSalary[operatorId].overtime_amount || 0;
    const advancedMoneyDeduct =
      updateSalary[operatorId].advanced_money_detuced || 0;
    let final = 0;
    if (overtime || advancedMoneyDeduct) {
      final =
        updateSalary[operatorId]?.takehome + overtime - advancedMoneyDeduct;
    }

    return {
      [operatorId]: {
        allowances: updateSalary[operatorId]?.allowances || 0,
        basic_pay: updateSalary[operatorId]?.basic_pay || 0,
        ctc: updateSalary[operatorId]?.ctc || 0,
        employee_esi: updateSalary[operatorId]?.employee_esi || 0,
        employee_pf: updateSalary[operatorId]?.employee_pf || 0,
        employer_esi: updateSalary[operatorId]?.employer_esi || 0,
        employer_pf: updateSalary[operatorId]?.employer_pf || 0,
        gross: updateSalary[operatorId]?.gross || 0,
        gst: updateSalary[operatorId]?.gst || 0,
        net_payment: updateSalary[operatorId]?.net_payment || 0,
        remittable_3p: updateSalary[operatorId]?.remittable_3p || 0,
        service_charge: updateSalary[operatorId]?.service_charge || 0,
        sub_total: updateSalary[operatorId]?.sub_total || 0,
        takehome: final || updateSalary[operatorId]?.takehome || 0, // taken home + overtime - advance deduction
        tds: updateSalary[operatorId]?.tds || 0,
        days_ratio,
        attend_days,
        actual_days,
        overtime_amount: updateSalary[operatorId]?.overtime_amount || 0,
        advance_paid_amount: updateSalary[operatorId]?.advance_paid_amount || 0,
        month_year: selectedMonth,
        advanced_money_detuced:
          updateSalary[operatorId]?.advanced_money_detuced || 0,
      },
    };
  };

  // console.log(updateSalary)
  const handleUpdateSalary = async (operatorId) => {
    const formattedData = getFormattedUpdatedSalary(operatorId);

    // console.log(formattedData); // Debug output

    try {
      const res = await fetch(`${setBaseUrl}/operator/entry-operator-salary`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify(formattedData),
      });

      toast.success("Updated Salary!");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update Salary!");
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0); // Current page
  const [pageSize, setPageSize] = useState(10); // Rows per page

  const filteredOperators = operators["all_data_of_arr"]?.filter((operator) =>
    operator.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  // const handleChangeRowsPerPage = (event) => {
  //   setPageSize(parseInt(event.target.value, 10));
  //   setPage(0); // Reset to the first page
  // };
  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    setPageSize(value === "All data" ? filteredOperators.length : value);
    setPage(0); // Reset to the first page
  };

  // Sort the filtered operators
  const sortedOperators = filteredOperators?.sort((a, b) => {
    if (sortBy === "name") {
      return sortOrder === "asc"
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (sortBy === "aadhar_no") {
      return sortOrder === "asc"
        ? a.aadhar_no.localeCompare(b.aadhar_no)
        : b.aadhar_no.localeCompare(a.aadhar_no);
    } else {
      // Handle other fields, you can add more sorting conditions based on field type
      return 0;
    }
  });

  const handleSort = (columnName) => {
    if (sortBy === columnName) {
      // Reverse the sort order if clicking the same column
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Default sort by the selected column
      setSortBy(columnName);
      setSortOrder("asc");
    }
  };

  // Get paginated operators
  const paginatedOperators = sortedOperators?.slice(
    page * pageSize,
    page * pageSize + pageSize
  );

  return (
    <div className="flex flex-col items-start my-1 gap-0">
      {/* Month Input */}

      <div className="flex justify-between items-center w-full">
        <input
          title="Select a month"
          type="month"
          className="text-xs border rounded p-1 text-center w-[150px]"
          onChange={(e) => setSelectedMonth(e.target.value)}
        />


      </div>

      <div className="my-2  flex items-center justify-end">
        <input
          type="text"
          placeholder="Search by Operator Name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="border-light-5 border bg-gray-50 h-[30px] w-[200px] p-[5px] rounded-[4px] text-[12px] items-center"
          disabled={!selectedMonth ? true : false}
        />
        {selectedMonth && (
          <div className="flex justify-between items-center">

            <TablePagination
              component="div"
              count={filteredOperators?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 30, 50, "All data"]}
              labelRowsPerPage="operators:"
              SelectProps={{
                renderValue: (value) =>
                  value === "All data" ? "All data" : value,
              }}
            />
            <button
              title="Export Data in Excel format"
              onClick={handleExport}
              className="border text-black buttons text-xs "
            >
              Export Excel
            </button>
          </div>
        )}
      </div>

      {/* Table for Operators */}
      {selectedMonth ? (
        paginatedOperators?.length > 0 ? (
          <div className="overflow-x-auto max-w-full h-[70vh] border overflow-y-auto">
            <table className="min-w-full  table-auto text-[10px] text-gray-700 border-separate border-spacing-0 border border-gray-200 rounded-lg shadow-md overflow-hidden">
              <thead className="bg-gray-100  text-left sticky top-0 z-10">
                <tr className="text-xs text-gray-600">
                  <th className="px-3 py-2 w-[200px]">#</th>
                  <th
                    className={`px-3 py-2 w-[300px] cursor-pointer ${sortBy === "name" ? "text-red-1" : "text-black"
                      }`}
                    onClick={() => handleSort("name")}
                  >
                    Name{" "}
                    {sortBy === "name" && (
                      <span className="ml-2">
                        {sortOrder === "asc" ? (
                          <FaSortUp size={12} />
                        ) : (
                          <FaSortDown size={12} />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    className={`px-3 py-2 w-[200px] cursor-pointer ${sortBy === "aadhar_no" ? "text-red-1" : "text-black"
                      }`}
                    onClick={() => handleSort("aadhar_no")}
                  >
                    Aadhar No{" "}
                    {sortBy === "aadhar_no" && (
                      <span className="ml-2">
                        {sortOrder === "asc" ? (
                          <FaSortUp size={12} />
                        ) : (
                          <FaSortDown size={12} />
                        )}
                      </span>
                    )}
                  </th>
                  <th
                    className={`px-3 py-2 w-[200px] cursor-pointer ${sortBy === "serviceSalePerson"
                      ? "text-red-1"
                      : "text-black"
                      }`}
                    onClick={() => handleSort("serviceSalePerson")}
                  >
                    Sales Person{" "}
                    {sortBy === "serviceSalePerson" && (
                      <span className="ml-2">
                        {sortOrder === "asc" ? (
                          <FaSortUp size={12} />
                        ) : (
                          <FaSortDown size={12} />
                        )}
                      </span>
                    )}
                  </th>
                  <th className="px-3 py-2 w-[200px]">Account No</th>
                  <th className="px-3 py-2 w-[200px]">Pf Account No</th>
                  <th className="px-3 py-2 w-[200px]">IFSC Code</th>
                  <th className="px-3 py-2 w-[200px]">Net Salary</th>
                  <th className="px-3 py-2 w-[200px]">Advance In Wallet</th>
                  <th className="px-3 py-2 w-[200px]">Selected Month</th>
                  <th className="px-3 py-2 w-[200px]">Working Days</th>
                  <th className="px-3 py-2 w-[200px]">Generate Salary</th>
                  <th className="px-3 py-2 w-[200px]">Allowances</th>
                  <th className="px-3 py-2 w-[200px]">Basic Pay</th>
                  <th className="px-3 py-2 w-[200px]">CTC</th>
                  <th className="px-3 py-2 w-[200px]">Employee ESI</th>
                  <th className="px-3 py-2 w-[200px]">Employee PF</th>
                  <th className="px-3 py-2 w-[200px]">Employer's ESI</th>
                  <th className="px-3 py-2 w-[200px]">Employer's PF</th>
                  <th className="px-3 py-2 w-[200px]">Gross</th>
                  <th className="px-3 py-2 w-[200px]">GST</th>
                  <th className="px-3 py-2 w-[300px]">Net Payment</th>
                  <th className="px-3 py-2 w-[200px]">Remittance</th>
                  <th className="px-3 py-2 w-[200px]">Service Charge</th>
                  <th className="px-3 py-2 w-[200px]">Subtotal</th>
                  <th className="px-3 py-2 w-[200px]">Overtime Amount</th>
                  <th className="px-3 py-2 w-[200px]">
                    Advanced Money Deducted
                  </th>
                  <th className="px-3 py-2 w-[200px]">Take Home</th>
                  <th className="px-3 py-2 w-[200px]">Updated Take Home</th>
                  <th className="px-3 py-2 w-[200px]">TDS</th>
                  <th className="px-3 py-2 w-[200px]">Generate</th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200 bg-white max-h-96 overflow-y-auto">
                {paginatedOperators?.map((operator, index) => {
                  const isDisabledForOvertime = updateSalary[operator.id]
                    ?.takehome
                    ? false
                    : true;
                  return (
                    <tr
                      key={operator.id}
                      className="hover:bg-gray-50 transition-colors "
                    >
                      <td className="px-3 py-2 border">{index + 1}</td>
                      <td className="px-3 py-2 border w-[300px] overflow-hidden hover:overflow-visible">
                        <Link
                          className="underline font-bold text-blue-2 "
                          to={`details/${operator.id}`}
                        >
                          {operator.name}
                        </Link>
                      </td>
                      <td className="px-3 py-2 border w-[200px] overflow-hidden hover:overflow-visible">{operator.aadhar_no}</td>
                      <td className="px-3 py-2 border w-[200px] overflow-hidden hover:overflow-visible">
                        {operator.serviceSalePerson}
                      </td>
                      <td className="px-3 py-2 border">
                        {operator.account_no}
                      </td>
                      <td className="px-3 py-2 border">
                        {operator.pf_account_no}
                      </td>
                      <td className="px-3 py-2 border">{operator.ifsc_code}</td>
                      <td className="px-3 py-2 border w-[200px] overflow-hidden hover:overflow-visible">
                        {operator.net_inhand_salary}
                      </td>
                      <td className="px-3 py-2 border">
                        <input
                    
                          placeholder="Advance in wallet"
                          type="number" min={0} maxLength={25}
                          disabled
                          value={operator.advance_paid_amount}
                          className="text-xs border rounded p-1 w-full"
                        // onChange={(e) => {
                        //   const updatedValue = e.target.value
                        //     ? parseFloat(e.target.value)
                        //     : 0; // Ensure it's a number

                        //   setAdvancedPaid((prev) => ({
                        //     ...prev,
                        //     [operator.id]: {
                        //       advance: updatedValue,
                        //     },
                        //   }));
                        // }}
                        />
                      </td>
                      <td className="px-3 py-2 border">
                        <input
                          type="month"
                          value={selectedMonth}
                          className="text-xs border rounded p-1 w-full"
                          readOnly
                        />
                      </td>
                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                  
                          max={getDaysInMonth(selectedMonth)} // Set max dynamically
                          placeholder="Enter Working Days"
                          value={
                            alreadyHaveData[operator.id]?.attend_days ??
                            workingDaysMap[operator.id] ??
                            0
                          }
                          className="text-xs border rounded p-1 text-center w-16"
                          onChange={(e) => {
                            let newValue = parseInt(e.target.value) || 0;
                            const maxDays = getDaysInMonth(selectedMonth); // Get max days for selected month

                            if (newValue > maxDays) {
                              newValue = maxDays; // Prevent exceeding max days
                            }

                            setWorkingDaysMap((prev) => ({
                              ...prev,
                              [operator.id]: newValue,
                            }));
                          }}
                        />
                      </td>

                      <td className="px-3 py-2 border">
                        <button
                          className="bg-blue-2 text-white px-3 py-1 text-xs rounded hover:bg-blue-3 transition"
                          onClick={() => handleGenerateSalary(operator)}
                          disabled={loadingOperators[operator.id] || false}
                        >
                          {loadingOperators[operator.id]
                            ? "Generating..."
                            : "Generate Salary"}
                        </button>
                      </td>
                      {/* generated value */}

                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth // Ensure it's the current month
                              ? alreadyHaveData[operator.id].allowances
                              : salaryData[operator.id]?.allowances || 0
                          }
                          onChange={(e) => {
                            setSalaryData((prev) => ({
                              ...prev,
                              [operator.id]: {
                                ...prev[operator.id],
                                allowances: e.target.value,
                              },
                            }));
                            handleSalaryChange(
                              operator.id,
                              "allowances",
                              e.target.value
                            );
                          }}
                        />
                      </td>

                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth
                              ? alreadyHaveData[operator.id].basic_pay
                              : salaryData[operator.id]?.basic_pay || 0
                          }
                          onChange={(e) => {
                            setSalaryData({
                              ...salaryData[operator.id],
                              basic_pay: e.target.value,
                            });
                            handleSalaryChange(
                              operator.id,
                              "basic_pay",
                              e.target.value
                            );
                          }}
                        />
                      </td>

                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth // Ensure it's the current month
                              ? alreadyHaveData[operator.id].ctc
                              : salaryData[operator.id]?.ctc || 0
                          }
                          onChange={(e) => {
                            setSalaryData({
                              ...salaryData[operator.id],
                              ctc: e.target.value,
                            });

                            handleSalaryChange(
                              operator.id,
                              "ctc",
                              e.target.value
                            );
                          }}
                        />
                      </td>

                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth // Ensure it's the current month
                              ? alreadyHaveData[operator.id].employee_esi
                              : salaryData[operator.id]?.employee_esi || 0
                          }
                          onChange={(e) => {
                            setSalaryData({
                              ...salaryData[operator.id],
                              employee_esi: e.target.value,
                            });
                            handleSalaryChange(
                              operator.id,
                              "employee_esi",
                              e.target.value
                            );
                          }}
                        />
                      </td>

                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth // Ensure it's the current month
                              ? alreadyHaveData[operator.id].employee_pfo
                              : salaryData[operator.id]?.employee_pf || 0
                          }
                          onChange={(e) => {
                            setSalaryData({
                              ...salaryData[operator.id],
                              employee_pf: e.target.value,
                            });
                            handleSalaryChange(
                              operator.id,
                              "employee_pf",
                              e.target.value
                            );
                          }}
                        />
                      </td>

                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth // Ensure it's the current month
                              ? alreadyHaveData[operator.id].employer_esi
                              : salaryData[operator.id]?.employer_esi || 0
                          }
                          onChange={(e) => {
                            setSalaryData({
                              ...salaryData[operator.id],
                              employer_esi: e.target.value,
                            });
                            handleSalaryChange(
                              operator.id,
                              "employer_esi",
                              e.target.value
                            );
                          }}
                        />
                      </td>

                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth // Ensure it's the current month
                              ? alreadyHaveData[operator.id].employer_pf
                              : salaryData[operator.id]?.employer_pf || 0
                          }
                          onChange={(e) => {
                            setSalaryData({
                              ...salaryData[operator.id],
                              employer_pf: e.target.value,
                            });
                            handleSalaryChange(
                              operator.id,
                              "employer_pf",
                              e.target.value
                            );
                          }}
                        />
                      </td>

                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth // Ensure it's the current month
                              ? alreadyHaveData[operator.id].gross
                              : salaryData[operator.id]?.gross || 0
                          }
                          onChange={(e) => {
                            setSalaryData({
                              ...salaryData[operator.id],
                              gross: e.target.value,
                            });
                            handleSalaryChange(
                              operator.id,
                              "gross",
                              e.target.value
                            );
                          }}
                        />
                      </td>

                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth // Ensure it's the current month
                              ? alreadyHaveData[operator.id].gst
                              : salaryData[operator.id]?.gst || 0
                          }
                          onChange={(e) => {
                            setSalaryData({
                              ...salaryData[operator.id],
                              gst: e.target.value,
                            });
                            handleSalaryChange(
                              operator.id,
                              "gst",
                              e.target.value
                            );
                          }}
                        />
                      </td>

                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth // Ensure it's the current month
                              ? alreadyHaveData[operator.id].net_payment
                              : salaryData[operator.id]?.net_payment || 0
                          }
                          onChange={(e) => {
                            setSalaryData({
                              ...salaryData[operator.id],
                              net_payment: e.target.value,
                            });
                            handleSalaryChange(
                              operator.id,
                              "net_payment",
                              e.target.value
                            );
                          }}
                        />
                      </td>

                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth // Ensure it's the current month
                              ? alreadyHaveData[operator.id].remittable_3p
                              : salaryData[operator.id]?.remittable_3p || 0
                          }
                          onChange={(e) => {
                            setSalaryData({
                              ...salaryData[operator.id],
                              remittable_3p: e.target.value,
                            });
                            handleSalaryChange(
                              operator.id,
                              "remittable_3p",
                              e.target.value
                            );
                          }}
                        />
                      </td>

                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth // Ensure it's the current month
                              ? alreadyHaveData[operator.id].service_charge
                              : salaryData[operator.id]?.service_charge || 0
                          }
                          onChange={(e) => {
                            setSalaryData({
                              ...salaryData[operator.id],
                              service_charge: e.target.value,
                            });
                            handleSalaryChange(
                              operator.id,
                              "service_charge",
                              e.target.value
                            );
                          }}
                        />
                      </td>

                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth // Ensure it's the current month
                              ? alreadyHaveData[operator.id].sub_total
                              : salaryData[operator.id]?.sub_total || 0
                          }
                          onChange={(e) => {
                            setSalaryData({
                              ...salaryData[operator.id],
                              sub_total: e.target.value,
                            });
                            handleSalaryChange(
                              operator.id,
                              "sub_total",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td className="px-3 py-2 border">
                        <input
                          disabled={isDisabledForOvertime}
                          type="number" min={0} maxLength={25}
                 
                          // overtime_amount
                          onChange={(e) => {
                            handleSalaryChange(
                              operator.id,
                              "overtime_amount",
                              e.target.value
                            );
                          }}
                          placeholder="Overtime Amount"
                          className="text-xs border rounded p-1 text-center w-16"
                        />
                      </td>
                      <td className="px-3 py-2 border">
                        <input
                          disabled={isDisabledForOvertime}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth // Ensure it's the current month
                              ? alreadyHaveData[operator.id]
                                .advanced_money_detuced
                              : updateSalary[operator.id]
                                ?.advanced_money_detuced || 0
                          }
                          // value={
                          //   alreadyHaveData[operator.id]
                          //     ?.advanced_money_detuced ||
                          //   updateSalary[operator.id]?.advanced_money_detuced
                          // }
                          type="number" min={0} maxLength={25}
                  
                    
                          placeholder="Advance Deduct Amount"
                          onChange={(e) => {
                            handleSalaryChange(
                              operator.id,
                              "advanced_money_detuced",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth // Ensure it's the current month
                              ? alreadyHaveData[operator.id].takehome
                              : updateSalary[operator.id]?.takehome || 0
                          }
                          // value={
                          //   alreadyHaveData[operator.id]
                          //     ? alreadyHaveData[operator.id].takehome
                          //     : salaryData[operator.id]?.takehome
                          // }
                          onChange={(e) => {
                            setSalaryData({
                              ...salaryData[operator.id],
                              takehome: e.target.value,
                            });
                            handleSalaryChange(
                              operator.id,
                              "takehome",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td className="px-3 py-2 border">
                        {/* {alreadyHaveData[operator.id]
                          ? alreadyHaveData[operator.id].takehome
                          : (updateSalary[operator.id]?.takehome ||
                              salaryData[operator.id]?.takehome ||
                              0) +
                            (updateSalary[operator.id]?.overtime_amount || 0) -
                            (updateSalary[operator.id]
                              ?.advanced_money_detuced || 0)} */}
                        {alreadyHaveData[operator.id] &&
                          alreadyHaveData[operator.id]?.month_year ===
                          selectedMonth &&
                          alreadyHaveData[operator.id]?.month_year ===
                          new Date().toISOString().slice(0, 7) // Ensure current month
                          ? alreadyHaveData[operator.id].takehome
                          : (updateSalary[operator.id]?.takehome ||
                            salaryData[operator.id]?.takehome ||
                            0) +
                          (updateSalary[operator.id]?.overtime_amount || 0) -
                          (updateSalary[operator.id]
                            ?.advanced_money_detuced || 0)}
                      </td>
                      <td className="px-3 py-2 border">
                        <input
                          type="number" min={0} maxLength={25}
                          className="text-xs border rounded p-1 text-center w-16"
                          value={
                            alreadyHaveData[operator.id] &&
                              alreadyHaveData[operator.id]?.month_year ===
                              selectedMonth // Ensure it's the current month
                              ? alreadyHaveData[operator.id].tds
                              : salaryData[operator.id]?.tds || 0
                          }
                          // value={
                          //   alreadyHaveData[operator.id]
                          //     ? alreadyHaveData[operator.id].tds
                          //     : salaryData[operator.id]?.tds
                          // }
                          onChange={(e) => {
                            setSalaryData({
                              ...salaryData[operator.id],
                              tds: e.target.value,
                            });
                            handleSalaryChange(
                              operator.id,
                              "tds",
                              e.target.value
                            );
                          }}
                        />
                      </td>
                      <td className="px-3 py-2 border">
                        <button
                          className={`${!alreadyHaveData[operator.id]
                            ? "bg-blue-2 text-white"
                            : "bg-gray-300 text-black cursor-not-allowed"
                            } buttons text-[10px]`}
                          // className="bg-blue-2 text-white px-3 py-1 text-xs rounded hover:bg-blue-3 transition"
                          onClick={() => handleUpdateSalary(operator.id)}
                          disabled={alreadyHaveData[operator.id]}
                        >
                          Update
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-red-500 mt-4 text-xs">
            No data found for the selected month.
          </p>
        )
      ) : (
        <p className="text-gray-500 mt-4 text-sm">Please Select a Month</p>
      )}
    </div>
  );
};

export default NewOperatorTableWithSalary;
