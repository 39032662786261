import React, { useState } from "react";
import { setBaseUrl } from "../../../config";
import { toast } from "react-toastify";

const SalesParson = () => {
const [form, setForm] = useState({
    name: '',
    email: '',
    "sale-person": '',
    "service-person": ''
})


const  handleSubmit = async (e) => {
    // e.preventDefault()


    // console.log(form)

    try {
        const res = await fetch(`${setBaseUrl}/lease/entry-sale-service-person`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-tokens": sessionStorage.getItem("token"),
            },
            body: JSON.stringify(form)
        })

        const data = await res.json()
        toast.success(" Sales person successfully submitted")
    } catch (error) {
        console.error(error)
        toast.error("Failed to submit sales person")
    }
}


  return (
    <div className="mt-5 bg-white  rounded-lg border p-6 max-w-lg mx-auto ">
      {/* Header */}
      <h2 title="Add sales person" className="text-xs font-semibold text-black ">
        Sales Person
      </h2>

      {/* Form */}
      <div className="mt-4 space-y-4">
        {/* Sales Person Input */}
        <div>
          <label className="block text-xs font-medium text-gray-600 dark:text-gray-300">
            Sales Person
          </label>
          <input
            type="text"
            // value={form.sale_person}
            onChange={(e) => setForm({...form, name: e.target.value })}
            className="border border-gray-300 dark:border-gray-600 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-2 dark:bg-gray-800 dark:text-white"
            placeholder="Search sales person"
          />
        </div>

        {/* Email Address Input */}
        <div>
          <label className="block text-xs font-medium text-gray-600 dark:text-gray-300">
            Email Address
          </label>
          <input
            type="email"
            // value={form.email}
            onChange={(e) => setForm({...form, email: e.target.value })}
            className="border border-gray-300 dark:border-gray-600 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-2 dark:bg-gray-800 dark:text-white"
            placeholder="sales@example.com"
          />
        </div>


        <div>
          <label className="block text-xs font-medium text-gray-600 dark:text-gray-300">
          Sales person
          </label>
          <input
            type="checkbox"
            // checked={form.sale_person}
            onChange={(e) => setForm({...form, "sale-person": e.target.checked })}
            className="border w-[20px] border-gray-300 dark:border-gray-600 rounded-md p-2  focus:ring-2 focus:ring-blue-2 dark:bg-gray-800 dark:text-white"
            placeholder="sales@example.com"
          />
        </div>
        <div>
          <label className="block text-xs font-medium text-gray-600 dark:text-gray-300">
          Service person
          </label>
          <input
            type="checkbox"
            // checked={form.sale_person}
            onChange={(e) => setForm({...form, "service-person": e.target.checked })}
            className="border w-[20px] border-gray-300 dark:border-gray-600 rounded-md p-2  focus:ring-2 focus:ring-blue-2 dark:bg-gray-800 dark:text-white"
            placeholder="sales@example.com"
          />
        </div>

        {/* Submit Button */}
        <button
        //   type="submit"
          onClick={() => handleSubmit()}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 rounded-md transition-all duration-200 shadow-md"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default SalesParson;
