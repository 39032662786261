import React, { useEffect, useState } from "react";
import Select from "react-select";
import { setBaseUrl } from "../../../../config";

const BasicFormData = ({
  name,
  type,
  keyNameType,
  label,
  leaseData,
  setLeaseData,
  customerArray,
  formErrors,
  setFormErrors,
}) => {
  const [hover, setHover] = useState(false);
  const [focused, setFocused] = useState(false); // New state for focus

  const handleInputChange = (e) => {
    setLeaseData({
      ...leaseData,
      [keyNameType]: e.target.value, // Dynamically update the field by its name
    });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(customerArray);


      const [salesPerson, setSalesPerson] = useState(null);
    
      const getSalesPerson = async () => {
        try {
          const res = await fetch(`${setBaseUrl}/lease/get-sale-persons`, {
            headers: {
              "x-access-tokens": sessionStorage.getItem("token"),
            },
          });
          const data = await res.json();
          setSalesPerson(data);
        } catch (error) {
          console.error(error);
        }
      };
    
      useEffect(() => {
        getSalesPerson();
      }, []);

  return (
    <div className="flex justify-between gap-5 items-center space-y-2 text-[12px]">
      <span className="text-[10px] flex-1">{name}
        {name === 'Customer Name' || name === 'Customer PO No' || name === "Internal Reference Name" ?  <span className="text-red-1 font-bold">*</span>:'' }
     
      </span>
      {name==="Sale Person" ? 
      
      <>
        <select name="" id=""
      
        className={`border border-gray-300 p-2 focus:border-blue-400 
          
          w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8 shadow-none
          ${
          hover? "hover:border-blue-400" : ""
        } ${focused? "focus:border-blue-400" : ""
        }`}
        onChange={(e) => 

          {
            setLeaseData({
              ...leaseData,
              [keyNameType]: e.target.value, 
            })
          }
        }
        >
          <option value="">Select Sale Person</option>
          {salesPerson?.map((person) => (
            <option key={person.id} value={person.name}>
              {person.name}
            </option>
          ))}
        </select>
      </> 
      
      
      : name === "Customer Name" ? (
        <>
        <span title={name}>

          <Select
          title={name}
            name="customer"
            placeholder="Please Type 3 Characters to Search"
            options={filteredOptions} // Display the filtered customer options
            openMenuOnClick={false} // Prevent automatic opening
            onInputChange={(inputValue) => {
              setSearchTerm(inputValue); // Update search term
              if (inputValue.length >= 3) {
                // Filter customerArray based on the input value
                const filtered = customerArray.filter((customer) =>
                  customer.label
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                );
                setFilteredOptions(filtered); // Update the filtered options
              } else {
                setFilteredOptions([]); // Clear options if input is less than 3 characters
              }
            }}
            onChange={(value) => {
              setLeaseData({ ...leaseData, customer: value.value });
            }}
            styles={{
              container: (provided) => ({
                ...provided,
                width: "350px",
                fontSize: 10,
              }),
              control: (provided, state) => ({
                ...provided,
                width: "350px",
                // height: "20px",
                border:
                  state.isFocused || state.isHovered
                    ? "none"
                    : "1px solid transparent",
                border: "0px solid #D1D5DB",
                boxShadow: state.isFocused
                  ? "0 0 0 0.2px rgba(156, 163, 175, 0.25)"
                  : null,
                outline: "none",
                borderRadius: 0,
                backgroundColor: '#EEE'
              }),
            }}
          />
        </span>
        </>
      ) : name === "Lease Type" ? (
        <>
          <select
            name={name}
            title={name}
            id="value"
            className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none  "
          
            onChange={(e) =>
              setLeaseData({
                ...leaseData,
                [keyNameType]: e.target.value, 
              })
            }
          >
              <option value="">Select</option>
            <option value="dry">Dry</option>
            <option value="wet">Wet</option>
          </select>
        </>
      ) : name === "Currency" ? (
        <>
       <>
          <select
            name={name}
            title={name}
            id="value"
            className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8 shadow-none"
            
            onChange={(e) =>
              setLeaseData({
                ...leaseData,
                [keyNameType]: e.target.value, 
              })
            }
          >
            
            <option value="inr">INR</option>
            
          </select>
        </>
        </>
      ):name === "Total Working Days/Month" ? (
        <input
          type="number"
          title={name}
         min={0}
         max={31}
          className={`w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8 shadow-none`}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "" || (Number(value) >= 1 && Number(value) <= 30)) {
              handleInputChange(e); // Call the input change handler for valid values
            }
          }}
          onKeyDown={(e) => {
            // Restrict invalid characters
            const invalidKeys = ["e", "E", "+", "-", ".", ","];
            if (invalidKeys.includes(e.key)) {
              e.preventDefault();
            }
          }}
          onFocus={() => {
            setHover(true); // Set hover to true on focus
            setFocused(true); // Set focused to true on focus
          }}
          placeholder={name}
          onBlur={() => {
            setHover(false); // Set hover to false on blur
            setFocused(false); // Set focused to false on blur
          }}
        />
      )
      
       : (name === "Transport Charges(MOB)" || 
        name === "Transport Charges(De-Mob)" || 
        name === "Reimbursements") ? (
         <input
         title={name}
           type={type}
           maxLength={25}
           className={`w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8 shadow-none`}
           min={0}
           onKeyDown={(e) => {
             // Restrict invalid characters
             const invalidKeys = ["e", "E", "+", "-", ".", ","];
             if (invalidKeys.includes(e.key)) {
               e.preventDefault();
             }
           }}
           onChange={(e) => {
             const value = e.target.value;
             if (value === "" || Number(value) >= 0) {
               handleInputChange(e); // Call the input change handler for valid values
             } else {
               e.target.value = ""; // Reset invalid values
             }
           }}
           onFocus={() => {
             setHover(true); // Set hover to true on focus
             setFocused(true); // Set focused to true on focus
           }}
           placeholder={name}
           onBlur={() => {
             setHover(false); // Set hover to false on blur
             setFocused(false); // Set focused to false on blur
           }}
         />
       ) :
        (
          <div className="flex flex-col gap-2">
          <input
          title={name}
          type={type || "text"}
          maxLength={25}
          className={`w-[350px] text-[10px]  bg-light-4 rounded border ${formErrors && formErrors[`${keyNameType}`]  === true ? 'border-red-1':''} h-8 shadow-none`}
          onChange={(e) => {
            // Remove emojis from input value
            const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
            if (!emojiRegex.test(e.target.value)) {
              handleInputChange(e);
              if (e.target.value.trim() !== "") {
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  [keyNameType]: false,
                }));
              }
            } else {
              e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
            }


          }}
          onKeyDown={(e) => {
            // Prevent typing emojis
            const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
            if (emojiRegex.test(e.key)) {
              e.preventDefault();
            }
          }}
          onPaste={(e) => {
            e.preventDefault(); // Prevent pasting emojis
            const text = e.clipboardData.getData("text/plain");
            const sanitizedText = text.replace(/[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu, "");
            e.target.value = sanitizedText;
            handleInputChange({ target: { value: sanitizedText } });
          }}
          onFocus={() => {
            setHover(true);
            setFocused(true);
          }}
          placeholder={name}
          onBlur={() => {
            setHover(false);
            setFocused(false);
          }}
        />
        {formErrors && formErrors[`${keyNameType}`]  === true &&   <span className="text-red-1 text-xs">Please enter {name}</span>}
    
          </div>
        
      )}
    </div>
  );
};

export default BasicFormData;
