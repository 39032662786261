// import React, { useEffect, useState } from "react";
// import { setBaseUrl } from "../../../config";

// const MonthlyFormTable = ({ rso_id, asset_no, leaseId, tableData }) => {
//   const [selectedMonth, setSelectedMonth] = useState("");
//   const [formData, setFormData] = useState([]);
//   const [openConflict, setOpenConflict] = useState(false);
//   // existing data
//   // const [existing, setExisting] = useState(null);
//   let actionType = null;

//   // Helper function to convert HH:MM to decimal format
//   const timeToDecimal = (time) => {
//     const [hours, minutes] = time.split(":").map(Number);
//     return hours + minutes / 60;
//   };

//   // Define initial field structure
//   const initialFields = {
//     x_studio_date: "",
//     x_studio_day_type: "",
//     x_studio_time_in: "",
//     // x_studio_rental_register_no: rso_id,
//     x_studio_time_out: "",
//     x_studio_breakdown_time: "",
//     x_studio_overtime: "",
//     x_studio_operator1: "",
//     x_studio_reimbursements: "",
//   };

//   // Calculate the number of days in the selected month and year
//   const getDaysInMonth = (month, year) => new Date(year, month, 0).getDate();

//   // Handle month change
//   const handleMonthChange = (e) => {
//     const [year, month] = e.target.value.split("-");
//     setSelectedMonth(e.target.value);

//     const daysInMonth = getDaysInMonth(month, year);

//     // If month data doesn't exist in tableData, initialize formData with empty fields for each day
//     if (!tableData[e.target.value]) {
//       setFormData(
//         Array.from({ length: daysInMonth }, (_, index) => ({
//           ...initialFields,
//           x_studio_date: `${year}-${month}-${String(index + 1).padStart(
//             2,
//             "0"
//           )}`,
//         }))
//       );
//     } else {
//       // setFormData([...tableData[e.target.value], ]);
//       // If data exists, check for missing days and complete the form for the entire month
//       const updatedFormData = Array.from(
//         { length: daysInMonth },
//         (_, index) => {
//           const day = `${year}-${month}-${String(index + 1).padStart(2, "0")}`;

//           // Check if the day already exists in the tableData for the selected month
//           const existingData = tableData[e.target.value].find(
//             (entry) => entry.x_studio_date === day
//           );

//           // If existing data is found, use it; otherwise, use initialFields

//           // return { ...initialFields, x_studio_date: day}
//           return existingData
//             ? existingData
//             : {
//                 ...initialFields,
//                 x_studio_date: day,
//               };
//         }
//       );

//       setFormData(updatedFormData);
//     }
//   };

//   // Use formData directly in transformedData, whether or not tableData has data for the selected month
//   // const transformedData = formData;

//   // Filtered fields to display in the table
//   const displayedFields = [
//     "x_studio_date",
//     // "x_studio_rental_register_no",
//     "x_studio_day_type",
//     "x_studio_time_in",
//     "x_studio_time_out",
//     "x_studio_breakdown_time",
//     "x_studio_overtime",
//     "x_studio_operator1",
//     "x_studio_reimbursements",
//   ];

//   const handleChange = (dayIndex, field, value) => {

//     // Check if the field is for time and ensure the value has up to 2 decimal places
//     if (field === "x_studio_time_in" || field === "x_studio_time_out") {
//       if (/^\d{0,2}(\.\d{0,2})?$/.test(value)) {
//         setFormData((prevData) => {
//           const updatedFormData = [...prevData];
//           updatedFormData[dayIndex][field] = value;
//           return updatedFormData;
//         });
//       }
//     } else {
//       // For other fields, check if value is an array and extract the string if needed
//       const formattedValue = Array.isArray(value) ? value[1] : value;

//       setFormData((prevData) => {
//         const updatedFormData = [...prevData];
//         updatedFormData[dayIndex][field] = formattedValue;
//         return updatedFormData;
//       });
//     }
//   };

//   const [operatorData, setOperatorData] = useState(null);

//   const convertedFormData = formData
//     .filter((entry) => entry.x_studio_time_in && entry.x_studio_time_out)
//     .map((entry) => {
//       const dayType = entry.x_studio_day_type || "WD";
//       const Operator =
//         entry.x_studio_operator1[1] || operatorData[0]?.operator_name;

//         // console.log(entry.x_studio_operator1[1])
//       //   if (Array.isArray(Operator)) {
//       //     // Convert array to string
//       //     let operatorString = Operator.join(','); // Join with comma or another separator if needed
//       //     value = Operator[1]; // Take the second element
//       //     console.log("Operator as a string:", operatorString);
//       //     console.log("Value at index 1:", value);
//       // } else {
//       //     console.log("Operator is not an array.");
//       // }

//       return {
//         ...entry,
//         x_studio_day_type: dayType,
//         x_studio_time_in: entry.x_studio_time_in,
//         x_studio_time_out: entry.x_studio_time_out,
//         x_studio_rental_register_no: rso_id,
//         x_name: asset_no,
//         x_studio_operator1: Operator,
//       };
//     });

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     // Uncomment to submit data
//     SubmissionFunction();
//   };

//   const SubmissionFunction = async () => {
//     try {
//       const response = await fetch(`${setBaseUrl}/lease/entry-timesheet-data`, {
//         method: "POST",
//         body: JSON.stringify({
//           input_arr: convertedFormData,
//           action: actionType,
//         }),
//         headers: {
//           "Content-Type": "application/json",
//           "x-access-tokens": sessionStorage.getItem("token"),
//         },
//       });
//       const data = await response.json();

//       // console.log(data.type);
//       if (data.type === "conflict") {
//         setOpenConflict(true);
//       }

//       if (response.status === 200) {
//         alert("Submission Successfull");
//         window.location.reload();

//       } else {
//         alert("Submission Failure!! Please Re-Submit");
//         console.log(response);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//     // console.log(convertedFormData)
//   };

//   const [loading, setLoading] = useState(false);
//   const getOperatorByLeaseID = async () => {
//     setLoading(true);
//     try {
//       const res = await fetch(`${setBaseUrl}/lease/get-operator-by-lease-id`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           "x-access-tokens": sessionStorage.getItem("token"),
//           "lease-id": leaseId,
//         },
//       });

//       const data = await res.json();
//       setOperatorData(data);
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     getOperatorByLeaseID();
//   }, []);

//   // console.log(actionType);

//   // console.log(displayedFields);
//   return (
//     <>
//       <h2 className="text-[12px] font-semibold mb-4">Monthly Log Form</h2>
//       <input
//         type="month"
//         className="mb-4 text-[10px] focus:outline-none focus:ring-0 focus:border-none border-none shadow-md rounded-[2px]"
//         value={selectedMonth}
//         onChange={handleMonthChange}
//       />
//       <div className="container mx-auto p-6 text-[10px] bg-white shadow-md ">
//         <div className="overflow-x-auto">
//           <table className="w-full text-[10px] text-center">
//             <thead className="w-full">
//               <tr>
// {displayedFields.map((field, index) => (
//   <th
//     key={index}
//     className="px-4 py-2 border text-center text-[10px] capitalize w-[250px]"
//   >
//     {field.replace("x_studio_", "").replace(/_/g, " ")}
//   </th>
// ))}
//               </tr>
//             </thead>
//             <tbody>
//               {formData.map((dayData, dayIndex) => (
//                 <tr key={dayIndex} className="border-b text-center">
//                   {displayedFields.map((field, index) => (
//                     <td key={index} className="border">
//                       {field === "x_studio_day_type" ? (
//                         <select
//                           onChange={(e) =>
//                             handleChange(dayIndex, field, e.target.value)
//                           }
//                           value={dayData[field] || "WD"}
//                           className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                         >
//                           <option value="WD">WD</option>
//                           <option value="HD">HD</option>
//                         </select>
//                       ) : field === "x_studio_operator1" ? (
//                         <>
//                           {/* <select
//                             onChange={(e) =>
//                               handleChange(dayIndex, field, e.target.value)
//                             }
//                             value={
//                               dayData[field][1] ||
//                               operatorData[0]?.operator_name
//                             }
//                             className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                           >
//                             {dayData[field] && (
//                               <option value={dayData[field]}>
//                                 {dayData[field]}
//                               </option>
//                             )}
//                             {operatorData !== null &&
//                               operatorData?.map((operator) => (
//                                 <>
//                                   <option
//                                     value={operator.operator_name}
//                                     key={operator.id}
//                                   >
//                                     {operator.operator_name}
//                                   </option>
//                                 </>
//                               ))}
//                           </select> */}
//                           <select
//                             onChange={(e) =>
//                               handleChange(dayIndex, field, e.target.value)
//                             }
//                             value={
//                               typeof dayData[field] === "object"
//                                 ? dayData[field][1]
//                                 : dayData[field]
//                             }
//                             className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                           >
//                             {dayData[field] && (
//                               <option
//                                 value={dayData[field][1] || dayData[field]}
//                               >
//                                 {dayData[field][1] || dayData[field]}
//                               </option>
//                             )}
//                             {operatorData?.map((operator) => (
//                               <option
//                                 value={operator.operator_name}
//                                 key={operator.id}
//                               >
//                                 {operator.operator_name}
//                               </option>
//                             ))}
//                           </select>
//                         </>
//                       ) : field === "x_studio_time_in" ||
//                         field === "x_studio_time_out" ? (
//                         <input
//                           type="number"
//                           value={dayData[field]}
//                           step="0.01"
//                           onChange={(e) =>
//                             handleChange(dayIndex, field, e.target.value)
//                           }
//                           className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                         />
//                       ) : field === "x_studio_date" ? (
//                         <input
//                           type="text"
//                           value={dayData[field]}
//                           onChange={(e) =>
//                             handleChange(dayIndex, field, e.target.value)
//                           }
//                           className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                         />
//                       ) : (
//                         <input
//                           type="number"
//                           value={dayData[field]}
//                           onChange={(e) =>
//                             handleChange(dayIndex, field, e.target.value)
//                           }
//                           className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                         />
//                       )}
//                     </td>
//                   ))}
//                 </tr>
//               ))}
//             </tbody>
//             {openConflict && (
//               <>
//                 <div className="flex flex-col fixed top-[20%] left-[40%] h-[200px] w-[400px] bg-white  items-center justify-center z-[9999] shadow-md">
//                   <span className="text-sm text-light-3">
//                     Information is already field!!! <br /> Do You Want to
//                     Replace it or Your Want to keep both?{" "}
//                   </span>
//                   <div className="flex p-5 gap-2">
//                     <button
//                       className="border border-light-1 text-light-1 text-xs p-1"
//                       onClick={() => {
//                         setOpenConflict(false);
//                       }}
//                     >
//                       Cancel
//                     </button>
//                     <button
//                       className="bg-light-1 text-white text-xs p-1"
//                       onClick={() => {
//                         actionType = "replace";
//                         SubmissionFunction();
//                       }}
//                     >
//                       Replace
//                     </button>
//                     <button
//                       className="bg-red-600 text-white text-xs p-1"
//                       onClick={() => {
//                         actionType = "keep";
//                         SubmissionFunction();
//                       }}
//                     >
//                       Keep Both
//                     </button>
//                   </div>
//                 </div>

//                 <div
//                   className="z-0 fixed top-0 bottom-0 left-0 right-0 bg-[#00000028]"
//                   onClick={() => setOpenConflict(false)}
//                 />
//               </>
//             )}
//           </table>
//         </div>
//       </div>
//       <div className="flex gap-2">

//         <button className="mt-4  p-1 transition text-[10px] shadow-md bg-white text-text-1 rounded-[4px] font-semibold" onClick={() => setFormData([])}>Cancel</button>
//       <button
//         onClick={handleSubmit}
//         className="mt-4 bg-blue-2 text-white p-1 transition text-[10px] w-[39px] h-[24px] font-semibold rounded-[4px] shadow-md hover:bg-blue-1"
//         >
//         Save
//       </button>
//         </div>
//     </>
//   );
// };

// export default MonthlyFormTable;

import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  // Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Select, MenuItem, InputLabel, FormControl,

  CircularProgress,
} from "@mui/material";
// import Loading from "../../Loading";
import { capitalizeFirstLetter } from "../AssetDetails/Tabs/FormatDate";
import moment from "moment";

const MonthlyFormTable = ({
  title,
  rso_id,
  asset_no,
  leaseId,
  tableData,
  setViewData,
  rentalStartDate,
  rentalEndDate,
}) => {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [formData, setFormData] = useState([]);
  const [operatorData, setOperatorData] = useState(null);
  const [openConflict, setOpenConflict] = useState(false);
  const [
    // actionType,
    setActionType,
  ] = useState(null);
  const [loading, setLoading] = useState(false);
  const initialFields = {
    x_studio_date: "",
    x_studio_day_type: "WD",
    x_studio_time_in: "",
    x_studio_time_out: "",
    x_studio_breakdown_time: "",
    x_studio_overtime: "",
    x_studio_operator1: "",
    x_studio_reimbursements: "",
  };

  const getDaysInMonth = (month, year) => new Date(year, month, 0).getDate();

  const handleMonthChange = (e) => {
    const [year, month] = e.target.value.split("-");
    setSelectedMonth(e.target.value);

    const daysInMonth = getDaysInMonth(month, year);
    const updatedFormData = Array.from({ length: daysInMonth }, (_, index) => {
      const day = `${year}-${month}-${String(index + 1).padStart(2, "0")}`;
      // const existingData =
      //   tableData[e.target.value]?.find(
      //     (entry) => entry.x_studio_date === day
      //   ) ||
      //    null;

      return { ...initialFields, x_studio_date: day };
    });

    setFormData(updatedFormData);
  };

  const handleChange = (dayIndex, field, value) => {
    setFormData((prevData) => {
      const updatedFormData = [...prevData];
      updatedFormData[dayIndex][field] = value;
      return updatedFormData;
    });
  };

  const getOperatorByLeaseID = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-operator-by-lease-id`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "lease-id": leaseId,
        },
      });
      const data = await res.json();
      setOperatorData(data);
    } catch (error) {
      console.error("Error fetching operator data:", error);
    }
  };

  useEffect(() => {
    getOperatorByLeaseID();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Filter the formData to include only rows where data has been entered/modified
    const updatedData = formData.filter((entry) => {
      return Object.keys(entry).some((key) => {
        // Exclude "x_studio_date" since it's a default value for every row
        return (
          key !== "x_studio_date" &&
          entry[key] !== "" && // Check if the field is not empty
          entry[key] !== initialFields[key] // Ensure it's different from the initial value
        );
      });
    });

    // Add metadata to the filtered data
    const submissionData = updatedData.map((entry) => ({
      ...entry,
      x_studio_rental_register_no: rso_id,
      x_name: asset_no,
    }));

    if (submissionData.length === 0) {
      toast.info("No changes to submit.");
      setLoading(false); // Stop loading if no changes
      return;
    }

    // console.log(submissionData)
    try {
      const response = await fetch(`${setBaseUrl}/lease/entry-timesheet-data`, {
        method: "POST",
        body: JSON.stringify({
          input_arr: submissionData,
          action: "keep",
        }),
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });

      const data = await response.json();

      if (response.ok) {
        if (data.type === "conflict") {
          setOpenConflict(true);
          toast.warn("Conflict detected! Please resolve it.");
        } else {
          toast.success("Submission Successful!");
          window.location.reload();
        }
      } else {
        toast.error("Submission Failed. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false); // Ensure loading is stopped in all cases
    }
  };

  const displayedFields = [
    "x_studio_date",
    "x_studio_date",
    "x_studio_day_type",
    "x_studio_time_in",
    "x_studio_time_out",
    "x_studio_breakdown_time",
    "x_studio_overtime",
    "x_studio_operator1",
    "x_studio_reimbursements",
  ];

  const header = [
    "Date",
    "Day",
    "Day Type",
    "Time In",
    "Time Out",
    "Breakdown Time",
    "Overtime",
    "Operator",
    "Reimbursements",
  ];

  const copyPreviousRow = (dayIndex) => {
    setFormData((prevData) => {
      if (dayIndex === 0) return prevData; // Prevent copying if it's the first row

      const updatedData = [...prevData];
      updatedData[dayIndex] = { ...prevData[dayIndex - 1] }; // Copy previous row

      // Ensure the date remains unique
      updatedData[dayIndex].x_studio_date = prevData[dayIndex].x_studio_date;

      return updatedData;
    });
  };

  return (
    <>
      {/* {console.log(rentalStartDate, rentalEndDate)} */}
      <h2 className="text-[12px] font-semibold mb-4">Monthly Log Form</h2>
      <input
        title="select month for add timesheet"
        type="month"
        min={rentalStartDate?.slice(0, 7)} // Extract YYYY-MM
        max={rentalEndDate?.slice(0, 7)}
        className="mb-4 text-[10px] shadow-md border-none focus:border-none active:ring-0 rounded-[2px]"
        value={selectedMonth}
        onChange={(e) => {
          const selectedValue = e.target.value; // YYYY-MM format

          const startMonth = rentalStartDate?.slice(0, 7);
          const endMonth = rentalEndDate?.slice(0, 7);

          if (
            (startMonth && selectedValue < startMonth) ||
            (endMonth && selectedValue > endMonth)
          ) {
            e.preventDefault();
            return;
          }

          handleMonthChange(e); // Only update state if within range
        }}
      />

      <div className=" mx-auto text-[10px] bg-white">
        <div className="overflow-x-auto">
          <table className="w-full text-[10px] text-center">
            <thead>
              <tr>
                {header.map((field) => (
                  <th key={field} className="p-4 bg-light-4">
                    {/* {capitalizeFirstLetter(field.replace("x_studio_", "", 1).replace(/_/g, " "))} */}
                    {field}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {formData.map((dayData, dayIndex) => {
                const currentDate = dayData["x_studio_date"]; // Assuming date is in YYYY-MM-DD format
                const isDisabled =
                  (rentalStartDate && currentDate < rentalStartDate) ||
                  (rentalEndDate && currentDate > rentalEndDate);
                  const rowClass = dayData.x_studio_day_type === "HD" ? "bg-red-100" : "bg-white";
                return (
                  <tr key={dayIndex} className={`border ${rowClass}`}>
                    {displayedFields.map((field, i) => (
                      <td
                        key={field}
                        className={`border w-fit ${
                          isDisabled ? "border-red-1 text-red-1" : "bg-transparent"
                        } `}
                      >
                        {field === "x_studio_date" && i === 1 ? ( // Check if it's the second occurrence
                          moment(dayData["x_studio_date"]).format("dddd") // Format as full weekday name (e.g., Monday)
                        ) : field === "x_studio_day_type" ? (
                          <select
                            value={dayData[field]}
                            onChange={(e) =>
                              handleChange(dayIndex, field, e.target.value)
                            }
                            disabled={isDisabled}
                            className={`"w-full text-[10px] focus:outline-none border-none bg-transparent "`}
                          >
                            <option value="WD">WD</option>
                            <option value="HD">HD</option>
                          </select>
                        ) : field === "x_studio_operator1" ? (
                          <select
                            value={dayData[field]}
                            onChange={(e) =>
                              handleChange(dayIndex, field, e.target.value)
                            }
                            disabled={isDisabled}
                            className={` "w-full text-[10px] focus:outline-none border-none bg-transparent "`}
                          >
                            <option value="">Select Operator</option>
                            {operatorData?.map((operator) => (
                              <option
                                key={operator.id}
                                value={operator.operator_name}
                              >
                                {operator.operator_name}
                              </option>
                            ))}
                          </select>
                        ) : field === "x_studio_date" ? (
                          <input
                            type={
                              field.includes("time")
                                ? "number"
                                : field === "x_studio_date"
                                ? "text"
                                : "number"
                            }
                            value={dayData[field]}
                            onChange={(e) =>
                              handleChange(dayIndex, field, e.target.value)
                            }
                            disabled={isDisabled}
                            className={` "w-full text-[10px] focus:outline-none border-none bg-transparent"`}
                          />
                        ) : field === "x_studio_time_in" ||
                          field === "x_studio_time_out" ? (
                          <input
                            min={0}
                            step="0.01"
                            type={
                              field.includes("time")
                                ? "number"
                                : field === "x_studio_date"
                                ? "text"
                                : "number"
                            }
                            value={dayData[field]}
                            // onChange={(e) =>
                            //   handleChange(dayIndex, field, e.target.value)
                            // }
                            onChange={(e) => {
                              // let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                              let inputValue = e.target.value;
                              inputValue = inputValue.replace(/[^0-9.]/g, "");

                              // if (!inputValue) {
                              //   inputValue = "0";
                              // }

                              let parts = inputValue.split(".");
                              if (parts.length > 2) {
                                inputValue = parts[0] + "." + parts[1]; // Keep only the first decimal point
                              }

                              // Limit to 2 digits before and 2 digits after the decimal
                              if (parts[0].length > 2) {
                                parts[0] = parts[0].slice(0, 2); // Keep only first 2 digits before decimal
                              }
                              if (parts[1]?.length > 2) {
                                parts[1] = parts[1].slice(0, 2); // Keep only first 2 digits after decimal
                              }

                              // Combine the parts back into a valid format
                              inputValue =
                                parts.length > 1
                                  ? parts[0] + "." + (parts[1] || "")
                                  : parts[0];

                              // if (field.includes("time") && inputValue.length > 2) {
                              //   // Convert last two digits into decimals
                              //   let intPart = inputValue.slice(0, -2); // Take all but last 2 digits
                              //   let decimalPart = inputValue.slice(-2); // Take last 2 digits
                              //   inputValue = `${intPart}.${decimalPart}`; // Format as float
                              // } else {
                              //   inputValue = parseInt(inputValue, 10).toString(); // Keep as an integer
                              // }

                              handleChange(dayIndex, field, inputValue);
                            }}
                            disabled={isDisabled}
                            className={` "w-full text-[10px] focus:outline-none border-none bg-transparent `}
                          />
                        ) : field === "x_studio_breakdown_time" ||
                          field === "x_studio_overtime" ? (
                          <input
                            min={0}
                            type={
                              field.includes("time")
                                ? "number"
                                : field === "x_studio_date"
                                ? "text"
                                : "number"
                            }
                            value={dayData[field]}
                            // onChange={(e) =>
                            //   handleChange(dayIndex, field, e.target.value)
                            // }
                            onChange={(e) => {
                              // let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                              // if (!inputValue) {
                              //   inputValue = "0";
                              // }

                              // if (field.includes("time") && inputValue.length > 2) {
                              //   // Convert last two digits into decimals
                              //   let intPart = inputValue.slice(0, -2); // Take all but last 2 digits
                              //   let decimalPart = inputValue.slice(-2); // Take last 2 digits
                              //   inputValue = `${intPart}.${decimalPart}`; // Format as float
                              // } else {
                              //   inputValue = parseInt(inputValue, 10).toString(); // Keep as an integer
                              // }
                              let inputValue = e.target.value;
                              inputValue = inputValue.replace(/[^0-9.]/g, "");

                              // if (!inputValue) {
                              //   inputValue = "0";
                              // }

                              let parts = inputValue.split(".");
                              if (parts.length > 2) {
                                inputValue = parts[0] + "." + parts[1]; // Keep only the first decimal point
                              }

                              // Limit to 2 digits before and 2 digits after the decimal
                              if (parts[0].length > 2) {
                                parts[0] = parts[0].slice(0, 2); // Keep only first 2 digits before decimal
                              }
                              if (parts[1]?.length > 2) {
                                parts[1] = parts[1].slice(0, 2); // Keep only first 2 digits after decimal
                              }

                              // Combine the parts back into a valid format
                              inputValue =
                                parts.length > 1
                                  ? parts[0] + "." + (parts[1] || "")
                                  : parts[0];

                              handleChange(dayIndex, field, inputValue);
                            }}
                            disabled={isDisabled}
                            className={`w-full text-[10px] focus:outline-none border-none bg-transparent`}
                          />
                        ) : (
                          <input
                            min={0}
                            step="0.01"
                            type={
                              field.includes("time")
                                ? "number"
                                : field === "x_studio_date"
                                ? "text"
                                : "number"
                            }
                            // value={dayData[field]}
                            onChange={(e) =>
                              handleChange(dayIndex, field, e.target.value)
                            }
                            disabled={isDisabled}
                            className={` w-full text-[10px] focus:outline-none border-none  bg-transparent `}
                          />
                        )}
                      </td>
                    ))}

                    <td className="border p-2">
                      {dayIndex > 0 && (
                        <button
                        title="Copy the previous day entered data"
                          onClick={() => copyPreviousRow(dayIndex)}
                          className="bg-blue-500 text-white px-2 py-1 rounded-md text-xs hover:bg-blue-600 transition"
                        >
                          Copy
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex gap-2 mt-4">
        <button
          onClick={() => {
            setFormData([]);
            setViewData(true);
          }}
          className=" bg-white text-dark-6 buttons"
        >
          Cancel
        </button>
        {!formData.length > 0 ? (
          <button className="bg-dark-6 text-white buttons">Save</button>
        ) : (
          <button
            onClick={handleSubmit}
            className=" text-white  bg-blue-2 buttons"
          >
            {loading ? (
              <CircularProgress size={18} sx={{ color: "#fff" }} />
            ) : (
              "Save"
            )}
          </button>
        )}
      </div>
      {openConflict && (
        <>
          <div className="fixed top-1/3 left-1/3 p-4 bg-white shadow-lg z-50">
            <p>Conflict detected! Would you like to replace or keep both?</p>
            <div className="flex gap-2 mt-2">
              <button
                className="bg-gray-300 p-1 rounded"
                onClick={() => {
                  setOpenConflict(false);
                }}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white p-1 rounded"
                onClick={() => {
                  setActionType("replace");
                  handleSubmit();
                }}
              >
                Replace
              </button>
              <button
                className="bg-red-500 text-white p-1 rounded"
                onClick={() => {
                  setActionType("keep");
                  handleSubmit();
                }}
              >
                Keep Both
              </button>
            </div>
          </div>
          <div
            className="fixed top-0 left-0 right-0 bottom-0 bg-[#00000002] opacity-50 z-40"
            onClick={() => setOpenConflict(false)}
          ></div>
        </>
      )}
    </>
  );
};

export default MonthlyFormTable;
