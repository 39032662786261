import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { setBaseUrl } from '../../config';
import Select from "react-select";

export const UpdateEmployee = () => {

  const { email } = useParams();
  // console.log(email)

  const navigate = useNavigate();

  const selectOptions = [
    {
      value: "ASSETS.ALL",
      label: "ASSETS.ALL",
    },
    { value: "ASSETS.VIEW", label: "ASSETS.VIEW" },
    { value: "ASSETS.CRU", label: "ASSETS.CRU" },
    { value: "MAINT.ALL", label: "MAINT.ALL" },
    { value: "MAINT.CRU", label: "MAINT.CRU" },
    { value: "MAINT.VIEW", label: "MAINT.VIEW" },

    { value: "LEASE.ALL", label: "LEASE.ALL" },
    { value: "LEASE.CRU", label: "LEASE.CRU" },
    { value: "LEASE.VIEW", label: "LEASE.VIEW" },
    { value: "OPERATOR.ALL", label: "OPERATOR.ALL" },
    { value: "OPERATOR.CRU", label: "OPERATOR.CRU" },
    { value: "OPERATOR.VIEW", label: "OPERATOR.VIEW" },
    { value: "ADMIN.ALL", label: "ADMIN.ALL" },
  ];

  const [selectOption, setSelectedOption] = useState(null);
  const [employeeDetail, setEmployeeDetail] = useState({ email: email, reset_password: false});

  const getAllPermissions = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/company/view-permissions-for-update`,
        {
          headers: {
            "x-access-tokens": sessionStorage.getItem("token"),
            "email": email
          }
        })
      // console.log(data);
      // setSelectedOption()
    } catch (error) {
      console.error(error)
      alert(error.message)
    }

  }

  useEffect(() => {
    getAllPermissions();
  }, [])


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    

    try {
      const res = await fetch(`${setBaseUrl}/company/add-employee`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify(employeeDetail),

        // body: JSON.stringify({
        //   email: email,
        //   permissions: selectOption? selectOption.value : []
        // }),

      })
    } catch (error) {
      console.error(error)
    }

  }




  // /company/edit-permissions

  return (
    // <div className="h-full w-full dark:test-white mt-5">
    //   <div className="flex justify-start items-center mx-10 my-0">
    //     <div className="flex justify-between items-center">
    //       <button onClick={() => navigate('/settings')}
    //       className='border border-light-2 text-xs rounded-[4px] p-0.5 px-2'
    //       >
    //         back
    //       </button>

    //     </div>
    //   </div>
    //   <div className="intro-y mx-10 overflow-auto border lg:overflow-visible mt-10 sm:mt-0">
    //     <div className="flex flex-col gap-2 justify-center items-center">
    //       <label htmlFor="email">Email:</label>
    //       <input
    //         type="email"
    //         name="email"
    //         value={email}
    //         required
    //         placeholder="Email"
    //         className="p-2 mx-4 text-xs w-[200px] bg-light-4 border-none focus:border-none focus:outline-none focus:ring-0 rounded"
    //         onChange={(e) =>
    //           setEmployeeDetail({ ...employeeDetail, email: e.target.value })
    //         }
    //       />
    //       <label htmlFor="name">Name:</label>
    //       <input
    //         type="text"
    //         name="name"
    //         required
    //         placeholder="Employee Name"
    //         className="p-2 mx-4 text-xs w-[200px] bg-light-4 border-none focus:border-none focus:outline-none focus:ring-0 rounded"
    //         onChange={(e) =>
    //           setEmployeeDetail({ ...employeeDetail, name: e.target.value })
    //         }
    //       />
    //       <label>Choose Permissions: </label>
    //       <Select
    //         isMulti
    //         onChange={setSelectedOption}
    //         options={selectOptions}
    // styles={{
    //   container: (provided) => ({
    //     ...provided,
    //     width: "200px",
    //     fontSize: 10,
    //   }),
    //   control: (provided, state) => ({
    //     ...provided,
    //     width: "200px",
    //     height: "20px",
    //     border:
    //       state.isFocused || state.isHovered
    //         ? "none"
    //         : "1px solid transparent",
    //     borderBottom: "1px solid #D1D5DB",
    //     boxShadow: state.isFocused
    //       ? "0.2px 0.2px 0.2px 0.2px rgba(156, 163, 175, 0.25)"
    //       : null,
    //     outline: "none",
    //     borderRadius: 0,
    //   }),
    // }}
    //       />
    //       <button
    //         type="submit"
    //         className="bg-blue-2 text-white px-3 py-1 text-xs rounded"
    //         onClick={handleSubmit}
    //       >
    //         Submit
    //       </button>
    //     </div>
    //   </div>
    // </div>

    <>
      <div className="h-full w-full mt-5 p-5  dark:bg-gray-800 text-gray-800 dark:text-white">
        <div className="flex justify-start items-center  pb-2">
          <button
            onClick={() => navigate('/settings')}
            className="buttons border bg-white text-black"
          >
            Back
          </button>
        </div>

        <div className="mt-10 bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 max-w-md mx-auto">
          <h2 className="text-md font-semibold text-center mb-4">Employee Details</h2>

          <div className="flex flex-col gap-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium mb-1">
                Email:
              </label>
              <input
                type="email"
                name="email"
                value={email}
                required
                placeholder="Enter email"
                className="p-2  text-xs w-full bg-light-4 border-none focus:border-none focus:outline-none focus:ring-0 rounded"
                onChange={(e) =>
                  setEmployeeDetail({ ...employeeDetail, email: e.target.value })
                }
              />
            </div>

            {/* <div>
              <label htmlFor="name" className="block text-sm font-medium mb-1">
                Name:
              </label>
              <input
                type="text"
                name="name"
                required
                placeholder="Enter employee name"
                className="p-2  text-xs w-full bg-light-4 border-none focus:border-none focus:outline-none focus:ring-0 rounded"
                onChange={(e) =>
                  setEmployeeDetail({ ...employeeDetail, name: e.target.value })
                }
              />
            </div> */}

            {/* <div>
              <label htmlFor="permissions" className="block text-sm font-medium mb-1">
                Choose Permissions:
              </label>
              <Select
                isMulti
                onChange={setSelectedOption}
                options={selectOptions}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: "180px",
                    fontSize: 10,
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    width: "59vh",
                    // height: "20px",
                    border:
                      state.isFocused || state.isHovered
                        ? "none"
                        : "1px solid transparent",
                    borderBottom: "1px solid #D1D5DB",
                    boxShadow: state.isFocused
                      ? "0.2px 0.2px 0.2px 0.2px rgba(156, 163, 175, 0.25)"
                      : null,
                    outline: "none",
                    borderRadius: 0,
                  }),
                }}
              />
            </div> */}

            {/* <Link to='/change-password' className="text-xs w-full px-4 py-2 text-center items-center text-white bg-blue-2"  >Change Password</Link> */}
            <button
              type="submit"
              className="text-xs w-full px-4 py-2 text-white bg-blue-2"
              onClick={(e) =>  {
                setEmployeeDetail({
                  ...employeeDetail,
                  reset_password: true
                })
                handleSubmit(e)}}
            >
              Reset Password
            </button>
          </div>
        </div>
      </div>

    </>
  )
}
