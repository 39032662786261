import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setBaseUrl } from "../config";
import OuterLoader from "./OuterLoder";
import Layout from "../components/Layout";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

export const ChangePasswordNewUser = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [creds, setCreds] = useState({ email: "", old_password: "", new_password: "" });
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const handleSubmit = async () => {
        try {
            setLoader(true);
            await axios.post(`${setBaseUrl}/change-password`, {
                email: creds.email,
                old_password: creds.old_password,
                new_password: creds.new_password,
            });
            sessionStorage.clear();
            navigate("/login");
            setIsSuccess(true);
        } catch (error) {
            console.error(error);
            setLoader(false);
            alert(error.response?.data?.message || "An error occurred");
        }
    };

    if (loader) return <OuterLoader />;

    return (
        <Layout>
            {isSuccess ? (
                <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="h-12 w-12 text-green-500 mx-auto"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            />
                        </svg>
                        <h2 className="text-xs font-semibold text-gray-700 mt-4">Password changed successfully!</h2>
                        <a href="/login" className="text-blue-500 hover:underline mt-2 inline-block">
                            Login
                        </a>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                        <h1 className="text-xs font-bold text-center text-gray-700 mb-6">Change Password</h1>
                        <div className="flex flex-col space-y-4">
                            <input
                                type="email"
                                required
                                placeholder="Email"
                                className="p-3 border text-xs rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                                onChange={(e) => setCreds({ ...creds, email: e.target.value })}
                            />
                            <div className="relative w-full">
                                <input
                                    type={showOldPassword ? "text" : "password"}
                                    required
                                    placeholder="Old Password"
                                    className="p-3 border text-xs rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    onChange={(e) => setCreds({ ...creds, old_password: e.target.value })}
                                />
                                <button
                                    type="button"
                                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                                    onClick={() => setShowOldPassword(!showOldPassword)}
                                >
                                    {showOldPassword ? <RemoveRedEyeOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </button>
                            </div>
                            <div className="relative w-full">
                                <input
                                    type={showNewPassword ? "text" : "password"}
                                    required
                                    placeholder="New Password"
                                    className="p-3 border text-xs rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    onChange={(e) => setCreds({ ...creds, new_password: e.target.value })}
                                />
                                <button
                                    type="button"
                                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                >
                                    {showNewPassword ? <RemoveRedEyeOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </button>
                            </div>
                            <div className="flex justify-between mt-4">
                                <button
                                    className="border buttons  bg-gray-200 hover:bg-gray-300"
                                    onClick={() => navigate(-1)}
                                >
                                    Back
                                </button>
                                <button
                                    type="submit"
                                    className="buttons bg-blue-500 text-white hover:bg-blue-600"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
};
