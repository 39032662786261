import React from "react";
import {
  capitalizeFirstLetter,
  currency,
  formatDate,
} from "../../AssetDetails/Tabs/FormatDate";

const LeaseDashboard = ({ leaseSpecificDetails }) => {
  let numb = 0;
  if (leaseSpecificDetails?.lease_status?.startsWith("expiring")) {
    // Match a number within the string "expiring in X days"
    const match = leaseSpecificDetails?.lease_status.match(/\d+/);

    if (match) {
      numb = parseInt(match[0], 10);
    }
  }

 let normalBillingAmount = 0;
 let overtimeBillingAmount = 0;
 let totalBillingAmount = 0;

 if( leaseSpecificDetails.per_month_biling_data) {

   
    for(let i = 0; i < leaseSpecificDetails.per_month_biling_data.length; i++ ) {
       normalBillingAmount += leaseSpecificDetails.per_month_biling_data[i].actual_normal_working_ammount
       overtimeBillingAmount += leaseSpecificDetails.per_month_biling_data[i].actual_overtime_working_ammount
       totalBillingAmount += leaseSpecificDetails.per_month_biling_data[i].actual_total_working_ammount
    }
 }

  return (
    <>
      {/* New UI Design */}

      <div className="grid grid-cols-3 mt-3 gap-2">
        {/* Card 1 - Basic Details */}
        <div className="bg-white p-4 flex flex-col gap-1 border">
          <span title="Basic Details" className="text-xs font-bold mb-2">Basic Details</span>
          <table className="w-full text-xs">
            <tbody>
              {[
                {
                  label: "Customer PO Number",
                  value:
                    leaseSpecificDetails?.customer_po_no === "null"
                      ? "N/A"
                      : leaseSpecificDetails?.customer_po_no,
                },
                {
                  label: "Customer",
                  value: leaseSpecificDetails?.customer || "N/A",
                },
                {
                  label: "Lease current status",
                  value: (
                    <span
                      className={`font-semibold ${  new Date(leaseSpecificDetails?.rental_start_date) > new Date() ? 'text-blue-2' : 
                        leaseSpecificDetails?.lease_status === "inactive"
                          ? "text-gray-500"
                          : leaseSpecificDetails?.lease_status === 
                            "never assigned" || leaseSpecificDetails?.lease_status ===
                            "expired"
                          ? "text-red-600"
                          : "text-green-500"
                      }`}
                    >
                      {new Date(leaseSpecificDetails?.rental_start_date) > new Date() ? 'Booked' : leaseSpecificDetails?.lease_status?.startsWith(
                        "expiring"
                      )
                        ? `Active (${numb} days)`
                        : leaseSpecificDetails?.lease_status === "inactive"
                        ? "Closed"
                        : leaseSpecificDetails?.lease_status === "expired" ? "Exceeded" : capitalizeFirstLetter(
                            leaseSpecificDetails?.lease_status
                          )}
                    </span>
                  ),
                },
                // {
                //   label: "Rental Start Date",
                //   value:
                //     formatDate(leaseSpecificDetails?.rental_start_date) ||
                //     "N/A",
                // },
                // {
                //   label: "Rental End Date",
                //   value: leaseSpecificDetails?.rental_extended_date
                //     ? formatDate(leaseSpecificDetails?.rental_extended_date)
                //     : leaseSpecificDetails?.closed_date
                //     ? formatDate(leaseSpecificDetails?.closed_date)
                //     : formatDate(leaseSpecificDetails?.rental_end_date),
                // },
                {
                  label: "Sales Person Name",
                  value: leaseSpecificDetails?.sale_person === "null" || leaseSpecificDetails?.sale_person === null || leaseSpecificDetails?.sale_person === "nill" ? "N/A" : leaseSpecificDetails?.sale_person
                   
                },
                {
                  label: "Operator Name",
                  value: Array.isArray(leaseSpecificDetails?.operators)
                    ? leaseSpecificDetails?.operators.map((op) => op.operator_name).join(", ")
                    : leaseSpecificDetails?.operators?.operator_name || "N/A",
                },
                
                {
                  label: "Rental Location",
                  value: leaseSpecificDetails?.rental_location
                    ? leaseSpecificDetails?.rental_location
                    : "N/A",
                },
              ].map(({ label, value }, idx) => (
                <tr key={idx} className="border-b">
                  <td title={label} className="py-2 font-medium text-gray-500">
                    {label}:
                  </td>
                  <td title={label} className="py-2">
                    {value || "--"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Card 2 - Contract Details */}
        <div className="bg-white p-4 flex flex-col gap-1 border">
          <span title="Contract Details" className="text-xs font-bold mb-2">Contract Details</span>
          <table className="w-full text-xs">
            <tbody>
              {[
                {
                  label: "Internal Reference Number",
                  value: leaseSpecificDetails?.internal_reference_number || "N/A",
                },
                {
                  label: "Lease Type",
                  // value: leaseSpecificDetails?.lease_type
                  //   ? leaseSpecificDetails?.lease_type === "wet_lease"
                  //     ? "Wet Lease"
                  //     : leaseSpecificDetails?.lease_type ===
                  //       "customer_certified"
                  //     ? "Customer Certified"
                  //     : leaseSpecificDetails?.lease_type === "dry_lease"
                  //     ? "Dry Lease"
                  //     : leaseSpecificDetails?.lease_type === "nill"
                  //     ? "N/A"
                  //     : "N/A"
                  //   : "N/A",
                  value: leaseSpecificDetails?.lease_type === "nill" || leaseSpecificDetails?.lease_type === null || leaseSpecificDetails?.lease_type === "null" || leaseSpecificDetails?.lease_type === undefined ? "N/A" : leaseSpecificDetails?.lease_type,
                },
                // { label: "Contract Value", value: `₹${currency(parseFloat(leaseSpecificDetails?.contract_value).toFixed(2))}/-` },
                {
                  label: "Working days",
                  value: leaseSpecificDetails?.total_working_days === "nill"  || leaseSpecificDetails?.total_working_days ===  "N/A" ||  leaseSpecificDetails?.total_working_days ===  null ? "N/A" : `${leaseSpecificDetails?.total_working_days}`,
                },
                // monthly_rental_amount
                // { label: "Transportation Charges", value: `₹${currency(leaseSpecificDetails?.transportation_charge)}` },
                {
                  label: "Nominal working hours",
                  value:  leaseSpecificDetails?.nominal_hours_per_day === "nill"  || leaseSpecificDetails?.nominal_hours_per_day ===  "N/A" ||  leaseSpecificDetails?.nominal_hours_per_day ===  null ? "N/A" : `${leaseSpecificDetails?.nominal_hours_per_day} hrs/day`,
                },
                {
                  label: "Rental amount",
                  value:  leaseSpecificDetails?.monthly_rental_amount === "nill"  || leaseSpecificDetails?.monthly_rental_amount ===  "N/A" ||  leaseSpecificDetails?.monthly_rental_amount ===  null ? "N/A" : `₹ ${leaseSpecificDetails?.monthly_rental_amount}/month`,
                },
                {
                  label: "Normal hourly rate",
                  value:  leaseSpecificDetails?.normal_rate === "nill"  || leaseSpecificDetails?.normal_rate ===  "N/A" ||  leaseSpecificDetails?.normal_rate ===  null ? "N/A" : `₹ ${parseFloat(leaseSpecificDetails?.normal_rate).toFixed(2)}/hr`,
                },
                {
                  label: "Overtime rate",
                  value:  leaseSpecificDetails?.overtime_rate === "nill"  || leaseSpecificDetails?.overtime_rate ===  "N/A" ||  leaseSpecificDetails?.overtime_rate ===  null ? "N/A" : `₹ ${parseFloat(leaseSpecificDetails?.overtime_rate).toFixed(2)}/hr`,
                },
                
              ].map(({ label, value }, idx) => (
                <tr key={idx} className="border-b">
                  <td title={label} className="py-2 font-medium text-gray-500">
                    {label}
                  </td>
                  <td title={label} className="py-2">
                    {value || "--"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Card 3 - Receivables */}
        <div className="bg-white p-4 flex flex-col gap-1 border">
          <span title="Receivables" className="text-xs font-bold mb-2">Invoicing </span>
          <table className="w-full text-xs">
            <tbody>
              {[
                { label: "Currency", value: leaseSpecificDetails?.currency === "INR" || leaseSpecificDetails?.currency === "inr" ? "INR": ''  },
                // { label: "Contract Value", value: `₹${currency(parseFloat(leaseSpecificDetails?.contract_value).toFixed(2))}/-` },
               
                // monthly_rental_amount
                // { label: "Transportation Charges", value: `₹${currency(leaseSpecificDetails?.transportation_charge)}` },
                {
                  label: "Normal amount from logsheet",
                  value: leaseSpecificDetails?.normal_amount
                    ? `₹${currency(
                        parseFloat(leaseSpecificDetails?.normal_amount).toFixed(
                          2
                        )
                      )}/-`
                    : "N/A",
                },
                {
                  label: "Overtime amount from logsheet",
                  value: leaseSpecificDetails?.overtime_amount
                    ? `₹${currency(
                        parseFloat(
                          leaseSpecificDetails?.overtime_amount
                        ).toFixed(2)
                      )}/-`
                    : "N/A",
                },
                // {
                //   label: "Reimbursements",
                //   value: leaseSpecificDetails?.reimbursements
                //     ? `₹${currency(
                //         parseFloat(
                //           leaseSpecificDetails?.reimbursements
                //         ).toFixed(2)
                //       )}/-`
                //     : "N/A",
                // },
                {
                  label: "Total amount from logsheet",
                  value: leaseSpecificDetails?.total_claimable_amount 
                    ? `₹${currency(
                        parseFloat(
                          leaseSpecificDetails?.total_claimable_amount
                        ).toFixed(2)
                      )}/-`
                    : "N/A",
                },
                {
                  label: 'Normal Billing Amount',
                  value: `₹${normalBillingAmount?.toFixed(2)}/-`,
                }, {
                  label: 'Overtime Billing Amount',
                  value: `₹${overtimeBillingAmount?.toFixed(2)}/-`,
                },
                {
                  label: 'Total Billing Amount',
                  value: `₹${totalBillingAmount?.toFixed(2)}/-`,
                },

              ].map(({ label, value }, idx) => (
                <tr key={idx} className="border-b">
                  <td title={label} className="py-2 font-medium text-gray-500">
                    {label}:
                  </td>
                  <td title={label} className="py-2">
                    {value || "--"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default LeaseDashboard;

// {/* <div className="flex flex-col  md:flex-row text-xs w-[93vw]
//    justify-between px-2  mt-2 dark:bg-slate-900 gap-2 ">
//         {/* Frame 1 */}
//         <div className="w-2/6 p-4 bg-white shadow-md">

//         {/* <h1 className="">Custormer Details</h1> */}
//         <div className="flex-1 borde mt-2  py-4 round-2xl ">
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l border-t round-t-lg h-[66px]">
//             <span className="text-gray-500">Customer PO No</span>
//             <span className="block font-semibold text-xs">
//                 {leaseSpecificDetails?.customer_po_no === "null" ? '--': leaseSpecificDetails?.customer_po_no}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l  h-[6px]">
//             <span className="text-gray-500">Customer</span>
//             <span className="block font-semibold text-xs">
//                 {leaseSpecificDetails?.customer}
//             </span>
//           </div>

//           <div className="p-2 flex justify-between flex-col border-b border-r border-l   h-[66px]">
//             <span className="text-gray-500">Status</span>
//             <span className={`block font-semibold text-xs
//                  ${leaseSpecificDetails?.lease_status === 'inactive'? 'text-gray-500':
//                     leaseSpecificDetails?.lease_status === "never assigned" ? "text-red-600"  : "text-green-500"}`}>
//             {capitalizeFirstLetter(leaseSpecificDetails?.lease_status)}
//             </span>
//           </div>
//            <div className="p-2 flex justify-between flex-col border-b border-r border-l   h-[6px]">
//             <span className="text-gray-500">Rental Start Date</span>
//             <span className="block font-semibold text-xs">
//             {formatDate(leaseSpecificDetails?.rental_start_date)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l   h-[66px]">
//             <span className="text-gray-500"> Rental End Date</span>
//             <span
//               className={`block font-semibold text-xs`}
//             >
//               {formatDate(leaseSpecificDetails?.rental_end_date)}

//             </span>
//           </div>

//           <div className="p-2 flex justify-between flex-col border-b border-r border-l  round-b-lg h-[66px]">
//             <span className="text-gray-500">Rental Extended End Date</span>
//             <span className="block font-semibold text-xs">
//               {leaseSpecificDetails?.rental_extended_date ?
//                 <>
//             {formatDate(leaseSpecificDetails?.rental_extended_date)}
//                 </>
//                 : "--"
//                 }
//             </span>
//           </div>
//         </div>
//         </div>

//         {/* Frame 2 */}
//         <div className="w-2/6 p-4 bg-white shadow-md">

//         {/* <h1 className="">Pay Term</h1> */}
//         <div className="flex-1  mt-2 py-4 round-2xl">
//         <div className="p-2 flex justify-between flex-col border   h-[66px]">
//             <span className="text-gray-500">Cuerrency</span>
//             <span className="block font-semibold text-xs">
//             {leaseSpecificDetails?.currency}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l border-t round-t-lg h-[66px]">
//             <span className="text-gray-500">Contract Value</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.contract_value)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l  h-[66px]">
//             <span className="text-gray-500">Transportation Charges</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.transportation_charge)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l   h-[66px]">
//             <span className="text-gray-500">Normal Amount</span>
//             <span className="block font-semibold text-xs">
//             ₹{(currency(leaseSpecificDetails?.normal_amount))}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l   h-[66px]">
//             <span className="text-gray-500"> Overtime Amount</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.overtime_amount)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l   h-[66px]">
//             <span className="text-gray-500">  Reimbursements</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.reimbursements)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l   h-[66px]">
//             <span className="text-gray-500">  Total Claimable Amount</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.total_claimable_amount)}
//             </span>
//           </div>
//         </div>
//         </div>

// {/* Frame 3 */}
//         <div className="w-2/6 p-4 bg-white shadow-md">

//         {/* <h1 className="">Invoice Details</h1> */}
//         <div className="flex-1 mt-2 py-4 ">
//           <div className="p-2 flex justify-between border-b border-r border-l border-t  h-8">
//             <span className="text-gray-500">Contract Value</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.contract_value)}
//             </span>
//           </div>
//           {/* <div className="p-2 flex justify-between border-b border-r border-l  h-8">
//             <span className="text-gray-500">Transportation Charges</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.transportation_charge)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between border-b border-r border-l   h-8">
//             <span className="text-gray-500">Normal Amount</span>
//             <span className="block font-semibold text-xs">
//             ₹{(currency(leaseSpecificDetails?.normal_amount))}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between border-b border-r border-l   h-8">
//             <span className="text-gray-500"> Overtime Amount</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.overtime_amount)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between border-b border-r border-l   h-8">
//             <span className="text-gray-500">  Reimbursements</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.reimbursements)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between border-b border-r border-l  round-b-lg h-8">
//             <span className="text-gray-500">  Total Claimable Amount</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.total_claimable_amount)}
//             </span>
//           </div> */}
//         </div>
//         </div>

//       </div> */}
