import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Button, FormControl, MenuItem, Select, span } from "@mui/material";
import { IoIosClose } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { capitalizeFirstLetter } from "../../Modals/AssetDetails/Tabs/FormatDate";

const NewLeaseTable = ({
  handleExport,
  filteredData,
  setFilteredData,
  leaseDetails,
  setSelectedRows,
}) => {
  const [page, setPage] = useState(0); // Current page
  const [pageSize, setPageSize] = useState(10); // Rows per page
  const [searchQuery, setSearchQuery] = useState(""); // Search query
  const [selectedFilter, setSelectedFilter] = useState("All Lease"); // Default to "All Lease"

  const selected = [
    { title: "All Lease", label: "all" },
    { title: "Active", label: ["active", "expiring"] },
    { title: "Closed", label: ["inactive"] },
    { title: "Exceeded", label: ["expired"] }, {

      title: "Booked", label: ["Booked"]

    },
  ];

  // const applyFilters = () => {
  //   let data = [...leaseDetails];

  //   // Filter by selected filter
  //   if (selectedFilter !== "All Lease") {
  //     const selectedLabels =
  //       selected.find((item) => item.title === selectedFilter)?.label || [];

  //     data = data.filter((item) => {
  //       const leaseStatus = item.lease_status.toLowerCase();

  //       // Exclude "inactive" for all filters except "Closed"
  //       if (selectedFilter !== "Closed" && leaseStatus.includes("inactive")) {
  //         return false; // Exclude inactive unless the filter is "Closed"
  //       }

  //       // Match lease statuses for the selected labels
  //       if (Array.isArray(selectedLabels)) {
  //         return selectedLabels.some(
  //           (label) => leaseStatus.includes(label.toLowerCase()) // Ensure case-insensitive match
  //         );
  //       }

  //       return true; // Default case (shouldn't occur if labels are set correctly)
  //     });
  //   }

  //   // Apply search
  //   if (searchQuery.trim()) {
  //     const lowerCaseQuery = searchQuery.toLowerCase();
  //     data = data.filter((item) =>
  //       Object.values(item).some(
  //         (value) =>
  //           value && value.toString().toLowerCase().includes(lowerCaseQuery)
  //       )
  //     );
  //   }

  //   setFilteredData(data);
  //   setPage(0); // Reset to the first page
  // };

  const applyFilters = () => {
    let data = [...leaseDetails];

    // Loop through each lease and check the rental_start_date
    data.forEach((item) => {
      const rentalStartDate = new Date(item.rental_start_date); // Convert to Date object
      const currentDate = new Date(); // Get today's date

      // If the rental start date is in the future, set lease status to "Booked"
      if (rentalStartDate > currentDate) {
        item.lease_status = "Booked"; // Set lease status to "Booked"
      }
    });

    // Filter by selected filter
    if (selectedFilter !== "All Lease") {
      const selectedLabels =
        selected.find((item) => item.title === selectedFilter)?.label || [];

      data = data.filter((item) => {
        const leaseStatus = item.lease_status.toLowerCase();

        // Exclude "inactive" for all filters except "Closed"
        if (selectedFilter !== "Closed" && leaseStatus.includes("inactive")) {
          return false; // Exclude inactive unless the filter is "Closed"
        }

        // Match lease statuses for the selected labels
        if (Array.isArray(selectedLabels)) {
          return selectedLabels.some(
            (label) => leaseStatus.includes(label.toLowerCase()) // Ensure case-insensitive match
          );
        }

        return true; // Default case (shouldn't occur if labels are set correctly)
      });
    }

    // Apply search
    if (searchQuery.trim()) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      data = data.filter((item) =>
        Object.values(item).some(
          (value) =>
            value && value.toString().toLowerCase().includes(lowerCaseQuery)
        )
      );
    }

    setFilteredData(data);
    setPage(0); // Reset to the first page
  };


  useEffect(() => {
    applyFilters(); // Initial filter and search application
  }, [leaseDetails, selectedFilter, searchQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let value = event.target.value
    setPageSize(value === 'All data' ? filteredData.length : value);
    setPage(0); // Reset to the first page
  };

  const navigate = useNavigate();

  // Import span component
  const formatDate = (dateString) => {
    //  console.log(dateString)
    if (!dateString) return "N/A"; // Handle empty or null values
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const columns = [
    {
      field: "counter",
      headerName: "#",
      width: 80,
      renderHeader: () => (
        <span title="Row Number" arrow placement="top">
          <span>#</span>
        </span>
      ),
      renderCell: (params) => <>{params.row.counter}</>, // Use the counter field
    },
    {
      field: "asset_no",
      headerName: "Asset No",
      width: 120,
      renderHeader: () => (
        <span title="Unique identifier for the asset" arrow placement="top">
          <span>Asset No</span>
        </span>
      ),
    },
    {
      field: "odoo_order_id",
      headerName: "RSO NO",
      width: 150,
      renderHeader: () => (
        <span title="Unique RSO No. Generated by Odoo" arrow placement="top">
          <span>RSO NO</span>
        </span>
      ),
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      width: 150,
      renderHeader: () => (
        <span title="Name of the customer" arrow placement="top">
          <span>Customer Name</span>
        </span>
      ),
    },
    {
      field: "gst_no",
      headerName: "GST No",
      width: 150,
      renderHeader: () => (
        <span title="GST number of the customer" arrow placement="top">
          <span>GST No</span>
        </span>
      ),
    },
    {
      field: "total_claimable_amount",
      headerName: "Total Amount",
      width: 180,
      renderHeader: () => (
        <span
          title="Total claimable amount for the asset"
          arrow
          placement="top"
        >
          <span>Total Amount</span>
        </span>
      ),
      renderCell: (params) => {
        let amount = params.value;
        return (
          <>
            {amount === null || amount === 0 || amount === "0" || amount === ""
              ? "N/A"
              : `₹${parseFloat(amount).toFixed(2)}/-`}
          </>
        );
      },
    },
    {
      field: "rental_start_date",
      headerName: "Rental Start Date",
      width: 150,
      renderHeader: () => (
        <span title="Start date of the rental period" arrow placement="top">
          <span>Rental Start Date</span>
        </span>
      ),

      renderCell: (params) => {
        let date = params.value;

        return <>{formatDate(date)}</>;
      },
    },
    {
      field: "rental_end_date",
      headerName: "Rental End Date",
      width: 150,
      renderHeader: () => (
        <span title="End date of the rental period" arrow placement="top">
          <span>Rental End Date</span>
        </span>
      ),
      renderCell: (params) => {
        let date = params.value;

        return <>{formatDate(date)}</>;
      },
    },
    {
      field: "lease_status",
      headerName: "Lease Status",
      width: 150,
      renderHeader: () => (
        <span title="Current status of the lease" arrow placement="top">
          <span>Lease Status</span>
        </span>
      ),
      renderCell: (params) => {
        let status = params.value;
        const color =
          status === "inactive"
            ? "gray"
            : status === "expired"
              ? "red"
              : status === "Booked" ? 'blue' : "green";

        let activeStatus = "Active";
        if (status.toLowerCase().includes("expiring")) {
          const daysMatch = status.match(/(\d+)\s+days/);
          if (daysMatch && daysMatch[1]) {
            const daysLeft = daysMatch[1]; // Extracted number of days
            activeStatus = `Active (${daysLeft} days left)`;
          }
        }

        return (
          <button
            className="text-xs"
            style={{
              color: color,
              fontWeight: "bold",
              padding: "4px 8px",
            }}
          >
            {status === "inactive"
              ? "Closed"
              : status.toLowerCase().includes("expiring")
                ? activeStatus
                : status === "expired" ? "Exceeded" : capitalizeFirstLetter(status)}
          </button>
        );
      },
    },
    // {
    //   field: "details",
    //   headerName: "See More",
    //   width: 120,
    //   renderHeader: () => (
    //     <span title="View more details of the lease" arrow placement="top">
    //       <span>See More</span>
    //     </span>
    //   ),
    //   renderCell: (params) => (
    //     <Button
    //       onClick={() => navigate(`/lease/leaseDetails/${params.row.id}`)}
    //       variant="contained"
    //       sx={{
    //         height: "32px",
    //         width: "96px",
    //         fontSize: 12,
    //         textTransform: "capitalize",
    //         "&:hover": {
    //           backgroundColor: "#004ba0", // Hover effect color
    //         },
    //       }}
    //     >
    //       See Details
    //     </Button>
    //   ),
    // },
  ];

  return (
    <div className="fixed right-3 left-[5%] bg-white">
      <Paper
        style={{
          padding: 8,
          height: "65vh",
          width: "100%",
          border: "hidden",
        }}
        elevation={0}
      >
        <div className="flex justify-between items-center my-2  ">
          {/* Search and Filter */}
          <div className="flex gap-4 items-center">
            {/* Search Input */}
            <div className="relative">
              <span
                placement="top"
                arrow
                title="Search Rso No, Asset No, Company, etc"
              >
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={(e) => e.key === "Enter" && applyFilters()}
                  placeholder="Search leases"

                  className="border-light-5 border bg-gray-50 h-[30px] w-[200px] p-[5px] rounded-[4px] text-[12px] items-center"
                />
              </span>
            </div>

            {/* Filter Dropdown */}
            <div>
              <FormControl
                fullWidth
                sx={{
                  "& .MuiInputLabel-root": { fontSize: "12px" },
                  "& .MuiSelect-select": { fontSize: "12px" },
                  "& .MuiMenuItem-root": { fontSize: "12px" },
                }}
              >
                <span title="Filter Lease Status" placement="top" arrow>
                  <select
                    value={selectedFilter}
                    onChange={(e) => setSelectedFilter(e.target.value)}
                    className="block w-[200px] p-[6.5px] mt-[0.2px] text-xs px-3 border border-light-5 rounded-[4px] text-dark-6 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    {selected.map((option) => (
                      <option key={option.title} value={option.title}>
                        {option.title}
                      </option>
                    ))}
                  </select>
                </span>
              </FormControl>
            </div>
          </div>

          <div className="flex gap-2 items-center">
            {/* <button
              className={`shadow-md bg-white  px-1.5 h-6 flex items-center rounded-full text-[10px]  ${
                selectedFilter === "All Lease" && "hidden"
              }`}
            >
              {selectedFilter}
              <IoIosClose
                className="h-4 w-4"
                onClick={() => setSelectedFilter("All Lease")}
              />
            </button> */}

            <button
              className={`shadow-md bg-white border rounded-md p-1.5  ${selectedFilter === "All Lease" && "hidden"
                } flex items-center text-[12px] text-black`}
            >
              {/* State indicator and label */}
              <span className={`flex items-center mr-2`}>
                <span
                  className={`h-2 w-2 rounded-full mr-1
                              ${selectedFilter === "Closed"
                      ? "bg-light-2"
                      : selectedFilter === "Exceeded"
                        ? "bg-red-1"
                        : selectedFilter === "Sold Items"
                          ? "bg-red-1"
                          : selectedFilter === "Booked" ? "bg-blue-2" : "bg-green-500"
                    }
                               `}
                ></span>{" "}
                {/* Green dot */}
                {selectedFilter}
              </span>

              {/* Close icon */}
              <IoIosClose
                className="h-4 w-4 cursor-pointer"
                onClick={() => {
                  setSelectedFilter("All Lease");
                  window.location.reload();
                }}
              />
            </button>
            <span title="Add pagination" placement="top" arrow>
              <TablePagination
                component="div"
                count={filteredData.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 30, 50, 'All data']}
                labelRowsPerPage="Leases:"
                SelectProps={{
                  renderValue: (value) => (value === 'All data' ? 'All data' : value), // Display "All data" if selected
                }}
              />
            </span>
            <NewAssetBtn tabName="lease" />
            <span title="Export Data in Excel format" placement="top" arrow>
              <button
                variant="outlined"
                sx={{
                  fontSize: 10,
                  color: "#000",
                  width: 104,
                  fontWeight: 'bold'
                }}
                onClick={handleExport}
                className="buttons border "
              >
                Export Excel
              </button>
            </span>
          </div>
        </div>

        {filteredData.length > 0 ? (
          <DataGrid
            rows={
              pageSize === filteredData.length
                ? filteredData.map((row, index) => ({
                  ...row,
                  counter: index + 1, // Add row number for all rows
                }))
                : filteredData
                  .slice(page * pageSize, page * pageSize + pageSize) // Paginated rows
                  .map((row, index) => ({
                    ...row,
                    counter: page * pageSize + index + 1, // Adjust counter for paginated rows
                  }))
            }
            columns={columns}
            // checkboxSelection
            paginationMode="server"
            pageSize={pageSize}
            pagination={false} // Disable default pagination
            onRowClick={(params) => {
              const rowId = params.row.id; // Use the row ID or another property to navigate
              navigate(`/lease/lease-details/${rowId}`); // Adjust the route as needed
            }}
            sx={{
              "& .MuiDataGrid-footerContainer": {
                display: "none", // Hides the footer container
              },
              "& .MuiDataGrid-root": { fontSize: "12px", height: 30 },
              "& .MuiDataGrid-columnHeaders": {
                fontSize: "14px",
                backgroundColor: "#000",
                fontWeight: "bold",
                color: "#006DB6",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
                height: 20,
              },
              "& .MuiDataGrid-cell": { fontSize: "12px", cursor: "pointer" },
              "& .MuiDataGrid-virtualScroller": {
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
          // onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          />
        ) : (
          <>
            <div className="text-xs items-center flex justify-center p-10 border">
              No Lease Found in your search area
            </div>
          </>
        )}
      </Paper>
    </div>
  );
};

export default NewLeaseTable;
