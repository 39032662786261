import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
import { toast } from "react-toastify";

const Wallet = ({ operatorDetail, setOperatorDetail }) => {
  const [walletName, setWalletName] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [issueDate, setIssueDate] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  // console.log(operatorDetail.id)

  const [presentWallet, setPresentWallet] = useState([]);

  const getWallet = async () => {
    try {
      const res = await fetch(
        `${setBaseUrl}/operator/get-operator-wallet-options`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );

      const data = await res.json();
      if (res.ok) {
        setPresentWallet(data.msg); // Assuming API response structure
      } else {
        console.error("Error getting wallet", data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getWallet();
  }, []);

  const handleAddWallet = async (e) => {
    e.preventDefault();

    const newWallet = {
      items_name: walletName,
      quantity: parseInt(quantity, 10),
      issue_date: issueDate,
      operator_id: operatorDetail.id,
    };

    // Update UI first
    // setOperatorDetail((prevData) => ({
    //     ...prevData,
    //     wallet_arr: [...prevData.wallet_arr, newWallet],
    // }));

    setWalletName("");
    setQuantity(0);
    setIssueDate("");
    setShowDropdown(false);

    // Prepare data for submission
    const payload = { data_arr: [newWallet] };

    try {
      const res = await fetch(
        `${setBaseUrl}/operator/entry-operator-wallet-list`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await res.json();

      if (res.ok) {
        // console.log("Wallet added successfully:", data);
        toast.success("Wallet added successfully!");
        // getWallet(); // Refresh the wallet list after successful addition
      } else {
        console.error("Error adding wallet:", data);
        toast.error(data);
      }
    } catch (error) {
      console.error("Request failed:", error);
    }
  };

  const handleWalletChange = (e) => {
    setWalletName(e.target.value);
    setShowDropdown(true);
  };

  const handleSelectWallet = (name) => {
    setWalletName(name);
    setShowDropdown(false);
  };


  return (
    <div className="border p-2 rounded-[8px] flex flex-col space-y-4 mt-6">
      <h2 className="text-xs font-bold">Wallet</h2>

      <label>Item Name</label>
      {/* <div className="relative">
        <input
          type="text"
          placeholder="Search or Add Name"
          value={ walletName}
          onChange={handleWalletChange}
          className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
        />
        {showDropdown && walletName && (
          <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg">
            {presentWallet
              .filter((wallet) =>
                wallet.toLowerCase().includes(walletName.toLowerCase())
              )
              .map((wallet, index) => (
                <li
                  key={index}
                  onClick={() => handleSelectWallet(wallet)}
                  className="p-2 cursor-pointer hover:bg-gray-200"
                >
                  {wallet}
                </li>
              ))}
          </ul>
        )}
      </div> */}
      <div className="relative">
        <input
          type="text"
          placeholder="Search or Add Name"
          value={  walletName}
          onChange={handleWalletChange}
          className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none pr-8"
        />
        {walletName && (
          <button
            onClick={() => setWalletName("")}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-red-500"
          >
            ✕
          </button>
        )}
        {showDropdown && walletName && (
          <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg">
            {presentWallet
              .filter((wallet) =>
                wallet.toLowerCase().includes(walletName.toLowerCase())
              )
              .map((wallet, index) => (
                <li
                  key={index}
                  onClick={() => handleSelectWallet(wallet)}
                  className="p-2 cursor-pointer hover:bg-gray-200"
                >
                  {wallet}
                </li>
              ))}
          </ul>
        )}
      </div>

      <label>Quantity of Item</label>
      <input
        min={0}
        type="number"
        value={quantity}
        onChange={(e) => setQuantity(e.target.value)}
        className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
      />

      <label>Issue Date</label>
      <input
        type="date"
        value={issueDate}
        onChange={(e) => setIssueDate(e.target.value)}
        className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
      />

      <button
        onClick={handleAddWallet}
        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
      >
        Add Wallet
      </button>
    </div>
  );
};

export default Wallet;
