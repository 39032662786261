import React from "react";
import { Card, CardContent, Typography, Chip } from "@mui/material";
import moment from "moment";

const SeeBookings = ({ assetNo, bookingDetails }) => {
  return (
    <div className="p-4">
      <Typography variant="h5 " sx={{ fontSize: "14px"}} className="mb-4 font-bold text-blue-600">
        Bookings for Asset: {assetNo}
      </Typography>

      {bookingDetails?.length === 0 ? (
        <Typography  variant="body1" className="text-gray-500">
          No Bookings Found
        </Typography>
      ) : (
        <div className="grid gap-4">
          {bookingDetails?.map((booking, index) => (
            <Card key={index} sx={{ fontSize: "12px"}} className="shadow-md border">
              <CardContent className="flex flex-col gap-2">
              <Typography   sx={{ fontSize: "12px"}} variant="body1">
                  <strong>Sale Person:</strong> {booking.sale_person_name}
                </Typography>  
              <Typography   sx={{ fontSize: "12px"}} variant="body1">
                  <strong>Customer Name:</strong> {booking.booking_customer_name}
                </Typography>
                <Typography   sx={{ fontSize: "12px"}} variant="body1">
                  <strong>Booking Date:</strong> {moment(booking.booking_need_from).format("DD-MM-YYYY") || "N/A"}
                </Typography>
                <Typography   sx={{ fontSize: "12px"}} variant="body1">
                  <strong>Location:</strong> {booking.booking_location || "No locations selected"}
                </Typography>
                <Typography   sx={{ fontSize: "12px"}} variant="body1">
                  <strong>Booking Rate:</strong> ₹{booking.booking_estimated_rate_per_day || "N/A"}/-
                </Typography>
                <Typography   sx={{ fontSize: "12px"}} variant="body1">
                  <strong>Comments:</strong> {booking.booking_follow_up_comments || "No Comments"}
                </Typography>
                <div className="flex items-center gap-2">
                  <Typography  sx={{ fontSize: "12px"}}  variant="body1">
                    <strong>Approval:</strong>
                  </Typography>
                  <Chip
                    label={!booking.booking_is_approved === true ? "Not Allotted" : "Allotted"}
                    color={!booking.booking_is_approved === true ? "warning" : "success"}
                  />
                </div>

              

              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default SeeBookings;
