// import React from 'react'

// const SalesForgetPassword = () => {
//   return (
//     <div>SalesForgetPassword</div>
//   )
// }

// export default SalesForgetPassword

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import backgVideo from "../../assets/videos/Access-banner.mp4";
import DurbinLogo from "../../assets/images/DurbinLogo.jpg";
import { setBaseUrl } from "../../config";
import { toast } from "react-toastify";
const SalesForgetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
  
    email: "",
   
  });

  const handleSubmit = async () => {
  console.log(form)

    setLoading(true); // Start Loading
    try {
      const response = await fetch(`${setBaseUrl}/sale-service/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      toast.success("Password has been sent successfully! Please check your email.");
      navigate("/sales-services/login");
    } catch (error) {
      console.error(error);
      toast.error("Failed authentication! Please try again.");
    }
    setLoading(false); // Stop Loading
  };


  return (
    <div className="flex justify-center items-center h-screen bg-gray-900">
      {/* Background Video */}
      <div className="relative w-full h-full">
        <video
          className="w-full h-full object-cover"
          src={backgVideo}
          autoPlay
          loop
          muted
        />
        
        {/* Overlay */}
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <form className="bg-white p-6 w-[340px] rounded-lg shadow-lg">
            {/* Logo */}
            <div className="flex flex-col items-center">
              <img
                src={DurbinLogo}
                alt="Durbin Logo"
                className="w-20 h-20 rounded-full mb-2"
              />
              <h1 className="text-lg font-bold text-gray-700">Asset Tracker</h1>
              <p className="text-sm font-semibold text-blue-500">
                Forgot Password
              </p>
            </div>

       

            {/* Email */}
            <div className="mt-4">
              <label htmlFor="email" className="block text-gray-600 text-sm">
                Email Address
              </label>
              <input
              title="Enter your email address"
                type="email"
                id="email"
                onChange={(e) => setForm({ ...form, email: e.target.value })}
                placeholder="Enter your email"
                className="w-full px-4 py-2 mt-1 text-sm border rounded-lg focus:ring-2 focus:ring-blue-400 outline-none"
              />
            </div>

           

            {/* Register Button */}
            <button
              
              onClick={handleSubmit}
              title="click to get you password"
              className="w-full py-3 mt-6 text-white font-bold bg-blue-500 rounded-lg hover:bg-blue-600 transition-all duration-300"
            >
             Forgot Password
            </button>

            {/* Already Have an Account */}
            <p className="text-xs text-center mt-4">
            Back to login?{" "}
              <span
                className="text-blue-500 font-semibold hover:underline cursor-pointer"
                onClick={() => navigate("/sales-services/login/")}
              >
                Login
              </span>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SalesForgetPassword;
