import React, { useState } from "react";
import { setBaseUrl } from "../../../config";


const SalaryDetails = ({ operatorId }) => {
  const [salaryData, setSalaryData] = useState({
    allowances: "",
    basic_pay: "",
    ctc: "",
    employee_esi: "",
    employee_pf: "",
    employer_esi: "",
    employer_pf: "",
    gross: "",
    gst: "",
    net_payment: "",
    remittable_3p: "",
    service_charge: "",
    sub_total: "",
    takehome: "",
    tds: "",
    days_ratio: 1,
    attend_days: 31,
    actual_days: 31,
    overtime_amount: 0,
    month_year: "2024-06",
    advanced_money_detuced: 0,
    operator_id: operatorId, // Passed as a prop
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSalaryData({ ...salaryData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch(`${setBaseUrl}/operator/entry-operator-salary`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify(salaryData),
      });

      const data = await res.json();
      if (res.ok) {
        alert("Salary entry successful!");
      } else {
        console.error("Error:", data);
      }
    } catch (error) {
      console.error("Request failed:", error);
    }
  };

  return (
    <div className="p-6 bg-white rounded-md shadow-md">
      <h2 className="text-xl font-bold mb-4">Generate Salary</h2>
      <form onSubmit={handleSubmit} className="grid grid-cols-3 gap-4">

        {/* Individual Hardcoded Input Fields */}
        <div>
          <label className="font-medium">Basic Pay</label>
          <input type="number" name="basic_pay" value={salaryData.basic_pay} onChange={handleChange} className="border p-2 rounded w-full" required />
        </div>

        <div>
          <label className="font-medium">Allowances</label>
          <input type="number" name="allowances" value={salaryData.allowances} onChange={handleChange} className="border p-2 rounded w-full" required />
        </div>

        <div>
          <label className="font-medium">CTC</label>
          <input type="number" name="ctc" value={salaryData.ctc} onChange={handleChange} className="border p-2 rounded w-full" required />
        </div>

        <div>
          <label className="font-medium">Employee PF</label>
          <input type="number" name="employee_pf" value={salaryData.employee_pf} onChange={handleChange} className="border p-2 rounded w-full" required />
        </div>

        <div>
          <label className="font-medium">Employee ESI</label>
          <input type="number" name="employee_esi" value={salaryData.employee_esi} onChange={handleChange} className="border p-2 rounded w-full" required />
        </div>

        <div>
          <label className="font-medium">Employer PF</label>
          <input type="number" name="employer_pf" value={salaryData.employer_pf} onChange={handleChange} className="border p-2 rounded w-full" required />
        </div>

        <div>
          <label className="font-medium">Employer ESI</label>
          <input type="number" name="employer_esi" value={salaryData.employer_esi} onChange={handleChange} className="border p-2 rounded w-full" required />
        </div>

        <div>
          <label className="font-medium">Gross</label>
          <input type="number" name="gross" value={salaryData.gross} onChange={handleChange} className="border p-2 rounded w-full" required />
        </div>

        <div>
          <label className="font-medium">GST</label>
          <input type="number" name="gst" value={salaryData.gst} onChange={handleChange} className="border p-2 rounded w-full" required />
        </div>

        <div>
          <label className="font-medium">Net Payment</label>
          <input type="number" name="net_payment" value={salaryData.net_payment} onChange={handleChange} className="border p-2 rounded w-full" required />
        </div>

        <div>
          <label className="font-medium">Remittable 3P</label>
          <input type="number" name="remittable_3p" value={salaryData.remittable_3p} onChange={handleChange} className="border p-2 rounded w-full" required />
        </div>

        <div>
          <label className="font-medium">Service Charge</label>
          <input type="number" name="service_charge" value={salaryData.service_charge} onChange={handleChange} className="border p-2 rounded w-full" required />
        </div>

        <div>
          <label className="font-medium">Subtotal</label>
          <input type="number" name="sub_total" value={salaryData.sub_total} onChange={handleChange} className="border p-2 rounded w-full" required />
        </div>

        <div>
          <label className="font-medium">Take Home</label>
          <input type="number" name="takehome" value={salaryData.takehome} onChange={handleChange} className="border p-2 rounded w-full" required />
        </div>

        <div>
          <label className="font-medium">TDS</label>
          <input type="number" name="tds" value={salaryData.tds} onChange={handleChange} className="border p-2 rounded w-full" required />
        </div>

        {/* Read-Only Fields */}
        <div>
          <label className="font-medium">Days Ratio</label>
          <input type="number" name="days_ratio" value={salaryData.days_ratio} className="border p-2 rounded w-full bg-gray-100" readOnly />
        </div>

        <div>
          <label className="font-medium">Attend Days</label>
          <input type="number" name="attend_days" value={salaryData.attend_days} className="border p-2 rounded w-full bg-gray-100" readOnly />
        </div>

        <div>
          <label className="font-medium">Actual Days</label>
          <input type="number" name="actual_days" value={salaryData.actual_days} className="border p-2 rounded w-full bg-gray-100" readOnly />
        </div>

        <div>
          <label className="font-medium">Overtime Amount</label>
          <input type="number" name="overtime_amount" value={salaryData.overtime_amount} className="border p-2 rounded w-full bg-gray-100" readOnly />
        </div>

        <div>
          <label className="font-medium">Month & Year</label>
          <input type="text" name="month_year" value={salaryData.month_year} className="border p-2 rounded w-full bg-gray-100" readOnly />
        </div>

        <div>
          <label className="font-medium">Advanced Money Deducted</label>
          <input type="number" name="advanced_money_detuced" value={salaryData.advanced_money_detuced} className="border p-2 rounded w-full bg-gray-100" readOnly />
        </div>

        {/* Submit Button */}
        <div className="col-span-2">
          <button type="submit" className="bg-blue-500 buttons text-white p-2 rounded hover:bg-blue-700 w-full">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default SalaryDetails;
