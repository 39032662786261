// import React, { useEffect, useState } from "react";
// import { setBaseUrl } from "../../../config";
// import moment from "moment";
// import * as XLSX from "xlsx";
// import { toast } from "react-toastify";

// const LeaseWithDevice = ({
//   rso_id,
//   asset_no,
//   leaseId,
//   deviceId,
//   nominalHours,
//   operators,
// }) => {
//   const [timeData, setTimeData] = useState([]);
//   const [formData, setFormData] = useState({});

//   const [uploadData, setUploadData] = useState({});

//   const initialFields = {
//     x_studio_date: "",
//     x_studio_day_type: "WD",
//     x_studio_time_in: "",
//     x_studio_time_out: "",
//     x_studio_breakdown_time: "",
//     x_studio_overtime: "",
//     x_studio_operator1: "",
//     x_studio_reimbursements: "",
//   };

//   // Fetch data
//   const getTimeSheetdata = async () => {
//     try {
//       const res = await fetch(
//         `${setBaseUrl}/lease/get-lease-timeline-by-device`,
//         {
//           headers: {
//             "x-access-tokens": sessionStorage.getItem("token"),
//             "rent-odoo-id": rso_id,
//           },
//         }
//       );

//       if (!res.ok) throw new Error("Failed to fetch data");
//       const data = await res.json();
//       setTimeData(data);

//       const initialFormData = {};
//       Object.entries(data).forEach(([month, entries]) => {
//         entries.forEach((entry) => {
//           initialFormData[entry.id] = {
//             dayType: "wd", // Default day type
//             reimbursement: entry.reimbursement || "",
//             // overtimeAmount: calculateOvertime(
//             //   entry.total_time,
//             //   nominalHours,
//             //   "wd"
//             // ),
//           };
//         });
//       });
//       setFormData(initialFormData);
//     } catch (error) {
//       console.error("Error fetching timesheet data:", error);
//     }
//   };

//   useEffect(() => {
//     getTimeSheetdata();
//   }, []);

//   const formatTime = (time) => {
//     const [hours, minutes] = time.split(":");
//     return `${hours}h ${minutes}m`;
//   };
//   const calculateOvertime = (totalTime, nominalHours, dayType) => {
//     // Parse totalTime into total minutes
//     let totalMinutes = 0;

//     if (totalTime?.includes("day")) {
//       // Handle "1 day, 1:31:56" format
//       const [daysPart, timePart] = totalTime.split(", ");
//       const days = parseInt(daysPart?.split(" ")[0], 10) || 0; // Extract days safely
//       const [hours = 0, minutes = 0, seconds = 0] =
//         timePart?.split(":").map(Number) || []; // Extract time components safely
//       totalMinutes = days * 24 * 60 + hours * 60 + minutes + seconds / 60; // Convert to total minutes
//     } else if (totalTime?.includes(":")) {
//       // Handle "12:33:06" format
//       const [hours = 0, minutes = 0, seconds = 0] =
//         totalTime?.split(":").map(Number) || [];
//       totalMinutes = hours * 60 + minutes + seconds / 60; // Convert to total minutes
//     } else {
//       // Handle unexpected or empty formats
//       console.warn("Unexpected totalTime format:", totalTime);
//       totalMinutes = 0; // Default to 0 minutes if the format is invalid
//     }

//     // Calculate overtime
//     let overtime = 0;
//     if (dayType === "wd") {
//       overtime = Math.max(0, totalMinutes - nominalHours); // Nominal hours should also be in minutes
//     } else if (dayType === "hd") {
//       overtime = totalMinutes; // All time counts as overtime on holidays
//     }

//     // Return overtime in hours, formatted to two decimal places
//     return (overtime / 60).toFixed(2); // Convert minutes to hours
//   };

//   // const handleDayTypeChange = (id, value, totalTime) => {
//   //   setFormData((prevFormData) => ({
//   //     ...prevFormData,
//   //     [id]: {
//   //       ...prevFormData[id],
//   //       dayType: value, // Update day type
//   //       overtimeAmount: calculateOvertime(totalTime, nominalHours, value), // Recalculate overtime
//   //     },
//   //   }));
//   // };

//   const handleDayTypeChange = (index, dayType, totalTime, no) => {
//     console.log("Index:", index, "Day Type:", dayType, "Total Time:", totalTime);
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [index]: {
//         ...prevFormData[index],
//         dayType,
//         overtimeAmount: calculateOvertime(totalTime, nominalHours, dayType),
//       },
//     }));
//   };

//   // Handle changes for dropdowns and inputs
//   const handleFieldChange = (id, field, value) => {
//     setFormData((prevFormData) => {
//       const updatedData = { ...prevFormData[id], [field]: value };

//       // Recalculate overtimeAmount if dayType changes
//       if (field === "dayType") {
//         updatedData.overtimeAmount = calculateOvertime(
//           timeData[id]?.total_time,
//           nominalHours,
//           value
//         );
//       }

//       return { ...prevFormData, [id]: updatedData };
//     });
//   };

//   const handleSubmit = async (month) => {
//     const dataMap = new Map(); // To store unique entries based on device_working_time_id

//     for (let i = 0; i < timeData[month].length; i++) {
//       const entry = timeData[month][i];
//       const workingTimeId = entry.device_working_time_id;

//       // Create the update object
//       const update = {
//         x_studio_rental_register_no:rso_id,
//         x_name: asset_no,
//         x_studio_date: moment(entry.start_time).format("YYYY-MM-DD"),
//         x_studio_day_type: formData[entry.id]?.dayType || "wd",
//         x_studio_time_in:
//           entry.start_time === null
//             ? "N/A"
//             : moment(entry.start_time).format("HH:mm"),
//         x_studio_time_out:
//           entry.end_time === null
//             ? "N/A"
//             : moment(entry.end_time).format("HH:mm"),
//         x_studio_breakdown_time: entry.breakdown_time || "N/A",
//         x_studio_overtime: entry.total_time
//           ? calculateOvertime(
//               entry.total_time,
//               nominalHours,
//               formData[entry.id]?.dayType || "wd"
//             )
//           : "N/A",
//         x_studio_operator1: "Operator", // Update with operator logic
//         x_studio_reimbursements: formData[entry.id]?.reimbursement || "",
//         device_working_time_id: workingTimeId,
//       };

//       // Check if the workingTimeId exists in the map
//       if (dataMap.has(workingTimeId)) {
//         // Update the existing entry with the new values
//         const existingEntry = dataMap.get(workingTimeId);
//         dataMap.set(workingTimeId, { ...existingEntry, ...update });
//       } else {
//         // Add the new entry to the map
//         dataMap.set(workingTimeId, update);
//       }
//     }

//     // Convert the map back to an array
//     const uniqueDataArray = Array.from(dataMap.values());

//     // Store the final array in submittedData
//     // const submittedData = {
//     //   uniqueDataArray,
//     // };

//     // console.log(submittedData);

//     try {
//         const response = await fetch(`${setBaseUrl}/lease/entry-timesheet-data`, {
//           method: "POST",
//           body: JSON.stringify({
//             input_arr: uniqueDataArray,
//             action: "keep",
//           }),
//           headers: {
//             "Content-Type": "application/json",
//             "x-access-tokens": sessionStorage.getItem("token"),
//           },
//         });

//         if (response.ok) {
//           toast.success("Data updated successfully!");
//         } else {
//           throw new Error("Failed to update data");
//         }
//       } catch (error) {
//         console.error(error);
//         toast.error("Data not updated successfully. Please check odoo logs and try again!!");
//       }
//   };

//   const exportToExcel = (monthData, month) => {
//     const ws = XLSX.utils.json_to_sheet(monthData);
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, `Lease Data for ${month}`);
//     XLSX.writeFile(wb, `Lease_Timesheet_${month}.xlsx`);
//   };

//   const getMonthData = (entries) => {
//     return entries.map((entry) => ({
//       Date: moment(entry.start_time).format("DD-MM-YYYY"),
//       "Day Type": formData[entry.id]?.dayType || "wd",
//       "Start Time": moment(entry.start_time).format("HH:mm"),
//       "End Time": moment(entry.end_time).format("HH:mm"),
//       "Total Time": formatTime(entry.total_time.split(".")[0]),
//       "Breakdown Time": entry.breakdown_time || "N/A",
//       Overtime: calculateOvertime(
//         entry.total_time,
//         nominalHours,
//         formData[entry.id]?.dayType || "wd"
//       ),
//       "Overtime Amount": formData[entry.id]?.overtimeAmount || 0,
//       Reimbursement: formData[entry.id]?.reimbursement || "",
//       "Operator Name": "Operator", // Update with operator logic
//     }));
//   };

//   return (
//     <div className="p-6 bg-gray-50 font-sans text-xs">
//       <h3 className="text-lg font-semibold mb-4 text-gray-800">
//         Lease Timesheet Details
//       </h3>
//       {Object.keys(timeData).length === 0 ? (
//         <p>Loading data...</p>
//       ) : (
//         Object.entries(timeData).map(([month, entries]) => (
//           <div key={month} className="mb-6">
//             <h4 className="text-md font-bold mb-2 text-gray-700 flex justify-between">
//               {month}
//               <button
//                 onClick={() => handleSubmit(month)}
//                 className="bg-green-500 text-white px-3 py-1 text-xs rounded hover:bg-green-600"
//               >
//                 Submit for {month}
//               </button>
//               <button
//                 onClick={() => exportToExcel(getMonthData(entries), month)}
//                 className="bg-blue-500 text-white px-3 py-1 text-xs rounded hover:bg-blue-600"
//               >
//                 Export to Excel
//               </button>
//             </h4>
//             <table className="table-auto w-full text-xs border-collapse border border-gray-300 shadow-md">
//               <thead className="bg-gray-200">
//                 <tr>
//                   <th className="border border-gray-300 px-4 py-2">Date</th>
//                   <th className="border border-gray-300 px-4 py-2">Day Type</th>
//                   <th className="border border-gray-300 px-4 py-2">
//                     Start Time
//                   </th>
//                   <th className="border border-gray-300 px-4 py-2">End Time</th>
//                   <th className="border border-gray-300 px-4 py-2">
//                     Total Time
//                   </th>
//                   <th className="border border-gray-300 px-4 py-2">
//                     Breakdown Time
//                   </th>
//                   <th className="border border-gray-300 px-4 py-2">Overtime</th>
//                   <th className="border border-gray-300 px-4 py-2">
//                     Overtime Amount
//                   </th>
//                   <th className="border border-gray-300 px-4 py-2">
//                     Reimbursement
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {entries
//                   .slice() // Create a copy of the entries array to avoid mutating the original data
//                   .sort(
//                     (a, b) => new Date(a.start_time) - new Date(b.start_time)
//                   )
//                   .map((entry, index) => (
//                     <tr
//                       key={index}
// className={`border-t ${
//   entry?.is_resloved ? "bg-white" : "bg-red-100"
// }`}
//                     >
//                       <td className="border border-gray-300 px-4 py-2">
// {entry?.start_time
//   ? moment(entry.start_time).format("DD-MM-YYYY")
//   : "N/A"}
//                       </td>

//                       <td className="border border-gray-300 px-4 py-2">
//                         <select
//                           value={formData[index]?.dayType || "wd"}
//                           onChange={(e) =>
//                             handleDayTypeChange(
//                               index,
//                               e.target.value,
//                               entry.totalTime
//                             )
//                           }
//                           className="text-xs bg-gray-100 border rounded px-2 py-1"
//                         >
//                           <option value="wd">WD</option>
//                           <option value="hd">HD</option>
//                         </select>
//                       </td>

//                       <td className="border border-gray-300 px-4 py-2">
//                         {entry.start_time === null
//                           ? "N/A"
//                           : moment(entry.start_time).format("HH:mm")}
//                       </td>
//                       <td className="border border-gray-300 px-4 py-2">
//                         {entry.end_time === null
//                           ? "N/A"
//                           : moment(entry.end_time).format("HH:mm")}
//                       </td>
//                       <td className="border border-gray-300 px-4 py-2">
//                         {entry?.total_time === null
//                           ? "N/A"
//                           : formatTime(entry.total_time.split(".")[0])}
//                       </td>
//                       <td className="border border-gray-300 px-4 py-2">
//                         {entry.breakdown_time || "N/A"}
//                       </td>
//                       <td className="border border-gray-300 px-4 py-2">
//                         {entry.total_time
//                           ? calculateOvertime(
//                               entry.total_time,
//                               nominalHours,
//                               formData[entry.id]?.x_studio_day_type || "wd"
//                             )
//                           : "N/A"}
//                       </td>
//                       <td className="border border-gray-300 px-4 py-2">
//                         {formData[entry.id]?.overtimeAmount || 0}
//                       </td>
//                       <td className="border border-gray-300 px-4 py-2">
//                         <input
//                           type="number"
//                           placeholder="Enter reimbursement"
//                           className="border border-gray-300 rounded px-2 py-1 text-xs"
//                           // value={formData[entry.id]?.reimbursement || ""}
//                           onChange={(e) =>
//                             handleFieldChange(
//                               index,
//                               "reimbursement",
//                               e.target.value
//                             )
//                           }
//                         />
//                       </td>
//                     </tr>
//                   ))}
//               </tbody>
//             </table>
//           </div>
//         ))
//       )}
//     </div>
//   );
// };

// export default LeaseWithDevice;

import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import moment from "moment";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";


const LeaseWithDevice = ({
  rso_id,
  asset_no,
  leaseId,
  deviceId,
  nominalHours,
  operators,
  nominal_hours_per_day,
  normal_amount_rates_by_hour,
}) => {
  const [timeData, setTimeData] = useState({});
  const [formData, setFormData] = useState({});
  const [uploadData, setUploadData] = useState({});

  // Fetch data
  const getTimeSheetdata = async () => {
    try {
      const res = await fetch(
        `${setBaseUrl}/lease/get-lease-timeline-by-device`,
        {
          headers: {
            "x-access-tokens": sessionStorage.getItem("token"),
            "rent-odoo-id": rso_id,
          },
        }
      );

      if (!res.ok) throw new Error("Failed to fetch data");
      const data = await res.json();
      setTimeData(data);

      const initialFormData = {};
      Object.entries(data).forEach(([month, entries]) => {
        entries.forEach((entry) => {
          initialFormData[entry.id] = {
            dayType: "wd", // Default day type
            reimbursement: entry.reimbursement || "",
          };
        });
      });
      setFormData(initialFormData);
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
    }
  };

  useEffect(() => {
    getTimeSheetdata();
  }, []);

  const formatTime = (time) => {
    if (!time) return "";
    const [hours, minutes] = time?.split(":");
    return `${hours}h ${minutes}m`;
  };

  const calculateOvertime = (totalTime, nominalHours, dayType) => {
    let totalMinutes = 0;

    if (!totalTime) return;
    if (totalTime?.includes("day")) {
      const [daysPart, timePart] = totalTime?.split(", ");
      const days = parseInt(daysPart?.split(" ")[0], 10) || 0;
      const [hours = 0, minutes = 0, seconds = 0] =
        timePart?.split(":").map(Number) || [];
      totalMinutes = days * 24 * 60 + hours * 60 + minutes + seconds / 60;
    } else if (totalTime?.includes(":")) {
      const [hours = 0, minutes = 0, seconds = 0] =
        totalTime?.split(":").map(Number) || [];
      totalMinutes = hours * 60 + minutes + seconds / 60;
    } else {
      totalMinutes = 0;
    }

    let overtime = 0;
    if (dayType === "wd") {
      overtime = Math.max(0, totalMinutes - nominalHours);
    } else if (dayType === "hd") {
      overtime = totalMinutes;
    }
    return (overtime / 60).toFixed(2);
  };

  const getDaysInMonth = (month) => {
    // Get all days in a given month
    const startDate = moment(month, "YYYY-MM");
    const daysInMonth = startDate.daysInMonth();
    return Array.from({ length: daysInMonth }, (_, i) =>
      startDate.date(i + 1).format("YYYY-MM-DD")
    );
  };

  // const handleDayTypeChange = (index, dayType, totalTime) => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [index]: {
  //       ...prevFormData[index],
  //       dayType,
  //       overtimeAmount: calculateOvertime(totalTime, nominalHours, dayType),
  //     },
  //   }));
  // };
  const handleDayTypeChange = (id, field, value, day) => {
    if (!id) return;

    setFormData((prevFormData) => {
      const updatedData = { ...prevFormData[id], [field]: value };

      // Calculate overtime only if `dayType` is being updated
      if (field === "dayType") {
        const totalTime =
          timeData[day]?.find((entry) => entry.device_working_time_id === id)
            ?.total_time || 0;
        updatedData.overtimeAmount = calculateOvertime(
          totalTime,
          nominalHours,
          value
        );
      }

      return { ...prevFormData, [id]: updatedData };
    });
  };

  // const handleFieldChange = (id, field, value, day) => {
  //   console.log(id)
  //   if(!id) return;

  //   setFormData((prevFormData) => {
  //     const updatedData = { ...prevFormData[id], [field]: value };

  //     if (field === "dayType") {
  //       updatedData.overtimeAmount = calculateOvertime(
  //         timeData[day]?.total_time,
  //         nominalHours,
  //         value
  //       );
  //     }

  //     return { ...prevFormData, [id]: updatedData };
  //   })

  //   console.log(formData)
  //   // console.log("ID:", id, "Field:", field, "Value:", value);

  //   // setFormData((prevFormData) => {
  //   //   const updatedData = { ...prevFormData[id], [field]: value };

  //   //   if (field === "dayType") {
  //   //     updatedData.overtimeAmount = calculateOvertime(
  //   //       timeData[id]?.total_time,
  //   //       nominalHours,
  //   //       value
  //   //     );
  //   //   }

  //   //   return { ...prevFormData, [id]: updatedData };
  //   // });
  // };

  const handleFieldChange = (id, field, value, day) => {
    if (!id) return; // Ensure `id` is valid

    setFormData((prevFormData) => {
      const updatedData = { ...prevFormData[id], [field]: value };

      // If dayType is changed, calculate overtime
      if (field === "dayType") {
        const totalTime = timeData[day]?.find(
          (entry) => entry.device_working_time_id === id
        )?.total_time;
        updatedData.overtimeAmount = calculateOvertime(
          totalTime,
          nominalHours,
          value
        );
      }

      return { ...prevFormData, [id]: updatedData };
    });

    // Log after state update to ensure proper visibility
    // setTimeout(() => console.log("Updated formData:", formData), 0);
  };

  // console.log(formData)
  // const handleSubmit = async (month) => {
  //   const dataMap = new Map();

  //   timeData[month]?.forEach((entry) => {
  //     const id = entry.device_working_time_id;
  //     const formDataEntry = formData[id] || {}; // Get formData for the current entry

  //     const update = {
  //       x_studio_rental_register_no: rso_id,
  //       x_name: asset_no,
  //       x_studio_date: moment(entry.start_time).format("YYYY-MM-DD"),
  //       x_studio_day_type: formDataEntry.dayType || "wd",
  //       x_studio_time_in: entry.start_time ? moment(entry.start_time).format("HH:mm") : "N/A",
  //       x_studio_time_out: entry.end_time ? moment(entry.end_time).format("HH:mm") : "N/A",
  //       x_studio_breakdown_time: entry.breakdown_time || "N/A",
  //       x_studio_overtime: entry.total_time
  //         ? calculateOvertime(entry.total_time, nominalHours, formDataEntry.dayType || "wd")
  //         : "0.00",
  //       x_studio_operator1: "Operator",
  //       x_studio_reimbursements: formDataEntry.reimbursement || "",
  //       device_working_time_id: id,
  //     };

  //     // Use Map to ensure unique entries
  //     if (dataMap.has(id)) {
  //       const existingEntry = dataMap.get(id);
  //       dataMap.set(id, { ...existingEntry, ...update });
  //     } else {
  //       dataMap.set(id, update);
  //     }
  //   });

  //   const uniqueDataArray = Array.from(dataMap.values());
  // console.log(uniqueDataArray);
  //   // try {
  //   //   const response = await fetch(`${setBaseUrl}/lease/entry-timesheet-data`, {
  //   //     method: "POST",
  //   //     body: JSON.stringify({
  //   //       input_arr: uniqueDataArray,
  //   //       action: "keep",
  //   //     }),
  //   //     headers: {
  //   //       "Content-Type": "application/json",
  //   //       "x-access-tokens": sessionStorage.getItem("token"),
  //   //     },
  //   //   });

  //   //   if (response.ok) {
  //   //     toast.success("Data updated successfully!");
  //   //   } else {
  //   //     throw new Error("Failed to update data");
  //   //   }
  //   // } catch (error) {
  //   //   console.error(error);
  //   //   toast.error("Data not updated successfully. Please check odoo logs and try again!!");
  //   // }
  // };
  const handleSubmit = async (month) => {
    const dataMap = new Map();

    timeData[month]?.forEach((entry) => {
      const id = entry.device_working_time_id;
      const formDataEntry = formData[id] || {}; // Get formData for the current entry

      const update = {
        x_studio_rental_register_no: rso_id,
        x_name: asset_no,
        x_studio_date: moment(entry.start_time).format("YYYY-MM-DD"),
        x_studio_day_type: formDataEntry.dayType || "wd",
        x_studio_time_in: entry.start_time
          ? moment(entry.start_time).format("HH:mm")
          : "N/A",
        x_studio_time_out: entry.end_time
          ? moment(entry.end_time).format("HH:mm")
          : "N/A",
        x_studio_breakdown_time: entry.breakdown_time || "N/A",
        x_studio_overtime: entry.total_time
          ? calculateOvertime(
              entry.total_time,
              nominalHours,
              formDataEntry.dayType || "wd"
            )
          : "0.00",
        x_studio_operator1: entry.operator || "Operator",
        x_studio_normal_amount:
          formDataEntry.x_studio_normal_amount || nominal_hours_per_day || "",
        x_studio_overtime_amount: formDataEntry.x_studio_overtime_amount || "",
        x_studio_reimbursements: formDataEntry.reimbursement || "",
        device_working_time_id: id,
      };

      // Use Map to ensure unique entries
      if (dataMap.has(id)) {
        const existingEntry = dataMap.get(id);
        dataMap.set(id, { ...existingEntry, ...update });
      } else {
        dataMap.set(id, update);
      }
    });

    const uniqueDataArray = Array.from(dataMap.values());
    // console.log(uniqueDataArray);

    try {
      const response = await fetch(`${setBaseUrl}/lease/entry-timesheet-data`, {
        method: "POST",
        body: JSON.stringify({
          input_arr: uniqueDataArray,
          action: "keep",
        }),
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });

      if (response.ok) {
        toast.success("Data updated successfully!");
      } else {
        throw new Error("Failed to update data");
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "Data not updated successfully. Please check odoo logs and try again!!"
      );
    }
  };

  // const handleSubmit = async (month) => {
  //   const dataMap = new Map();

  //   // Iterate over the entries for the specified month
  //   for (let i = 0; i < timeData[month].length; i++) {
  //     const entry = timeData[month][i];
  //     const id = entry.device_working_time_id; // Match the ID from timeData
  //     const formDataEntry = formData[id] || {}; // Get the related formData entry or default to an empty object

  //     const update = {
  //       x_studio_rental_register_no: rso_id,
  //       x_name: asset_no,
  //       x_studio_date: moment(entry.start_time).format("YYYY-MM-DD"),
  //       x_studio_day_type: formDataEntry.dayType || "wd", // Use updated formData value
  //       x_studio_time_in: entry.start_time
  //         ? moment(entry.start_time).format("HH:mm")
  //         : "N/A",
  //       x_studio_time_out: entry.end_time
  //         ? moment(entry.end_time).format("HH:mm")
  //         : "N/A",
  //       x_studio_breakdown_time: entry.breakdown_time || "N/A",
  //       x_studio_overtime: entry.total_time
  //         ? calculateOvertime(
  //             entry.total_time,
  //             nominalHours,
  //             formDataEntry.dayType || "wd"
  //           )
  //         : "N/A",
  //       x_studio_operator1: "Operator",
  //       x_studio_reimbursements: formDataEntry.reimbursement || "",
  //       device_working_time_id: id,
  //     };

  //     // Update the Map with the unique ID
  //     if (dataMap.has(id)) {
  //       const existingEntry = dataMap.get(id);
  //       dataMap.set(id, { ...existingEntry, ...update });
  //     } else {
  //       dataMap.set(id, update);
  //     }
  //   }

  //   // Convert the Map to an array of unique data
  //   const uniqueDataArray = Array.from(dataMap.values());
  //   console.log(uniqueDataArray);
  //   // Submit the data
  //   // try {
  //   //   const response = await fetch(`${setBaseUrl}/lease/entry-timesheet-data`, {
  //   //     method: "POST",
  //   //     body: JSON.stringify({
  //   //       input_arr: uniqueDataArray,
  //   //       action: "keep",
  //   //     }),
  //   //     headers: {
  //   //       "Content-Type": "application/json",
  //   //       "x-access-tokens": sessionStorage.getItem("token"),
  //   //     },
  //   //   });

  //   //   if (response.ok) {
  //   //     toast.success("Data updated successfully!");
  //   //   } else {
  //   //     throw new Error("Failed to update data");
  //   //   }
  //   // } catch (error) {
  //   //   console.error(error);
  //   //   toast.error("Data not updated successfully. Please check odoo logs and try again!!");
  //   // }
  // };

  // const handleSubmit = async (month) => {
  //   const dataMap = new Map();

  //   for (let i = 0; i < timeData[month].length; i++) {
  //     const entry = timeData[month][i];
  //     const workingTimeId = entry.device_working_time_id;
  //     const update = {
  //       x_studio_rental_register_no: rso_id,
  //       x_name: asset_no,
  //       x_studio_date: moment(entry.start_time).format("YYYY-MM-DD"),
  //       x_studio_day_type: formData[entry.id]?.dayType || "wd",
  //       x_studio_time_in: entry.start_time === null ? "N/A" : moment(entry.start_time).format("HH:mm"),
  //       x_studio_time_out: entry.end_time === null ? "N/A" : moment(entry.end_time).format("HH:mm"),
  //       x_studio_breakdown_time: entry.breakdown_time || "N/A",
  //       x_studio_overtime: entry.total_time ? calculateOvertime(entry.total_time, nominalHours, formData[entry.id]?.dayType || "wd") : "N/A",
  //       x_studio_operator1: "Operator",
  //       x_studio_reimbursements: formData[entry.id]?.reimbursement || "",
  //       device_working_time_id: workingTimeId,
  //     };

  //     if (dataMap.has(workingTimeId)) {
  //       const existingEntry = dataMap.get(workingTimeId);
  //       dataMap.set(workingTimeId, { ...existingEntry, ...update });
  //     } else {
  //       dataMap.set(workingTimeId, update);
  //     }
  //   }

  //   const uniqueDataArray = Array.from(dataMap.values());

  //   try {
  //     const response = await fetch(`${setBaseUrl}/lease/entry-timesheet-data`, {
  //       method: "POST",
  //       body: JSON.stringify({
  //         input_arr: uniqueDataArray,
  //         action: "keep",
  //       }),
  //       headers: {
  //         "Content-Type": "application/json",
  //         "x-access-tokens": sessionStorage.getItem("token"),
  //       },
  //     });

  //     if (response.ok) {
  //       toast.success("Data updated successfully!");
  //     } else {
  //       throw new Error("Failed to update data");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     toast.error("Data not updated successfully. Please check odoo logs and try again!!");
  //   }
  // };

  const exportToExcel = (monthData, month) => {
    const ws = XLSX.utils.json_to_sheet(monthData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `Lease Data for ${month}`);
    XLSX.writeFile(wb, `Lease_Timesheet_${month}.xlsx`);
  };

  const getMonthData = (entries) => {
    return entries.map((entry) => ({
      Date: moment(entry.start_time).format("DD-MM-YYYY"),
      "Day Type": formData[entry.id]?.dayType || "wd",
      "Start Time": moment(entry.start_time).format("HH:mm"),
      "End Time": moment(entry.end_time).format("HH:mm"),
      "Total Time": formatTime(entry.total_time?.split(".")[0]),
      "Breakdown Time": entry?.breakdown_time || "N/A",
      Overtime: calculateOvertime(
        entry?.total_time,
        nominalHours,
        formData[entry.id]?.dayType || "wd"
      ),
      "Normal Amount": formData[entry.id]?.normalAmount || 0,
      "Overtime Amount": formData[entry.id]?.overtimeAmount || 0,
      Reimbursement: formData[entry.id]?.reimbursement || "",
      "Operator Name": "Operator",
    }));
  };

  // normal_amount_rates_by_hour

  // normal_amount
  // working days
  // is normal amount  and monthly rental amount same?

  // calculate normal amount

  // calculate overtime amount
  const calculateOvertimeAmount = (overtime, overtimeAmount) => {
    if (!overtime || !overtimeAmount) {
      return;
    }

    let overTimeAmount = overtime * overtimeAmount;

    return overTimeAmount;
  };

  const calculateOvertimeRate = (normalAmount) => {
    let baseMultiplier = 0.1,
      step = 0.1,
      count = 10;
    if (!normalAmount || isNaN(normalAmount)) {
      return []; // Return an empty array for invalid inputs
    }

    const overtimeRates = [];
    for (let i = 0; i < count; i++) {
      const multiplier = baseMultiplier + step * i; // Auto-increment multiplier
      const overtimeAmount = normalAmount * multiplier;
      overtimeRates.push({
        multiplier: multiplier.toFixed(2),
        overtimeAmount: overtimeAmount.toFixed(2),
      });
    }

    return overtimeRates;
  };

  return (
    <div className="p-6 bg-gray-50 font-sans text-xs">
      <h3 className="text-lg font-semibold mb-4 text-gray-800">
        Lease Timesheet Details
      </h3>
      {Object.keys(timeData).length === 0 ? (
        <p>Loading data...</p>
      ) : (
        Object.entries(timeData)
          .reverse()
          .map(([month, entries]) => {
            const allDaysInMonth = getDaysInMonth(month);

            return (
              <div key={month} className="my-4">
                <Accordion>
                  {/* <AccordionSummary> */}
                  <AccordionSummary
                    className="text-md font-bold mb-2 text-gray-700"
                    sx={{
                      backgroundColor: "rgba(0, 0, 0, .03)",
                      borderBottom: "1px solid rgba(0, 0, 0, .125)",
                      padding: "0px 10px",
                      display: "flex",
                      alignItems: "center", // Ensure vertical alignment
                    }}
                  >
                    {/* Left-aligned Date */}
                    <div className="flex-1">{month}</div>

                    {/* Right-aligned Buttons */}
                   
                  </AccordionSummary>

                  {/* </AccordionSummary> */}

                  <AccordionDetails>
                  <div className="flex space-x-2 items-end justify-end mb-2">
                      <button
                        onClick={() => handleSubmit(month)}
                        className="bg-green-500 text-white px-3 py-1 text-xs rounded hover:bg-green-600"
                      >
                        Submit for {month}
                      </button>
                      <button
                        onClick={() =>
                          exportToExcel(getMonthData(entries), month)
                        }
                        className="bg-blue-500 text-white px-3 py-1 text-xs rounded hover:bg-blue-600"
                      >
                        Export to Excel
                      </button>
                    </div>
                    <table className="table-auto w-full text-xs border-collapse border border-gray-300 shadow-md">
                      <thead className="bg-gray-200">
                        <tr>
                          <th className="border border-gray-300 px-2 py-2">
                            Date
                          </th>
                          <th className="border border-gray-300 px-2 py-2">
                            Day Type
                          </th>
                          <th className="border border-gray-300 px-2 py-2">
                            Start Time
                          </th>
                          <th className="border border-gray-300 px-2 py-2">
                            End Time
                          </th>
                          <th className="border border-gray-300 px-2 py-2">
                            Total Time
                          </th>
                          <th className="border border-gray-300 px-2 py-2">
                            Breakdown Time
                          </th>
                          <th className="border border-gray-300 px-2 py-2">
                            Overtime
                          </th>
                          <th className="border border-gray-300 px-2 py-2">
                            Normal Amount(₹)
                          </th>
                          <th className="border border-gray-300 px-2 py-2">
                            Overtime Amount(₹)
                          </th>
                          <th className="border border-gray-300 px-2 py-2">
                            Reimbursement(₹)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allDaysInMonth.map((day) => {
                          const entry = entries.find(
                            (entry) =>
                              moment(entry.start_time).format("YYYY-MM-DD") ===
                              day
                          );
                          return (
                            <tr
                              key={day}
                              className={`border-t ${
                                entry?.is_resloved === false
                                  ? "bg-red-100"
                                  : "bg-white"
                              }`}
                            >
                              <td className="border border-gray-300 px-2 py-2">
                                {moment(day).format("DD-MM-YYYY")}
                              </td>
                              <td className="border border-gray-300 px-2 py-2">
                                {/* {console.log(entry)} */}
                                <select
                                  // value={
                                  //   formData[entry?.device_working_time_id]
                                  //     ?.dayType || "wd"
                                  // }
                                  onChange={(e) =>
                                    handleDayTypeChange(
                                      entry?.device_working_time_id,
                                      "dayType",
                                      e.target.value,
                                      day
                                    )
                                  }
                                  className="p-2 border rounded text-xs"
                                >
                                  <option value="wd">WD</option>
                                  <option value="hd">HD</option>
                                </select>
                              </td>
                              <td className="border border-gray-300 px-2 py-2">
                                {entry
                                  ? moment(entry.start_time).format("HH:mm")
                                  : "N/A"}
                              </td>
                              <td className="border border-gray-300 px-2 py-2">
                                {entry
                                  ? moment(entry.end_time).format("HH:mm")
                                  : "N/A"}
                              </td>
                              <td className="border border-gray-300 px-2 py-2">
                                {entry
                                  ? formatTime(entry.total_time?.split(".")[0])
                                  : "N/A"}
                              </td>
                              <td className="border border-gray-300 px-2 py-2">
                                {entry?.breakdown_time || "N/A"}
                              </td>
                              <td className="border border-gray-300 px-2 py-2">
                                {entry
                                  ? calculateOvertime(
                                      entry?.total_time,
                                      nominalHours,
                                      formData[entry.device_working_time_id]
                                        ?.dayType || "wd"
                                    )
                                  : "N/A"}
                              </td>

                              <td className="border border-gray-300 px-2 py-2">
                                <input
                                  type="number"
                                  min={0}
                                  placeholder="Normal Amount"
                                  value={
                                    formData[entry?.id]
                                      ?.x_studio_normal_amount ||
                                    parseFloat(
                                      normal_amount_rates_by_hour
                                    ).toFixed(2) ||
                                    ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      entry?.device_working_time_id,
                                      "x_studio_normal_amount",
                                      e.target.value,
                                      day
                                    )
                                  }
                                  className="p-2 border rounded text-xs"
                                />
                              </td>
                              <td className="border border-gray-300 px-2 py-2">
                                <input
                                  type="number"
                                  min={0}
                                  placeholder="Overtime Amount"
                                  value={
                                    formData[entry?.id]
                                      ?.x_studio_overtime_amount ||
                                    parseFloat(
                                      normal_amount_rates_by_hour
                                    ).toFixed(2) ||
                                    ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      entry?.device_working_time_id,
                                      "x_studio_overtime_amount",
                                      e.target.value,
                                      day
                                    )
                                  }
                                  className="p-2 border rounded text-xs"
                                />
                              </td>
                              <td className="border border-gray-300 px-2 py-2">
                                <input
                                  type="number"
                                  min={0}
                                  placeholder="Reimbursement"
                                  // value={formData[entry?.id]?.reimbursement || ""}
                                  onChange={(e) =>
                                    handleFieldChange(
                                      entry?.device_working_time_id,
                                      "reimbursement",
                                      e.target.value,
                                      day
                                    )
                                  }
                                  className="p-2 border rounded text-xs"
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })
      )}
    </div>
  );
};

export default LeaseWithDevice;
