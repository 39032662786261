import React, { useState } from "react";
import { toast } from "react-toastify";
import { setBaseUrl } from "../../../../config";

const BillingDetails = ({ billing }) => {
  const [formData, setFormData] = useState(
    billing.map((item) => ({
      id: item.id,
      actual_normal_working_ammount: item.actual_normal_working_ammount ,
      actual_overtime_working_ammount: item.actual_overtime_working_ammount ,
      actual_total_working_ammount: item.actual_total_working_ammount ,
    }))
  );

  // ✅ Handle input changes for individual fields
  const handleChange = (index, field, value) => {
    const updatedForm = [...formData];
    updatedForm[index][field] = parseFloat(value) || 0; // Ensure numeric value
    updatedForm[index].actual_total_working_ammount =
      parseFloat(updatedForm[index].actual_normal_working_ammount) +
      parseFloat(updatedForm[index].actual_overtime_working_ammount); // Auto-calculate total
    setFormData(updatedForm);
  };

  // ✅ Handle individual row submission
  const handleSubmit = async (index) => {
    const updatedRow = formData[index];
// console.log(updatedRow);
    try {
      const response = await fetch(`${setBaseUrl}/lease/estimate-cost-update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens" : sessionStorage.getItem("token")
        },
        body: JSON.stringify(updatedRow),
      });

      if (!response.ok) throw new Error("Failed to submit");

      toast.success(`Billing data  updated successfully!`);
    } catch (error) {
      console.error("Submission error:", error);
      toast.error("Failed to submit data.");
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-xs font-semibold mb-3">Billing Details</h2>
      <table className="w-full border-collapse border border-gray-200 text-xs">
        <thead>
          <tr className="bg-gray-100">
            <th className="border p-2">Month-Year</th>
        
            <th className="border p-2">Normal Billing Amount:</th>
            <th className="border p-2">Overtime Billing Amount</th>
            <th className="border p-2">Total Billing Amount:</th>
            {/* <th className="border p-2">Normal Hours</th> */}
            {/* <th className="border p-2">Overtime Hours</th> */}
            <th className="border p-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {billing.map((item, index) => (
            <tr key={item.id} className="hover:bg-gray-100">
              <td className="border p-2">{item.month_year}</td>
              {/* <td className="border p-2">₹ {item.ideal_normal_working_ammount.toFixed(2)}</td>
              <td className="border p-2">₹ {item.ideal_overtime_working_ammount.toFixed(2)}</td>
              <td className="border p-2">₹ {item.actual_total_working_ammount.toFixed(2)}</td> */}
              
              {/* Normal Amount Input */}
              <td className="border p-2">
                <input
                  type="number"
                  className="bg-transparent text-xs w-full p-1 border rounded"
                  value={formData[index].actual_normal_working_ammount}
                  onChange={(e) => handleChange(index, "actual_normal_working_ammount", e.target.value)}
                />
              </td>

              {/* Overtime Amount Input */}
              <td className="border p-2">
                <input
                  type="number"
                  className="bg-transparent text-xs w-full p-1 border rounded"
                  value={formData[index].actual_overtime_working_ammount}
                  onChange={(e) => handleChange(index, "actual_overtime_working_ammount", e.target.value)}
                />
              </td>

              {/* Auto-updating Total Amount */}
              <td className="border p-2 font-semibold text-blue-600">
                ₹ {formData[index]?.actual_total_working_ammount.toFixed(2)}
              </td>

              {/* <td className="border p-2">{item.normal_working_hour}</td>
              <td className="border p-2">{item.overtime_working_hour}</td> */}

              {/* Submit Button */}
              <td className="border p-2">
                <button
                  className="bg-blue-2 hover:bg-blue-1 text-white buttons"
                  onClick={() => handleSubmit(index)}
                >
                  Submit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BillingDetails;
