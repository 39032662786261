import React, { useEffect, useRef, useState } from "react";
import { Button, Paper, TablePagination } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { formatDate } from "../../Modals/AssetDetails/Tabs/FormatDate";

const ButtonData = ({ handleExport, selectedRows, filteredData }) => {
  return (
    <>
      <span title="Export Data in Excel format" placement="top">
        {filteredData.length > 0 ? (
          <button
            variant="outlined"
            sx={{
              fontSize: 10,
              color: "#000",
              width: 104,
              fontWeight: "bold",
            }}
            onClick={handleExport}
            className="border text-black buttons text-xs "
          >
            Export Excel
          </button>
        ) : (
          <button
            disabled
            variant="outlined"
            sx={{
              fontSize: 10,
              color: "#000",
              width: 104,
              fontWeight: "bold",
              textDecoration: "line-through", // Added line-through
            }}
            className="text-xs border line-through font-bold buttons"
          >
            Export Excel
          </button>
        )}
      </span>
    </>
  );
};

const NewOperatorTable = ({
  allOperators,
  setFilteredData,
  selectedRows,
  setSelectedRows,
  handleExport,
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filteredData, setLocalFilteredData] = useState(allOperators);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const inputRef = useRef(null);
  const navigate = useNavigate();

  const [statusFilter, setStatusFilter] = useState("all");

  // const filterOperatorsByStatus = (operators, status) => {
  //   // return operators.filter((operator) => {
  //   //   const leavingDate = operator.leaving_date
  //   //     ? new Date(operator.leaving_date)
  //   //     : null;
  //   //   const today = new Date();

  //   //   if (status === "available") {
  //   //     return !leavingDate || leavingDate < today; // Available: No leaving_date or it's in the past
  //   //   } else if (status === "assigned") {
  //   //     return leavingDate && leavingDate >= today; // Assigned: Leaving date is in the future
  //   //   } else if (status === "not_specified") {
  //   //     return !operator.leaving_date; // Not Specified: No leaving date provided
  //   //   }
  //   //   return true; // Default: Show all
  //   // });

  //   return operators.filter((operator) => {
  //     const leavingDate = operator.leaving_date

  //     if(!leavingDate) {
  //       return true; // No leaving_date provided
  //     } else {

  //     }
  //   })
  // };
  const filterOperatorsByStatus = (operators, status) => {
    return operators.filter((operator) => {
      const leavingDate = operator.leaving_date
        ? new Date(operator.leaving_date)
        : null;
      const today = new Date();

      if (status === "Active") {
        return !leavingDate || leavingDate < today; // Active: No leaving_date or it's in the past
      } else if (status === "Inactive") {
        return leavingDate && leavingDate >= today; // Inactive: Leaving date is in the future
      }
      return true; // Default: Show all
    });
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  // Apply filtering before displaying data
  const filteredOperators = filterOperatorsByStatus(filteredData, statusFilter);

  useEffect(() => {
    setFilteredData(filteredData);
  }, [filteredData, setFilteredData]);

  useEffect(() => {
    setLocalFilteredData(allOperators);
  }, [allOperators]);

  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = allOperators.filter((operator) =>
      Object.values(operator).some((value) =>
        value?.toString().toLowerCase().includes(lowerCaseQuery)
      )
    );
    setLocalFilteredData(filtered);
    setSuggestions([]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch(searchQuery);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    setPageSize(value === "All data" ? filteredData.length : value);
    setPage(0);
  };

  const handleInputChange = (query) => {
    setSearchQuery(query);
    if (query.trim() === "") {
      setSuggestions([]);
    } else {
      const lowerCaseQuery = query.toLowerCase();
      const matches = allOperators
        .filter(
          (operator) =>
            operator.name?.toLowerCase().includes(lowerCaseQuery) ||
            operator.aadhar_no?.toString().includes(lowerCaseQuery) ||
            operator.pf_account_no?.toString().includes(lowerCaseQuery)
        )
        .map((operator) => ({
          name: operator.name,
          aadhar_no: operator.aadhar_no,
          pf_account_no: operator.pf_account_no,
        }));
      setSuggestions(matches.slice(0, 5)); // Limit suggestions to 5
    }
  };

  const preprocessData = (data) =>
    data.map((item) => ({
      ...item,
      pf_account_no: Number.isInteger(item.pf_account_no)
        ? item.pf_account_no
        : parseInt(item.pf_account_no, 10) || "N/A",
      aadhar_no: item.aadhar_no
        ? item.aadhar_no
            .toString()
            .replace(/\D/g, "") // Remove non-numeric characters
            .replace(/(\d{4})(\d{4})(\d{4})/, "$1 $2 $3")
        : "N/A", // Fallback to "n/a" if Aadhaar number is invalid or missing
      joining_date: item.joining_date ? formatDate(item.joining_date) : "N/A",
      leaving_date: item.leaving_date ? formatDate(item.leaving_date) : "N/A",
    }));

  const columns = [
    {
      field: "counter",
      headerName: "#",
      width: 80,
      renderHeader: () => (
        <span title="Row Number" arrow placement="top">
          <span>#</span>
        </span>
      ),
      renderCell: (params) => <>{params.row.counter}</>, // Use the counter field
    },

    {
      field: "name",
      headerName: "Name",
      width: 180,
      renderHeader: () => (
        <span title="Name of the operator" arrow placement="top">
          <span>Name</span>
        </span>
      ),
    },
    {
      field: "aadhar_no",
      headerName: "Aadhaar Number",
      width: 180,
      renderHeader: () => (
        <span title="Aadhaar number of the operator" arrow placement="top">
          <span>Aadhaar Number</span>
        </span>
      ),
    },
    {
      field: "operator_role",
      headerName: "Operator Role",
      width: 180,
      renderHeader: () => (
        <span title="Role of the operator" arrow placement="top">
          <span>Operator Role</span>
        </span>
      ),
    },
    {
      field: "phone",
      headerName: "Contact Number",
      width: 180,
      renderHeader: () => (
        <span title="contact number of the operator" arrow placement="top">
          <span>Contact Number</span>
        </span>
      ),
    },

    {
      field: "sale_person",
      headerName: "Operator Sales Person",
      width: 180,
      renderHeader: () => (
        <span title="operator sales person" arrow placement="top">
          <span>Operator Sales Person</span>
        </span>
      ),
    },
    {
      field: "arr_asset_nos",
      headerName: "arr_asset_nos",
      width: 180,
      renderHeader: () => (
        <span title="Name of the operator" arrow placement="top">
          <span>Assets</span>
        </span>
      ),
    },

    // {
    //   field: "joining_date",
    //   headerName: "Joining Date",
    //   width: 180,
    //   renderHeader: () => (
    //     <span title="Date the operator joined" arrow placement="top">
    //       <span>Joining Date</span>
    //     </span>
    //   ),
    // },
    {
      field: "leaving_date",
      headerName: "Availability",
      width: 180,
      renderHeader: () => (
        <span title="Operator Availability" placement="top" arrow>
          <span>Check Availability</span>
        </span>
      ),
      renderCell: (params) => {
        const leavingDate = params.row.leaving_date
          ? new Date(params.row.leaving_date)
          : null;

        if (!leavingDate) {
          return <span className="text-green-500 font-bold">Active</span>;
        } else {
          return <span className="text-dark-6 font-bold">Inactive</span>;
        }
      },
    },

    // {
    //   field: "leaving_date",
    //   headerName: "Leaving Date",
    //   width: 180,
    //   renderHeader: () => (
    //     <span title="Date the operator left" placement="top" arrow>
    //       <span>Check Availability</span>
    //     </span>
    //   ),

    //   renderCell: (params) => {
    //     const leavingDate = params.row.leaving_date;
    //     if (!leavingDate) {
    //       return <span className="text-green-1">Active</span>;
    //     } {
    //       return <span>Inactive</span>;
    //     }
    //   }
    // },
    // {
    //   field: "leaving_date",
    //   headerName: "Status",
    //   width: 180,
    //   renderHeader: () => (
    //     <span title="Operator Status" arrow placement="top">
    //       <span>Status</span>
    //     </span>
    //   ),
    //   renderCell: (params) => {
    //     const leavingDate = params.row.leaving_date
    //       ? new Date(params.row.leaving_date)
    //       : null;
    //     const today = new Date();

    //     if (!leavingDate || leavingDate < today) {
    //       return <span className="text-green-500 font-bold">Available</span>;
    //     } else if (leavingDate >= today) {
    //       return <span className="text-red-500 font-bold">Assigned</span>;
    //     } else {
    //       return <span className="text-gray-500 font-bold">Not Specified</span>;
    //     }
    //   },
    // },
  ];

  return (
    <div className="fixed right-3 left-[5%] bg-white">
      {/* 
      
      Name, Adhaar number, role, contact number, sales person, asset no, status
      */}
      <Paper
        style={{
          padding: 10,
          height: "70vh",
          width: "100%",
          border: "hidden",
        }}
        elevation={0}
      >
        <div className="flex justify-between items-center">
          <div className="relative flex gap-2 items-center">
            <div className="flex gap-2 border rounded-[4px]">
              <input
                title="Search by operator name, adhaar number, etc..."
                ref={inputRef}
                type="text"
                placeholder="Search by Name, Aadhaar No, etc..."
                value={searchQuery}
                onChange={(e) => handleInputChange(e.target.value)}
                onKeyDown={handleKeyDown}
                className="border-light-5 border bg-gray-50 h-[30px] w-[200px] p-[5px] rounded-[4px] text-[12px] items-center"
              />
              {suggestions.length > 0 && (
                <ul
                  className="absolute z-10 bg-white border border-gray-300 rounded-md shadow-md max-h-40 overflow-auto"
                  style={{
                    top: "35px",
                    left: 0,
                    width: "300px",
                  }}
                >
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      className="p-2 hover:bg-gray-200 cursor-pointer text-sm"
                      onClick={() => {
                        setSearchQuery(
                          suggestion.name ||
                            suggestion.aadhar_no ||
                            suggestion.pf_account_no
                        );
                        handleSearch(
                          suggestion.name ||
                            suggestion.aadhar_no ||
                            suggestion.pf_account_no
                        );
                      }}
                    >
                      {suggestion.name ||
                        suggestion.aadhar_no ||
                        suggestion.pf_account_no}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <div
              title="Check availability of the operator"
              className="flex items-center gap-2"
            >
              <span className="text-xs">Check Availability: </span>
              <select
                className="text-xs border rounded p-1 text-center w-[150px]"
                value={statusFilter}
                onChange={handleStatusChange}
              >
                <option value="all">All Operators</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
                {/* <option value="not_specified">Not Specified</option> */}
              </select>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <TablePagination
              title="Pagination"
              component="div"
              count={filteredData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 30, 50, "All data"]}
              labelRowsPerPage="Operators:"
              SelectProps={{
                renderValue: (value) =>
                  value === "All data" ? "All data" : value, // Display "All data" if selected
              }}
              className="bg-white rounded-lg"
            />
            <NewAssetBtn tabName="operator" />
            <ButtonData
              handleExport={handleExport}
              selectedRows={selectedRows}
              filteredData={filteredData}
            />
          </div>
        </div>

        {filteredData.length > 0 ? (
          // <DataGrid
          //   rows={
          //     pageSize === filteredData.length
          //       ? filteredData.map((row, index) => ({
          //           ...row,
          //           counter: index + 1, // Add row number for all rows
          //         }))
          //       : filteredData
          //           .slice(page * pageSize, page * pageSize + pageSize) // Paginated rows
          //           .map((row, index) => ({
          //             ...row,
          //             counter: page * pageSize + index + 1, // Adjust counter for paginated rows
          //           }))
          //   }
          //   onRowClick={(params) => {
          //     const rowId = params.row.id; // Use the row ID or another property to navigate
          //     navigate(`/operators/details/${rowId}`); // Adjust the route as needed
          //   }}
          //   columns={columns}
          //   // checkboxSelection
          //   paginationMode="server"
          //   pagination={false}
          //   disableSelectionOnClick
          //   // sx={{
          //   //   "& .MuiDataGrid-footerContainer": {
          //   //     display: "none", // Hides the footer container
          //   //   },
          //   //   "& .MuiDataGrid-root": { fontSize: "12px", height: 30 },
          //   //   "& .MuiDataGrid-columnHeaders": {
          //   //     fontSize: "14px",
          //   //     backgroundColor: "#000",
          //   //     fontWeight: "bold",
          //   //     color: "#006DB6",
          //   //   },
          //   //   "& .MuiDataGrid-columnHeaderTitle": {
          //   //     fontWeight: "bold",
          //   //     height: 20,
          //   //   },
          //   //   "& .MuiDataGrid-cell": { fontSize: "12px" },
          //   //   "& .MuiDataGrid-virtualScroller": {
          //   //     "&::-webkit-scrollbar": {
          //   //       display: "none",
          //   //     },
          //   //     "-ms-overflow-style": "none",
          //   //     "scrollbar-width": "none",
          //   //   },
          //   // }}
          //   sx={{
          //     "& .MuiDataGrid-footerContainer": {
          //       display: "none", // Hides the footer container
          //     },
          //     "& .MuiDataGrid-root": { fontSize: "12px", height: 30 },
          //     "& .MuiDataGrid-columnHeaders": {
          //       fontSize: "14px",
          //       backgroundColor: "#000",
          //       fontWeight: "bold",
          //       color: "#006DB6",
          //     },
          //     "& .MuiDataGrid-columnHeaderTitle": {
          //       fontWeight: "bold",
          //       height: 20,
          //     },
          //     "& .MuiDataGrid-cell": { fontSize: "12px", cursor: "pointer" },
          //     "& .MuiDataGrid-virtualScroller": {
          //       "&::-webkit-scrollbar": {
          //         display: "none",
          //       },
          //       "-ms-overflow-style": "none",
          //       "scrollbar-width": "none",
          //     },
          //     "& .MuiDataGrid-cell:focus": {
          //       outline: "none",
          //     },
          //   }}
          //   onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          // />
          <DataGrid
            rows={
              pageSize === filteredOperators.length // Use filteredOperators instead of filteredData
                ? filteredOperators.map((row, index) => ({
                    ...row,
                    counter: index + 1, // Add row number for all rows
                  }))
                : filteredOperators
                    .slice(page * pageSize, page * pageSize + pageSize) // Paginate filtered data
                    .map((row, index) => ({
                      ...row,
                      counter: page * pageSize + index + 1, // Adjust counter for paginated rows
                    }))
            }
            onRowClick={(params) => {
              const rowId = params.row.id; // Use the row ID or another property to navigate
              navigate(`/operators/details/${rowId}`); // Adjust the route as needed
            }}
            columns={columns}
            paginationMode="server"
            pagination={false}
            disableSelectionOnClick
            sx={{
              "& .MuiDataGrid-footerContainer": { display: "none" },
              "& .MuiDataGrid-root": { fontSize: "12px", height: 30 },
              "& .MuiDataGrid-columnHeaders": {
                fontSize: "14px",
                backgroundColor: "#000",
                fontWeight: "bold",
                color: "#006DB6",
              },
              "& .MuiDataGrid-cell": { fontSize: "12px", cursor: "pointer" },
              "& .MuiDataGrid-virtualScroller": {
                "&::-webkit-scrollbar": { display: "none" },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
              },
              "& .MuiDataGrid-cell:focus": { outline: "none" },
            }}
            onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          />
        ) : (
          <>
            <div className="text-xs items-center flex justify-center p-10 border">
              No operators found in your search area
            </div>
          </>
        )}
      </Paper>
    </div>
  );
};

export default NewOperatorTable;
