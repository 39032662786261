import React, { useState } from "react";

import SalesNavbar from "../../components/Sales/SalesNavbar";
import SalesSidebar from "../../components/Sales/SalesSidebar";

const SalesLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div className="flex bg-gray-100">
      {/* Sidebar */}
      <SalesSidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/>
      {/* Main Content Area */}
      <div className="flex flex-col ml-[4rem]">
        {/* Navbar */}
       <SalesNavbar/>

        {/* Main Content */}
        <div className="p-2">{children}</div>
      </div>
    </div>
  );
};

export default SalesLayout;
