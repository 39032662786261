import { Tooltip } from "@mui/material";
import React from "react";
import { formatDateInput } from "../AssetDetails/Tabs/FormatDate";
import moment from "moment/moment";

function CommercialDetails({
  commToggle,
  setCommToggle,
  commercialDetails,
  setCommercialDetails,
  updateAssetCommercial,
  setUpdateAssetCommercial,
  assetBasicDetails,
  setAssetBasicDetails,
  updateAssetDetails,
  setUpdateAssetDetails,
}) {
  // const handleToggle = () => {
  //   setCommToggle((prev) => !prev);
  // };
  return (
    <>
      <div className="flex animSlideup relative">
        <div className="">
          <div className="flex items-center underline  h-9 w-fit px-2 gap-3 text-xs text-[#5a5959]  dark:border-dark-5">
            Commercial Details
            {/* <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
              <input
               
                type="checkbox"
                name="toggle"
                id="showComm"
                className="
              comToggle-checkbox 
              
              absolute block w-4 h-4 rounded-full bg-white  appearance-none transition-all duration-200 ease-in hover:scale-105 cursor-pointer"
              />
              <label
                for="showComm"
                className="comToggle-label block overflow-hidden h-4 rounded-full bg-gray-500 cursor-pointer"
              ></label>
            </div> */}

    
          </div>
        </div>
      </div>

      <>
        <div className="border text-xs p-10 mt-2 gap-4 bg-white dark:bg-slate-900 grid grid-cols-1 md:grid-cols-4">
          {/* Item 1 */}
          <div title="Purchase order number" className="flex-1 w-[200px] ">
            
              <label className="font-bold">PO No.</label>
            
            <input
              type="text"
              maxLength={25}
              value={commercialDetails?.purchase_order_no}
              placeholder="PO Number."
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  purchase_order_no: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  purchase_order_no: e.target.value,
                });
              }}
            />
          </div>
          <div  title="Purchase order date" className="flex-1 w-[200px] ">
           
              <label className="font-bold">PO Date</label>
           
            <input
              type="date"
              min="2000-01-01"
              value={commercialDetails?.purchase_order_date || ''}
              placeholder="PO No.and copy placed on OEM"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  purchase_order_date: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  purchase_order_date: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 3 */}
          <div title="Invoice Number" className="flex-1 w-[200px] ">
          
              <label className="font-bold">Invoice No.</label>
          
            <input
              type="text"
              maxLength={25}
              value={commercialDetails?.invoice_no}
              placeholder="Invoice Number"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  invoice_no: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  invoice_no: e.target.value,
                });
              }}
            />
          </div>

          {/* Item 4 */}
          <div title="Invoice Date" className="flex-1 w-[200px] ">
            
              <label className="font-bold">Invoice Date</label>
            
            <input
              type="date"
              min="2000-01-01"
              value={commercialDetails?.invoice_date}
              placeholder="Invoice Number"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  invoice_date: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  invoice_date: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 5 */}
          <div  title="Payment Terms" className="flex-1 w-[200px] ">
            
              <label className="font-bold">Payment Terms</label>
            
            <input
              type="text"
              maxLength={100}
              value={commercialDetails?.payment_terms}
              placeholder="Payment Terms"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  payment_terms: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  payment_terms: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 6 */}
          <div title="Payment Remitted to OEM"  className="flex-1 w-[200px] ">
            
              <label className="font-bold">Payment Remitted to OEM (₹)</label>
            
            <input
              type="number"
              maxLength={25}
              // value={parseFloat(commercialDetails?.amount_rem_to_oem).toFixed(2) || ""}
              placeholder="e.g. ₹1,20,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  amount_rem_to_oem: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  amount_rem_to_oem: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 7 */}
          <div title="Date of Payment Remitted to OEM" className="flex-1 w-[200px] ">
           
              <label className="font-bold">
                Date of Payment Remitted to OEM
              </label>
       
            <input
              type="date"
              min="2000-01-01"
              value={commercialDetails?.date_of_rem_to_oem}
              placeholder="Payment Remitted to OEM"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  date_of_rem_to_oem: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  date_of_rem_to_oem: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 8 */}
          <div title="Exchange Rate of Remittence" className="flex-1 w-[200px] ">
            
              <label className="font-bold">Exchange Rate of Remittence</label>
            
            <input
              type="number"
              value={commercialDetails?.exchange_rate_rem}
              min={0}
              maxLength={25}
              placeholder="Exchange Rate of Remittence"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  exchange_rate_rem: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  exchange_rate_rem: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 9 */}
          <div title="Date of Custom Duty Payment" className="flex-1 w-[200px] ">
            
              <label className="font-bold">Date of Custom Duty Payment</label>
            
            <input
              type="date"
              min="2000-01-01"
              value={commercialDetails?.custom_duty_payment}
              placeholder="Exchange Rate of Remittence"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  custom_duty_payment: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  custom_duty_payment: e.target.value,
                });
              }}
            />
          </div>
                   {/* Customer Dutey Date of Clearance */}
        <div
          title="Enter Custom Duty Date of Clearance"
          className="flex-1 w-[200px] "
        >
          <label className="font-bold">
            Custom Duty Date of Clearance
            
          </label>

          <input
            type="date"
            min="1990-01-01"
            max={new Date()}
            value={formatDateInput(
              assetBasicDetails?.custom_duty_date_of_clearance
            )}
            placeholder=""
            onChange={(e) => {
              setAssetBasicDetails({
                ...assetBasicDetails,
                custom_duty_date_of_clearance: e.target.value,
              });
              {
                setUpdateAssetDetails({
                  ...updateAssetDetails,
                  custom_duty_date_of_clearance: e.target.value,
                });
              }
            }}
            className="text-black   focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
          />
        </div>
          {/* Item 10 */}
          <div  title="Ex Work Price" className="flex-1 w-[200px] ">
            
              <label className="font-bold">Ex Work Price ($)</label>
            
            <input
              type="number"
              min={0}
              maxLength={14}
              value={commercialDetails?.exworks_price}
              placeholder="Ex Work Price"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  exworks_price: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  exworks_price: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 11 */}
          <div title="CIF Charges" className="flex-1 w-[200px] ">
            
              <label className="font-bold">CIF Charges ($) </label>
            
            <input
              type="number"
              min={0}
              value={commercialDetails?.cif_charges}
              placeholder="0"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  cif_charges: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  cif_charges: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 12 */}
          <div title="Total Cost"  className="flex-1 w-[200px] ">
            
              <label className="font-bold">Total Cost ($) </label>
            
            <input
              type="number"
              min={0}
              value={commercialDetails?.total_cost}
              placeholder="e.g. $ 1,20,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  total_cost: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  total_cost: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 13 */}
          <div  title="BOE No." className="flex-1 w-[200px] ">
            
              <label className="font-bold">BOE Number</label>
            
            <input
              type="text"
              maxLength={30}
              min={0}
              value={commercialDetails?.boe_no}
              placeholder="e.g. 12345"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  boe_no: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  boe_no: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 14 */}
          <div  title="Custom Duty Value" className="flex-1 w-[200px] ">
            
              <label className="font-bold"> Custom Duty Value (₹) </label>
            
            <input
              type="number"
              maxLength={30}
              min={0}
              value={commercialDetails?.custom_duty_value}
                placeholder="e.g. ₹42,83,472/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  custom_duty_value: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  custom_duty_value: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 15 */}
          <div title="GST Amount" className="flex-1 w-[200px] ">
            
              <label className="font-bold">GST Amount (₹) </label>
            
            <input
              type="number"
              min={0}
              value={commercialDetails?.gst_amount}
             placeholder="e.g. ₹2,00,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  gst_amount: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  gst_amount: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 16 */}
          <div  title="Ex Rate as per BOE" className="flex-1 w-[200px] ">
            
              <label className="font-bold">Ex Rate as per BOE</label>
            
            <input
              type="number"
              min={0}
              value={commercialDetails?.exrate_boe}
              placeholder="Ex Rate as per BOE"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  exrate_boe: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  exrate_boe: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 17 */}
          <div title="Clearing Charges" className="flex-1 w-[200px] ">
            
              <label className="font-bold">Clearing Charges (₹) </label>
            
            <input
              type="number"
              min={0}
              value={commercialDetails?.clearing_charges}
               placeholder="e.g. ₹ 70,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  clearing_charges: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  clearing_charges: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 18 */}
          <div title="Custom House Agent Charges" className="flex-1 w-[200px] ">
         
              <label className="font-bold">CHA Charges (₹)  </label>
         
            <input
              type="text"
              value={commercialDetails?.cha_charges}
              placeholder="e.g. ₹20,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  cha_charges: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  cha_charges: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 19 */}
          <div  title="Transportation charges upto yard" className="flex-1 w-[200px] ">
           
              <label className="font-bold">
                Transportation charges upto yard  (₹) 
              </label>
            
            <input
              type="text"
              min={0}
              value={commercialDetails?.transportation_charges}
              placeholder="e.g. ₹20,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  transportation_charges: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  transportation_charges: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 20 */}
          <div title="Country / Port of dispatch" className="flex-1 w-[200px] ">
            
              <label className="font-bold">Country / Port of dispatch</label>
            
            <input
              type="text"
              value={commercialDetails?.port_of_dispatch}
              placeholder="Country / Port of dispatch"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  port_of_dispatch: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  port_of_dispatch: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 21 */}
          <div title="Port of Clearance" className="flex-1 w-[200px] ">
            
              <label className="font-bold"> Port of clearance </label>
            
            <input
              type="text"
              value={commercialDetails?.port_of_clearance}
              placeholder="e.g. Chennai"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  port_of_clearance: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  port_of_clearance: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 22 */}
          {/* <div className="flex-1 w-[200px] ">
              <label className="font-bold">  Under which GST machine cleared  </label>
              <input
                 type="text"
                 value={commercialDetails?.port_of_clearance}
                placeholder="Under which GST machine cleared"
               className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"

                onChange={(e) => {setCommercialDetails({...commercialDetails, port_of_clearance: e.target.value})
                setUpdateAssetCommercial({
                  ...updateAssetCommercial, "port_of_clearance": e.target.value
                })
              }}
              />
            </div> */}
          {/* Item 23 */}
          <div title="Period of Insurance" className="flex-1 w-[200px] ">
            
              <label className="font-bold"> Period of Insurance </label>
            
            <input
              type="text"
              value={commercialDetails?.period_of_insurance}
              placeholder=" Port of Insurance"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  period_of_insurance: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  period_of_insurance: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 24 */}
          <div title="Renewal Date" className="flex-1 w-[200px] ">
            
              <label className="font-bold">Renewal Date </label>
            
            {/* <input
              type="date"
              min="2000-01-01"
              value={formatDateInput(commercialDetails?.insurance_renewal) || ""}
              placeholder="Renewal Date"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  insurance_renewal: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  insurance_renewal: e.target.value,
                });
              }}
            /> */}
            <input type="date"
            min="2000-01-01"
            value={commercialDetails?.insurance_renewal || "" }
            className="w-full text-xs font-medium rounded-md shadow-none p-2 bg-light-4"
            
            onChange={(e) => {
              setCommercialDetails({
                ...commercialDetails,
                insurance_renewal: e.target.value,
              });
              setUpdateAssetCommercial({
                ...updateAssetCommercial,
                insurance_renewal: e.target.value,
              });
            }}
            />
            
          </div>
          {/* Item 25 */}
          <div title="Total Landed Cost" className="flex-1 w-[200px] ">
          
              <label className="font-bold">Total Landed Cost  (₹) </label>
            
            <input
              type="text"
              min={0}
              value={commercialDetails?.total_landed_cost}
                 placeholder="e.g. ₹ 2,00,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  total_landed_cost: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  total_landed_cost: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 25 */}
          {/* {console.log(commercialDetails?.total_landed_cost_with_gst)} */}
          <div  title="Total Landed Cost (including GST)"  className="flex-1 w-[200px] ">
            
              <label className="font-bold"> Total Landed Cost (including GST) (₹)  </label>
            
            <input
              type="text"
              value={commercialDetails?.total_landed_cost_with_gst}
                placeholder="e.g. ₹ 2,00,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  total_landed_cost_with_gst: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  total_landed_cost_with_gst: e.target.value,
                });
              }}
            />
          </div>
          <div  title="Valu of Sold Asset" className="flex-1 w-[200px] ">
            
              <label className="font-bold">Value of sold asset (₹)  </label>
            
            <input
              type="number"
              min={0}
              value={commercialDetails?.value_of_sold_asset}
                placeholder="e.g. ₹ 2,00,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  value_of_sold_asset: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  value_of_sold_asset: e.target.value,
                });
              }}
            />
          </div>
          <div  title="Sold to customer name"  className="flex-1 w-[200px] ">
            
              <label className="font-bold">Sold to customer name  </label>
            
            <input
              type="text"
              
              maxLength={25}
              value={commercialDetails?.sold_customer_name}
                placeholder="e.g. TATA"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  sold_customer_name: e.target.value,
                });
                setUpdateAssetCommercial({
                  ...updateAssetCommercial,
                  sold_customer_name: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </>
    </>
  );
}

export default CommercialDetails;

// <div className="p-10 mt-2 text-xs gap-4 bg-white dark:bg-slate-900 grid grid-cols-1 md:grid-cols-4 ">
// {/* Used or New */}
{
  /* <div className="flex-1 w-[200px] ">
    <label className="font-bold">Used/New</label>
    <select
      type="text"
      value={assetConfigDetails?.used_or_new}
      placeholder="used or new"
      class="w-[200px] h-8 rounded border-gray-200
        focus:outline-none
        focus:border-gray-200 focus:ring-0
        text-xs"
      onChange={(e) =>
        setAssetConfigDetails({
          ...assetConfigDetails,
          used_or_new: e.target.value,
        })
      }
    >
    <option value="used">Used</option>
    <option value="new">New</option>
  </select> */
}
// </div>
// {/* Ansi/ce */}
// <div className="flex-1 w-[200px] ">
//   <label className="font-bold">ANSI/CE</label>
//   <select
//     type="text"
//     value={assetConfigDetails?.ansi_or_new}
//     placeholder="ansi or new"
//     class="w-[200px] h-8 rounded border-gray-200
//       focus:outline-none
//       focus:border-gray-200 focus:ring-0
//       text-xs"
//     onChange={(e) =>
//       setAssetConfigDetails({
//         ...assetConfigDetails,
//         used_or_new: e.target.value,
//       })
//     }
//   >
//     <option value="used">ANSI</option>
//     <option value="new">CE</option>
//   </select>
// </div>
// {/* Ship Owner */}
// <div className="flex-1 w-[200px] ">
//   <label className="font-bold">Machine Ownership Ship Type</label>
//   <select
//     type="text"
//     value={assetConfigDetails?.machine_ownership_type}

//     class="w-[200px] h-8 rounded border-gray-200
//       focus:outline-none
//       focus:border-gray-200 focus:ring-0
//       text-xs"
//       onChange={(e) => setAssetConfigDetails({...assetConfigDetails, machine_ownership_type: e.target.value})}
//   >
//    <option value="rental">Rental</option>
//   <option value="sands">S and S</option>
//   <option value="retail">Retail</option>
//   <option value="sold">Sold</option>
//   </select>
// </div>
// {/* DIESEL/BATTERY */}
// <div className="flex-1 w-[200px] ">
//   <label className="font-bold">DIESEL/BATTERY</label>
//   <select
//     type="text"
//     value={assetConfigDetails?.battery_type}

//     class="w-[200px] h-8 rounded border-gray-200
//       focus:outline-none
//       focus:border-gray-200 focus:ring-0
//       text-xs"
//       onChange={(e) => setAssetConfigDetails({...assetConfigDetails, battery_type: e.target.value})}
//   >
//   <option value="diesel">Diesel</option>
//   <option value="battery">Battery</option>
//   </select>
// </div>
// {/* Engine Sr No */}
// <div className="flex-1 w-[200px] ">
//   <label className="font-bold">ENGINE SR.NO</label>
//   <select
//     type="text"
//     value={assetConfigDetails.engine_serial_no}
//     placeholder="Engine sr.no"
//     class="w-[200px] h-8 rounded border-gray-200
//       focus:outline-none
//       focus:border-gray-200 focus:ring-0
//       text-xs"
//       onChange={(e) => setAssetConfigDetails({...assetConfigDetails, engine_serial_no: e.target.value})}
//   >
//   <option value="diesel">Diesel</option>
//   <option value="battery">Battery</option>
//   </select>
// </div>
// {/* 2WD / 4WD */}
// <div className="flex-1 w-[200px] ">
//   <label className="font-bold">2WD / 4WD</label>
//   <select
//     type="text"
//     value={assetConfigDetails.two_or_four_wd}
//     class="w-[200px] h-8 rounded border-gray-200
//       focus:outline-none
//       focus:border-gray-200 focus:ring-0
//       text-xs"
//       onChange={(e) => setAssetConfigDetails({...assetConfigDetails, two_or_four_wd: e.target.value})}

//   >
//   <option value="2WD">2WD</option>
//   <option value="4WD">4WD</option>
//   </select>
// </div>
// {/* Others */}
// <div className="flex-1 w-[200px] ">
// <label className="font-bold">Accessories (if any)</label>
// <input
//   type="text"
//   value={assetConfigDetails?.accessories}

//   placeholder="Accessories"
//   class="w-[200px] h-8 rounded border-gray-200
//       focus:outline-none
//       focus:border-gray-200 focus:ring-0
//       text-xs"
//       onChange={(e) => setAssetConfigDetails({...assetConfigDetails, accessories: e.target.value})}

// />
// </div>
// {/* Tires */}
// <div className="flex-1 w-[200px] ">
//   <label className="font-bold">Tires</label>
//   <select
//     type="text"
//     value={assetConfigDetails.tyres}

//     class="w-[200px] h-8 rounded border-gray-200
//       focus:outline-none
//       focus:border-gray-200 focus:ring-0
//       text-xs"
//       onChange={(e) => setAssetConfigDetails({...assetConfigDetails, tyres: e.target.value})}
//   >
//     <option> Airfilled </option>
//     <option> Foam </option>
//   </select>
// </div>
// </div>
