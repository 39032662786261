import React, { useEffect, useState } from "react";
import Select from "react-select";
import { setBaseUrl } from "../../../config";
import { AssetDropdown } from "./AssetDropdown";

const NewForm = ({
  title,
  type,
  placeholder,
  keyValueType,
  newLogistic,
  setNewLogistic,
  RSO_No,
  Asset_No,
  getRsoId,
  dataFetched,
  setDataFetched,
  formErrors,
  setFormErrors,
  selectedAssets, setSelectedAssets
}) => {
  // const [dataFetched, setDataFetched] = useState(false);

  const [filteredOptions, setFilteredOptions] = useState(RSO_No);
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      {title === "Mob Demob State" ? (
        <>
          <div className="flex justify-between items-center   text-[10px]">
            <h1 className="">{title}</h1>
            <select
              className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
              onChange={(e) => {
                setNewLogistic({
                  ...newLogistic,
                  [keyValueType]: e.target.value,
                });
              }}
              value={newLogistic[keyValueType]}
            // onClick={() => getRsoId()}
            >
              <option value="">Select</option>
              <option value="mob">Mob</option>
              <option value="demob">Demob</option>
            </select>
          </div>
        </>
      ) : title === "Select Multiple RSO" ? (
        <>
          <div className="flex justify-between items-center text-[10px]">
            <h1 className="">{title}</h1>

            {/* <Select
              isMulti
              name="select_multiple_rso"
              options={filteredOptions}
              openMenuOnClick={false}
              onMenuOpen={() => {
                if (!dataFetched) {
                  getRsoId();
                  setDataFetched(true);
                }
              }}
              onInputChange={(inputValue) => {
                setSearchTerm(inputValue);

                if (inputValue.length >= 1) {
                  const filtered = RSO_No.filter((rso) =>
                    rso?.label?.toLowerCase().includes(inputValue.toLowerCase())
                  );
                  // console.log(filtered); // Debug filtered options
                  setFilteredOptions(filtered);
                } else {
                  setFilteredOptions(RSO_No);
                }
              }}
              value={filteredOptions.filter((option) =>
                newLogistic.select_multiple_rso?.includes(option.value)
              )} // Ensure correct value structure
              onChange={(selectedOptions) => {
                // console.log(selectedOptions); // Debug selected options
                const rsoValues = selectedOptions.map((option) => option.value);
                setNewLogistic({
                  ...newLogistic,
                  select_multiple_rso: rsoValues,
                });
              }}
              placeholder="Select RSO Number"
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "350px",
                  fontSize: 12,
                }),
                control: (provided, state) => ({
                  ...provided,
                  width: "350px",
                  // height: "30px",
                  border:
                    state.isFocused || state.isHovered
                      ? "none"
                      : "1px solid transparent",
                  borderBottom: "1px solid #D1D5DB",
                  boxShadow: state.isFocused
                    ? "0 0 0 0.2px rgba(156, 163, 175, 0.25)"
                    : formErrors ? '#DB4444' : null,
                  outline: "none",
                  borderRadius: 0,
                  backgroundColor: "#EEE",
                }),
              }}
            /> */}
            <div>
              <Select
                isMulti
                name="select_multiple_rso"
                options={filteredOptions}
                openMenuOnClick={false}
                onMenuOpen={() => {
                  if (!dataFetched) {
                    getRsoId();
                    setDataFetched(true);
                  }
                }}
                onInputChange={(inputValue) => {
                  setSearchTerm(inputValue);

                  if (inputValue.length >= 1) {
                    const filtered = RSO_No.filter((rso) =>
                      rso?.label
                        ?.toLowerCase()
                        .includes(inputValue.toLowerCase())
                    );
                    setFilteredOptions(filtered);
                  } else {
                    setFilteredOptions(RSO_No);
                  }
                }}
                value={filteredOptions.filter((option) =>
                  newLogistic.select_multiple_rso?.includes(option.value)
                )}
                onChange={(selectedOptions) => {
                  const rsoValues = selectedOptions.map(
                    (option) => option.value
                  );
                  setNewLogistic({
                    ...newLogistic,
                    select_multiple_rso: rsoValues,
                  });
                  // Clear form errors when an RSO number is added
                  if (selectedOptions.length > 0) {
                    setFormErrors(false);
                  }
                }}
                placeholder="Select RSO Number"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: "350px",
                    fontSize: 12,
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    width: "350px",
                    border: formErrors
                      ? "1px solid #DB4444" // Red border for error state
                      : state.isFocused || state.isHovered
                        ? "none"
                        : "1px solid transparent",
                    borderBottom: formErrors
                      ? "2px solid #DB4444" // Highlight error with thicker border
                      : "1px solid #D1D5DB",
                    boxShadow: state.isFocused
                      ? "0 0 0 0.2px rgba(156, 163, 175, 0.25)"
                      : "none",
                    outline: "none",
                    borderRadius: 0,
                    backgroundColor: formErrors ? "#FFE5E5" : "#EEE", // Light red background for error
                  }),
                }}
              />
              {formErrors && (
                <span style={{ color: "#DB4444", fontSize: "12px" }}>
                  Please add at least one RSO number.
                </span>
              )}
            </div>
          </div>
        </>
      ) : keyValueType === "mob_as_per_invoice" || keyValueType === "demob_as_per_invoice" ? <>
        <div className="flex justify-between items-center text-[10px]">
          <h1 className="">{title}</h1>
          <input
            type={type || "text"}
            placeholder={placeholder || ""}
            value={newLogistic[keyValueType]}
            className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
            onChange={(e) => {
              setNewLogistic({
                ...newLogistic,
                [keyValueType]: e.target.value,
              });
            }}
            disabled
          />
        </div>

      </> : title === "Asset Number" ? (
        <>
          <div className="flex justify-between items-center text-[10px]">
            <h1 className="">{title}</h1>
 

            <AssetDropdown
        assets={Asset_No}
        selectedAssets={selectedAssets}
        setSelectedAssets={setSelectedAssets}
      />


            {/* <Select
              isMulti
              name="asset_no"
              options={filteredOptions}
              openMenuOnClick={false}
              onMenuOpen={() => {
                if (!dataFetched) {
                  getRsoId();
                  setDataFetched(true);
                }
              }}
              onInputChange={(inputValue) => {
                setSearchTerm(inputValue);

                if (inputValue.length >= 1) {
                  const filtered = Asset_No.filter((asset) =>
                    asset?.label
                      ?.toLowerCase()
                      .includes(inputValue.toLowerCase())
                  );
                  // console.log(filtered); // Debug filtered options
                  setFilteredOptions(filtered);
                } else {
                  setFilteredOptions(Asset_No);
                }
              }}
              value={filteredOptions.filter((option) =>
                newLogistic.asset_no?.includes(option.value)
              )} // Ensure correct value structure
              onChange={(selectedOptions) => {
                // console.log(selectedOptions); // Debug selected options
                const assetValues = selectedOptions.map(
                  (option) => option.value
                );
                setNewLogistic({
                  ...newLogistic,
                  asset_no: assetValues,
                });
              }}
              placeholder="Select Asset Number"
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "350px",
                  fontSize: 12,
                }),
                control: (provided, state) => ({
                  ...provided,
                  width: "350px",
                  // height: "30px",
                  border:
                    state.isFocused || state.isHovered
                      ? "none"
                      : "1px solid transparent",
                  borderBottom: "1px solid #D1D5DB",
                  boxShadow: state.isFocused
                    ? "0 0 0 0.2px rgba(156, 163, 175, 0.25)"
                    : null,
                  outline: "none",
                  borderRadius: 0,
                  backgroundColor: "#EEE",
                }),
              }}
            /> */}
          </div>
          {/* <>
            <div className="flex justify-between items-center text-[10px]">
              <h1 className="">{title}</h1>

              <Select
                isMulti
                name="asset_no"
                options={filteredOptions}
                openMenuOnClick={false}
                onMenuOpen={() => {
                  if (!dataFetched) {
                    getRsoId();
                    setDataFetched(true);
                  }
                }}
                onInputChange={(inputValue) => {
                  setSearchTerm(inputValue);

                  if (inputValue.length >= 1) {
                    const filtered = Asset_No.filter((asset) =>
                      asset?.label
                        ?.toLowerCase()
                        .includes(inputValue.toLowerCase())
                    );
                    // console.log(filtered); // Debug filtered options
                    setFilteredOptions(filtered);
                  } else {
                    setFilteredOptions(Asset_No);
                  }
                }}
                value={filteredOptions.filter(
                  (option) => newLogistic.asset_no?.includes(option.value) // Ensure correct value structure
                )}
                onChange={(selectedOptions) => {
                  console.log(selectedOptions); // Debug selected options
                  // If assets are selected, we append the selected values to the existing `asset_no` array
                  const selectedValues = selectedOptions.map(
                    (option) => option.value
                  );

                  // Update the `asset_no` array with selected values, ensuring no duplicates
                  const updatedAssetNo = [
                    ...new Set([...newLogistic.asset_no, ...selectedValues]),
                  ];

                  // Set the updated asset_no array to newLogistic
                  setNewLogistic({
                    ...newLogistic,
                    asset_no: updatedAssetNo,
                  });
                }}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: "350px",
                    fontSize: 12,
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    width: "350px",
                    height: "30px",
                    border:
                      state.isFocused || state.isHovered
                        ? "none"
                        : "1px solid transparent",
                    borderBottom: "1px solid #D1D5DB",
                    boxShadow: state.isFocused
                      ? "0 0 0 0.2px rgba(156, 163, 175, 0.25)"
                      : null,
                    outline: "none",
                    borderRadius: 0,
                    backgroundColor: "#EEE",
                  }),
                }}
              />
            </div>
          </> */}
        </>
      ) : (
        <>
          <div className="flex justify-between items-center text-[10px]">
            <h1 className="">{title}</h1>
            <input
              type={type || "text"}
              placeholder={placeholder || ""}
              min={type === "number" && 0}
              maxLength={25}
              value={newLogistic[keyValueType]}
              className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
              onChange={(e) => {
                setNewLogistic({
                  ...newLogistic,
                  [keyValueType]: e.target.value,
                });
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default NewForm;
