import React, { useEffect, useRef, useState } from "react";
import { setBaseUrl } from "../../../../config";
import {
  capitalizeFirstLetter,

} from "../../AssetDetails/Tabs/FormatDate";
import * as XLSX from "xlsx";
import moment from "moment";


const Logs = ({ leaseId }) => {
  const [logData, setLogData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldNameMapping = {
    id: "ID",
    site_location: "Site Location",
    action_time: "Action Time",
    act_by: "Acted By",
    action_type: "Action Type",
    fields_are: "Fields Changed",
    lease_id: "Lease ID",
    maintenance_id: "Maintenance ID",
    operator_id: "Operator ID",
    user_id: "User ID",
    attachments: "Attachments",
    contract_value: "Contract Value",
    currency: "Currency",
    customer: "Customer PO Number",
    fild_id: "Field ID",
    lease_status: "Lease Status",
    lease_type: "Lease Type",
    normal_amount: "Normal Amount",
    odoo_order_id: "RSO ID",
    overtime_amount: "Overtime Amount",
    photos: "Photos",
    reimbursements: "Reimbursements",
    rental_end_date: "Rental End Date",
    rental_extended_date: "Rental Extended Date",
    rental_start_date: "Rental Start Date",
    sale_person: "Sale Person",
    total_claimable_amount: "Total Claimable Amount",
    transportation_charge: "Transportation Charges",
    custom_duty_date_of_clearance: "Date of Clearance",
    purchased_from: "Purchased From",
    device_no: "Device Number",
    asset_id: "Asset ID",
    asset_no: "Asset Number",
    customer_po_no: "Customer PO Number",
    model: "Model",
    serial_no: "Serial Number",
    installation_date: "Installation Date",
    yom: "Year of Manufacture",
    hieght_machine: "Height of Machine",
    capacity: "Capacity",
    category: "Category",
    total_working_days: 'Working Days',
    monthly_rental_amount: 'Monthly Rent Amount',
    rental_location: 'Rental Location',
  };

  const hasLogFetch = useRef(false)

  const getUserLogData = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${setBaseUrl}/dashboard/get-log-data-user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "lease-id": leaseId,
        },
      });
      const data = await res.json();
      setLogData(data);
    } catch (error) {
      console.error("Error fetching log data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(!hasLogFetch.current){
      hasLogFetch.current = true;
      getUserLogData();
    }
  }, []);




  
  const parseFields = (fields) => {
    try {
      const parsedFields = JSON.parse(fields);
      return Object.entries(parsedFields)
        .filter(([key]) => key !== "id") // Exclude the `id` field
        .map(([key, value]) => ({
          fieldName: fieldNameMapping[key] || key,
          fieldValue: typeof value === "object" ? JSON.stringify(value) : value,
        }));
    } catch (error) {
      console.error("Error parsing fields:", error);
      return [];
    }
  };



    const handleExportExcel = () => {
      const formattedData = logData.map((data) => {
        const fields = parseFields(data.fields_are || "{}");
        const fieldDetails = fields.map(
          (field) => `${field.fieldName}: ${JSON.stringify(field.fieldValue)}`
        );
  
        return {
          Time: data.action_time,
          "Act By": capitalizeFirstLetter(data.act_by),
          "Action Type": capitalizeFirstLetter(data.action_type),
          "Fields Changed": fields.map((field) => field.fieldName).join(", "),
          "Updated Data": fieldDetails.join("\n"),
        };
      });
  
      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "User Log Data");
      XLSX.writeFile(workbook, "Lease Logs.xlsx");
    };
  

  return (
    <div className="min-h-[50vh] text-xs py-2">
      <div className="max-w-7xl mx-auto px-2">
        <div className="mt-3 bg-white shadow-md p-5">
        <div className="flex justify-end mb-4">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              onClick={handleExportExcel}
            >
              Export to Excel
            </button>
          </div>

          
          {loading ? (
            <div className="flex justify-center items-center py-10">
              <div className="animate-spin rounded-full h-8 w-8"></div>
            </div>
          ) : (
            <div className="overflow-x-auto text-[10px] border">
              <table className="min-w-full border-collapse divide-y">
                <thead className="divide-y sticky top-0 bg-light-4">
                  <tr>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Time
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Act By
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Action Type
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Fields Changed
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Updated Data
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {logData.length > 0 ? (
                    logData.map((data, index) => {
                      const fields = parseFields(data.fields_are || "{}");
                      return (
                        <tr
                          key={index}
                          className="hover:bg-gray-100 transition duration-200 ease-in-out"
                        >
                          <td className="py-3 px-4 text-dark-6 border-b">
                          {moment(data?.action_time).format("DD-MM-YYYY ")}

                          </td>
                          <td className="py-3 px-4 text-dark-6 border-b">
                            {capitalizeFirstLetter(data.act_by)}
                          </td>
                          <td className="py-3 px-4 text-dark-6 border-b">
                            {capitalizeFirstLetter(data.action_type)}
                          </td>
                          <td className="py-3 px-4 text-dark-6 border-b">
                            <ul className="list-disc ml-4">
                              {fields.map((field, i) => (
                                <li key={i}>{field.fieldName}</li>
                              ))}
                            </ul>
                          </td>
                          <td className="py-3 px-4 text-dark-6 border-b">
                            <ul className="list-disc ml-4">
                              {fields.map((field, i) => (
                                <li key={i}>
                                  <strong>{field.fieldName}:</strong> {field.fieldValue}
                                </li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="5"
                        className="py-4 px-4 text-center text-dark-6"
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Logs;
