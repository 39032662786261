import React from "react";
import { FiMenu, FiHome, FiBarChart2, FiSettings, FiLogOut } from "react-icons/fi";
import { motion } from "framer-motion";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

const sidebarVariants = {
  open: { width: "16rem", transition: { duration: 0.3 } },
  closed: { width: "4rem", transition: { duration: 0.3 } },
};

const SalesSidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {

  const navigate = useNavigate()
  const handleLogout = () => {
    sessionStorage.clear()
    navigate("/sales-services/login")
  }
  return (
    <motion.div
      variants={sidebarVariants}
      animate={isSidebarOpen ? "open" : "closed"}
      className="bg-white shadow-lg h-screen flex flex-col fixed left-0 top-0 z-50"
    >
      {/* Sidebar Header */}
      <div className="flex items-center justify-between p-4 border-b">
        {isSidebarOpen &&  <div className={`flex justify-start items-center transition-all translate-x-1 duration-300 ease-in-out  w-[300px] `}>
              <img className="w-8  mb-1" src={logo} alt="logo" />
              <p className=" font-semibold  text-dark-6 text-base">
                Asset <span className="font-semibold">Tracker</span>
              </p>
            </div>}
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="p-2 text-gray-600 hover:bg-gray-200 rounded transition"
        >
          <FiMenu size={24} />
        </button>
      </div>

      {/* Sidebar Links */}
      <nav className="mt-4 flex flex-col space-y-2">
        {[
          { icon: <FiHome size={20} />, label: "Home" },
          // { icon: <FiBarChart2 size={20} />, label: "Reports" },
          // { icon: <FiSettings size={20} />, label: "Settings" },
        ].map((item, index) => (
          <div
            key={index}
            className="flex items-center space-x-3 p-3 hover:bg-gray-100 cursor-pointer transition-all duration-200"
          >
            {item.icon}
            {isSidebarOpen && <span className="text-sm text-gray-700">{item.label}</span>}
          </div>
        ))}

        {/* Logout Button */}
        <div
          className="flex items-center space-x-3 p-3 text-red-600 hover:bg-red-100 cursor-pointer transition-all duration-200 mt-auto"
        >
          <FiLogOut size={20} />
          {isSidebarOpen && <span className="text-sm" onClick={handleLogout}>Logout</span>}
        </div>
      </nav>
    </motion.div>
  );
};

export default SalesSidebar;
