// import React, { useEffect, useRef, useState } from 'react'
// import { useParams } from 'react-router-dom';
// import { setBaseUrl } from '../../config';
// import Layout from '../../components/Layout';

// const LogisticsDetails = () => {
//     const { id } = useParams();
//       const [formData, setFormData] = useState([]);
// const hasGetLogistics = useRef(false)


//       const getLogisticsData = async () => {
//         try {
//           const res = await fetch(`${setBaseUrl}/lease/get-logistic-id`, {
//             headers: {
//               "Content-Type": "application/json",
//               "x-access-tokens": sessionStorage.getItem("token"),
//               id: id,
//             },
//           });
    
//           if (res.ok) {
//             const data = await res.json();
//             setFormData(data); // Initialize formData with fetched data
//           } else {
//             console.error("Failed to fetch logistics data.");
//           }
//         } catch (error) {
//           console.error("Error fetching logistics data:", error);
//         }
//       };


//        useEffect(() => {
//           if (!hasGetLogistics.current) {
//             hasGetLogistics.current = true;
//             getLogisticsData();
//           }
//         }, []);

//         console.log(formData)

//   return (
//     <Layout>LogisticsDetails</Layout>
//   )
// }

// export default LogisticsDetails

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { setBaseUrl } from '../../config';
import Layout from '../../components/Layout';

const LogisticsDetails = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState(null);
    const hasGetLogistics = useRef(false);
const navigate = useNavigate()
    const getLogisticsData = async () => {
        try {
            const res = await fetch(`${setBaseUrl}/lease/get-logistic-id`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-tokens": sessionStorage.getItem("token"),
                    id: id,
                },
            });

            if (res.ok) {
                const data = await res.json();
                setFormData(data);
            } else {
                console.error("Failed to fetch logistics data.");
            }
        } catch (error) {
            console.error("Error fetching logistics data:", error);
        }
    };

    useEffect(() => {
        if (!hasGetLogistics.current) {
            hasGetLogistics.current = true;
            getLogisticsData();
        }
    }, []);

    if (!formData) {
        return <Layout><p className="text-center text-xs text-gray-500">Loading logistics details...</p></Layout>;
    }

    return (
        <Layout>
            <div className="max-w-4xl text-xs mx-auto p-6 bg-white shadow-lg rounded-lg">
                <h2 className="text-md font-semibold mb-4">Logistics Details</h2>
                <table className="w-full border-collapse border border-gray-300">
                    <tbody>
                        {Object.entries(formData).map(([key, value]) => (
                            <tr key={key} className="border-b text-xs border-gray-300">
                                <td className="p-3 font-medium text-gray-700 capitalize">{key.replace(/_/g, ' ')}</td>
                                <td className="p-3 text-gray-600">{Array.isArray(value) ? value.join(', ') : value || 'N/A'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex justify-between gap-2">
                    <button className="buttons" onClick={() => navigate(-1)} >Back</button>
                    <button className="buttons bg-blue-500 text-white" onClick={() => navigate(`/update-logistics/${id}`)} >Edit</button>
                </div>
            </div>
        </Layout>
    );
};

export default LogisticsDetails;
