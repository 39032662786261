import moment from "moment";
import React from "react";

const Attendance = ({ attendance }) => {
  if (!attendance || !Array.isArray(attendance) || attendance.length === 0)
    return <p>No Attendance found</p>;

  return (
    <div className="bg-white text-dark-6 dark:bg-gray-900 p-4 rounded-[2px] shadow-md">
      <h2 className="text-xx font-bold mb-4">Attendance</h2>
      <table className="w-full text-xs">
        <thead className="text-dark-6 text-left">
          <tr>
            <th className="px-4 py-2 border-b border-gray-300 w-[105px]">
              Date
            </th>
            <th className="px-4 py-2 border-b border-gray-300">Time In</th>
            <th className="px-4 py-2 border-b border-gray-300">Time Out</th>
          </tr>
        </thead>
        <tbody className="text-dark-600 h-[100px] overflow-y-auto">
          {attendance.map((log, idx) => (
            <tr key={idx} className="hover:bg-gray-50">
              <td className="px-4 py-2 border-b border-gray-300 w-[105px]">
                {moment(log.in).format("DD-MM-YYYY")}
              </td>
              <td className="px-4 py-2 border-b border-gray-300">
                {moment(log.in).format("HH:mm")}
              </td>
              <td className="px-4 py-2 border-b border-gray-300">
                {moment(log.out).format("HH:mm")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Attendance;
