import React, { useState } from "react";
import MachineForm from "./MachineForm";
import { useGetAsset, useGetOperator } from "../api/newLeaseApi";
import { setBaseUrl } from "../../../../config";

const NewMachine = ({
  count,
  setCount,
  machineData,
  setMachineData,
  selectedOperator,
  setSelectedOperator,
  // formErrors,
  // setFormErrors,
  formErrorMachine, 
  setFormErrorMachine
}) => {
  const { assetArray } = useGetAsset(`${setBaseUrl}/asset/get_all`);
  const { operatorArray } = useGetOperator(`${setBaseUrl}/operator/get_all`);

  const [error, setError] = useState([]);

  const [overtimeRate, setOvertimeRate] = useState([0, 0]);

  const removeMachine = (index) => {
    // Remove machine at the specified index
    const updatedMachines = machineData.filter((_, idx) => idx !== index);
    setMachineData(updatedMachines);
    setCount((prev) => prev - 1);
  };

  let normalRent = 0;
  // let OvertimeRate = 0

  const NormalHourlyRent = (
    monthly_rental_amount,
    nominal_hours_per_day,
    total_working_days,
    index
    // setMachineData
  ) => {
    const HourlyRent =
      monthly_rental_amount / (nominal_hours_per_day * total_working_days);
    normalRent = HourlyRent;
    // setMachineData({ ...machineData, normal_rate: HourlyRent})

    return HourlyRent;
  };

  // const OvertimeHourlyRent = (normalRent, overtime_rate) => {
  //   const OvertimeRent = normalRent + overtime_rate;
  //   // setMachineData({ ...machineData, overtime_rate: OvertimeRent})
  //   OvertimeRate = OvertimeRent
  //   return OvertimeRent
  // }
  const OvertimeHourlyRent = (normalRentValue, overtime_rate, index) => {
    if (!normalRentValue || !overtime_rate) {
      return 0; // Avoid calculation errors if inputs are missing
    }
    console.log(normalRentValue, index, overtime_rate);

    return (normalRentValue * overtime_rate) / 100;

    //   const OvertimeRentCal = machineData.map((machine, idx) => {
    //     if (idx === index) {
    //       setOvertimeRate((prev) => [, overtime_rate]);
    //       return {
    //         ...machine,
    //         overtime_rate: (
    //           (parseFloat(normalRentValue) * parseFloat(overtime_rate)) /
    //           100
    //         ).toFixed(2),
    //       };
    //     }
    //     return machine;
    //   }
    // )
    //     const OvertimeRent =
    //       (parseFloat(normalRentValue) * parseFloat(overtime_rate)) / 100;
    //     setOvertimeRate(OvertimeRent); // Update state

    //     const updatedMachineData = machineData.map((machine, idx) =>
    //       idx === index
    //         ? { ...machine, overtime_rate: OvertimeRent.toFixed(2) }
    //         : machine
    //     );
    //     setMachineData(updatedMachineData); // Update state
    // //
    //     return OvertimeRentCal;
  };

  return (
    <div>
      {count > 0 && <h1>Total Machines: {count}</h1>}

      {/* Loop through machineData to render forms for each machine */}
      {machineData.length > 0 &&
        machineData?.map((machine, index) => (
          <>
            <div
              key={index}
              className="border p-5 my-2 flex flex-col md:flex-row justify-start gap-10"
            >
              Machine: {index + 1}
              <div className="flex flex-col">
                <MachineForm
                  name="Asset No"
                  type=""
                  label={machine.asset}
                  assetArray={assetArray} // Ensure assetArray is defined
                  machineData={machineData}
                  setMachineData={setMachineData} // Pass it down
                  machineIndex={index} // Pass the current index
                // formErrors={formErrors}
                // setFormErrors={setFormErrors}
                formErrorMachine={formErrorMachine}
                setFormErrorMachine={setFormErrorMachine}
                />

                {/* Select Number of operators */}
                <MachineForm
                  name="Select Number Of Operators"
                  type="number"
                  keyNameType="select_number_of_operators"
                  label={machine?.select_number_of_operators}
                  machineData={machineData}
                  machineIndex={index}
                  setMachineData={setMachineData}
                />
                {/* MachineForm for Operator Name */}

                <MachineForm
                  name="Operator Name"
                  type="text"
                  label={machine.select_operators}
                  operatorArray={operatorArray}
                  machineIndex={index}
                  machineData={machineData}
                  setMachineData={setMachineData}
                  selectedOperator={selectedOperator}
                  setSelectedOperator={setSelectedOperator}
                // formErrors={formErrors}
                // setFormErrors={setFormErrors}
                />

                {/* <MachineForm
                  name="Sale Person"
                  type="text"

                  keyNameType="sale_person"

                  label={machine.sale_person}
                  machineIndex={index}
                  machineData={machineData}
                  setMachineData={setMachineData}
                /> */}

                {/* MachineForm for Rental Start Date */}
                <MachineForm
                  name="Rental Start Date"
                  type="date"
                  keyNameType="rental_start_date"
                  label={machine.rental_start_date}
                  machineData={machineData}
                  machineIndex={index}
                  setMachineData={setMachineData}
                  error={error}
                  setError={setError}
                // formErrors={formErrors}
                // setFormErrors={setFormErrors}
                formErrorMachine={formErrorMachine}
                setFormErrorMachine={setFormErrorMachine}
                />

                {/* Rental End Date */}
                <MachineForm
                  name="Rental End Date"
                  type="date"
                  keyNameType="rental_end_date"
                  label={machine.rental_end_date}
                  machineData={machineData}
                  machineIndex={index}
                  setMachineData={setMachineData}
                  error={error}
                  setError={setError}
                // formErrors={formErrors}
                // setFormErrors={setFormErrors}
                />

                {/* {error ? (
                  <>
                    {" "}
                    <div className="text-red-500">{error}</div>{" "}
                  </>
                ) : (
                  ""
                )} */}
                {error[0] && error[1] === index ? (
                  <div className="text-red-500">{error[0]}</div>
                ) : (
                  ""
                )}

                {/* Rental Location */}
                <MachineForm
                  name="Rental Location"
                  type="text"
                  keyNameType="rental_location"
                  label={machine.rental_location}
                  machineData={machineData}
                  machineIndex={index}
                  setMachineData={setMachineData}
                  error={error}
                  setError={setError}
                // formErrors={formErrors}
                // setFormErrors={setFormErrors}
                />
                {/* 
              <MachineForm
                name="Total Working Days/month"
                type="text"
                keyNameType="total_working_days"
                label={machine.total_working_days}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}
              /> */}
                {/* nominal_hours_per_day */}
                {/*Contract value*/}
                <MachineForm
                  name="Contract Value"
                  type="number"
                  keyNameType="contract_value"
                  label={machine.contract_value}
                  machineData={machineData}
                  machineIndex={index}
                  setMachineData={setMachineData}
                // formErrors={formErrors}
                // setFormErrors={setFormErrors}
                />
              </div>
              <div className="flex flex-col">
                <MachineForm
                  name="Nominal Hrs/day"
                  type="number"
                  keyNameType="nominal_hours_per_day"
                  label={machine.nominal_hours_per_day}
                  machineData={machineData}
                  machineIndex={index}
                  setMachineData={setMachineData}
                // formErrors={formErrors}
                // setFormErrors={setFormErrors}
                />
                {/* Total Working Hours in a month */}

                {/* <MachineForm
                name="Total Working Hrs/month"
                type="text"
                keyNameType="total_working_hours_in_month"
                label={machine.total_working_hours_in_month}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}
              /> */}

                <MachineForm
                  name="Total Working Days/Month"
                  type="number"
                  keyNameType="total_working_days"
                  label={machine.total_working_days}
                  machineData={machineData}
                  machineIndex={index}
                  setMachineData={setMachineData}
                // formErrors={formErrors}
                // setFormErrors={setFormErrors}
                />

                {/* Monthly Rental Amount */}
                <MachineForm
                  name="Monthly Rental Amount"
                  type="number"
                  keyNameType="monthly_rental_amount"
                  label={machine.monthly_rental_amount}
                  machineData={machineData}
                  machineIndex={index}
                  setMachineData={setMachineData}
                // formErrors={formErrors}
                // setFormErrors={setFormErrors}
                formErrorMachine={formErrorMachine}
                setFormErrorMachine={setFormErrorMachine}
                />
                {/* Normal Rate */}
                <MachineForm
                  name="Normal Hourly Rent"
                  type="text"
                  keyNameType="normal_rate"
                  label={machine.normal_rate}
                  machineData={machineData}
                  machineIndex={index}
                  setMachineData={setMachineData}
                  validateData={NormalHourlyRent(
                    machine.monthly_rental_amount,
                    machine.nominal_hours_per_day,
                    machine.total_working_days,
                    index
                    // setMachineData
                  )}
                />

                {/*Overtime Rate*/}
                <MachineForm
                  name="Overtime Rate"
                  type="number"
                  keyNameType="overtime_rate"
                  label={machine.overtime_rate}
                  machineData={machineData}
                  machineIndex={index}
                  setMachineData={setMachineData}
                  OvertimeHourlyRent={OvertimeHourlyRent}
                  normalRent={normalRent}
                  overtimeRate={overtimeRate}
                />

                {/*Expected Revenue Without Overtime*/}
                {/* <MachineForm
                name="Expected Revenue Without Overtime"
                type="text"
                keyNameType="expected_revenue_without_overtime"
                label={machine.expected_revenue_without_overtime}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}
              /> */}
                {/* <MachineForm
                  name="Normal Amount"
                  type="text"
                  keyNameType="normal_amount"
                  label={machine.normal_amount}
                  machineData={machineData}
                  machineIndex={index}
                  setMachineData={setMachineData}
                />
                <MachineForm
                  name="Overtime Amount"
                  type="number"
                  keyNameType="overtime_amount"
                  label={machine.overtime_amount}
                  machineData={machineData}
                  machineIndex={index}
                  setMachineData={setMachineData}
                />

                <MachineForm
                  name="Claimable Amount"
                  type="text"
                  keyNameType="total_claimable_amount"
                  label={machine.total_claimable_amount}
                  machineData={machineData}
                  machineIndex={index}
                  setMachineData={setMachineData}
                /> */}
                <button
                  title="remove the machine from the list"
                  className="border border-red-600 text-red-600 rounded-[2px] shadow-md w-[65px] px-2 py-1 mt-2"
                  onClick={() => removeMachine(index)}
                >
                  Remove
                </button>
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

export default NewMachine;
