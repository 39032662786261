import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
import { LoaderContext } from "../../Context/LoaderContext";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import Document from "../viewer/Document";
import deleteDocument from "../../apis/files/deleteDocument";
import CheckOldOperator from "./CheckOldOperator";
import { useGetOperatorAccount } from "./NewLease/api/newLeaseApi";
import { toast } from "react-toastify";
import Wallet from "./Wallet";
import NewWallet from "./NewWallet";

function NewOperator() {
  const [loader, setLoader] = useContext(LoaderContext);
  const [adhaarChecked, setAdhaarChecked] = useState(false);
  // const [newAdhaar, setNewAdhaar] = useState("");
  const navigate = useNavigate();

  // console.log(newAdhaar)
  let [operatorData, setOperatorData] = useState({
    name: "",
    aadhar_no: "",
    // dob: "", //added Avijit
    // blood_group: "", // avijit
    // family: "", // avijit
    // photo: "", //avijit
    // emergency_mobile: "", //avijit
    joining_date: "",
    leaving_date: "",
    net_inhand_salary: "",
    operator_role: "",
    operator_sale_person: "",
    pf_account_no: "",
    phone_code: "+91",
    phone_no: "",
    ifsc_code: "",
    account_no: "",
    advance_paid_amount: 0,
    wallet_arr: [],
    operator_sale_person_id: ''
  });

  const [phone_number_alert, setPhone_number_alert] = useState(null);
  const [emergency_mobile_alert, setEmergency_mobile_alert] = useState(null);
  const [validAdhaar, setValidAdhaar] = useState(null);

  //target the document input element by using useRef hook
  const documentInputRef = React.useRef(null);

  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [showDocument, setShowDocument] = useState(null);
  const [alreadyUploadedDocuments, setAlreadyUploadedDocuments] = useState([]);

  // const mobile_input_ref = useRef(null);

  // const handlePhoneNo = (e) => {
  //   // Remove non-numeric characters and keep only digits
  //   let phoneValue = e.target.value.replace(/\D/g, "");

  //   // Ensure the phone number is not negative
  //   if (phoneValue.startsWith("-")) {
  //     phoneValue = phoneValue.substring(1); // Remove the negative sign
  //   }

  //   // Limit the phone number to 10 digits
  //   if (phoneValue.length > 10) {
  //     phoneValue = phoneValue.substring(0, 10); // Truncate to 10 digits
  //   }

  //   // Update the operator data with the valid phone number

  //   // Validate the phone number length
  //   let phoneDigitCount = phoneValue.length;
  //   if (phoneDigitCount === 10) {
  //     setOperatorData({
  //       ...operatorData,
  //       phone_no: phoneValue,
  //     });
  //     setPhone_number_alert(null); // Valid phone number
  //   } else {
  //     setPhone_number_alert(
  //       "Phone number must be of 10 digits. You gave: " + phoneDigitCount
  //     );
  //   }
  // };

  const handlePhoneNo = (e) => {
    let phoneValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
  
    if (phoneValue.length > 10) {
      phoneValue = phoneValue.substring(0, 10); // Ensure max length is 10
    }
  
    setOperatorData({
      ...operatorData,
      phone_no: phoneValue,
    });
  
    if (phoneValue.length === 10) {
      setPhone_number_alert(null);
    } else {
      setPhone_number_alert(`Phone number must be 10 digits. You entered: ${phoneValue.length}`);
    }
  };
  
  // Prevent typing non-numeric characters
  const preventNonNumeric = (e) => {
    if (
      !/[0-9]/.test(e.key) && // Allow digits only
      !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(e.key) // Allow navigation keys
    ) {
      e.preventDefault();
    }
  };
  
  // Prevent pasting non-numeric content
  const preventPasteNonNumeric = (e) => {
    let pastedData = e.clipboardData.getData("text");
    if (!/^\d+$/.test(pastedData)) {
      e.preventDefault();
    }
  };

  const handleEmergencyPhone = (e) => {
    setOperatorData({
      ...operatorData,
      emergency_mobile: e.target.value,
    });
    let phoneDigitCount = e.target.value.length;
    if (phoneDigitCount === 10) {
      setEmergency_mobile_alert(null);
    } else {
      setEmergency_mobile_alert(
        "Phone number must be of 10 digits. you gave: " + phoneDigitCount
      );
    }
  };

  // const handleAdhaarNo = (e) => {
  //   setOperatorData({
  //     ...operatorData,
  //     aadhar_no:  newAdhaar,
  //   });
  //   let aadhar_no_value = e.target.value;
  //   let aadhar_no_value_length = aadhar_no_value.length;
  //   var expr =
  //     /^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/;
  //   if (expr.test(aadhar_no_value)) {
  //     setValidAdhaar(null);
  //   } else {
  //     setValidAdhaar(
  //       "Adhaar number must contain 12 digits. You gave>> " +
  //         aadhar_no_value_length
  //     );
  //   }
  // };

  //invoke the document upload input on button click
  const handleDocumentUploadInput = (e) => {
    documentInputRef.current.click();
  };
  //set the document to send to the server
  const handleDocumentChange = (e) => {
    setUploadedDocument(e.target.files);
    let docFiles = e.target.files;
    let docArray = [];
    for (const key in docFiles) {
      if (docFiles.hasOwnProperty(key)) {
        docArray.push(docFiles[key]);
      }
    }
    setShowDocument(docArray);
  };
  //upload document
  const upload_document = async (operator_id) => {
    const formData = new FormData();
    formData.append("operator_id", operator_id);
    formData.append("types", "operator");
    formData.append("serial_no", "9HUDU755");
    formData.append("doc_types", "document");
    formData.append("doc_expiry_date", "13/06/2024");
    if (uploadedDocument) {
      for (const key in uploadedDocument) {
        if (uploadedDocument.hasOwnProperty(key)) {
          formData.append("photo", uploadedDocument[key]);
        }
      }
    }
    try {
      // setLoader(true);
      const { data } = await axios.post(
        `${setBaseUrl}/operator/upload-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );
    } catch (error) {
      // console.error(error);
    } finally {
      // setLoader(false);
    }
  };

  const [formError, setFormError] = useState({
    name: false,
    joining_date: false,
    account_no: false,

  })

  const handleSubmit = async () => {
    //checking if usse has filled correct phone number and adhaar number
    if (phone_number_alert !== null) {
      alert("Please enter a valid phone number");
      return;
    }
    if (validAdhaar === false) {
      alert("Please enter a valid 12 digit adhaar number");
      return;
    }

    // Ends --- checking if usse has filled correct phone number and adhaar number

    //Check if the user has filled in all the required fields
    let mandatoryFields = ["name", "joining_date", "account_no"];
    for (const key in operatorData) {
      if (bankAccountExist === null) {
        if (mandatoryFields.includes(key) && operatorData[key].length === 0) {
          toast.error(key.toLocaleUpperCase() + " cannot be empty");
          setFormError({
            ...formError, [key]: true
          })
          return;
        }
      } else {
        alert("Please Add new Account No.");

        return;
      }
    } 
    //Ends --- Check if the user has filled in all the required fields

    // try {
    //   setLoader(true);
    //   const res = await axios.post(
    //     `${setBaseUrl}/operator/create`,
    //     operatorData,
    //     {
    //       headers: {
    //         "x-access-tokens": sessionStorage.getItem("token"),
    //       },
    //     }
    //   );
    //   // console.log(data);
    //   // upload_document(data.id);
    //   if (res.status === 200) {
    //     toast.success("Operator created successfully");
    //     // navigate("/operators");
    //     navigate("/operators");
    //   }
    // } catch (error) {
    //   console.error(error);
    //   toast.error("Error in creating operator");
    // } finally {
    //   setLoader(false);
    // }

  };
  //function for document delete
  const deleteDocuments = async (id) => {
    const data = await deleteDocument(id, "maintenance");
    if (data != false) {
      let newDocList = alreadyUploadedDocuments.filter((doc) => doc.id !== id);
      setAlreadyUploadedDocuments(newDocList);
    } else {
      alert("Document not deleted");
    }
  };

  const { operatorArray } = useGetOperatorAccount(
    `${setBaseUrl}/operator/get_all`
  );
  // console.log(operatorArray);

  const [bankAccountExist, setBankAccountExist] = useState(null);
  let AccountNo;
  // Check Bank Account
  const handleCheckAccount = (e) => {
    AccountNo = e.target.value;
    try {
      for (let i = 0; i < operatorArray.length; i++) {
        // filterAccount.push(operatorArray[i].account)
        if (AccountNo === operatorArray[i].account) {
          setBankAccountExist(operatorArray[i]);
        }
      }

      if (bankAccountExist === null) {
        setFormError({...formError, account_no: true});
        setOperatorData({
          ...operatorData,
          account_no: AccountNo,
        });
      }
    } catch (error) { }
  };

  // console.log("Bank Account Check", bankAccountExist);
  // console.log("Form Account Set", operatorData.account_no);



  const [newDob, setNewDob] = useState("");


  const [salesPerson, setSalesPerson] = useState(null)

  const getSalesPerson = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-sale-persons`, {
        headers: {
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      })
      const data = await res.json();
      setSalesPerson(data)
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    getSalesPerson()
  }, [])


  if (loader) {
    return <Loader />;
  }
  // else if (oldOperator) {
  //   navigate(`/updateOperator/${id}`)
  // }
  else if (!adhaarChecked) {
    return (
      <CheckOldOperator
        adhaarChecked={adhaarChecked}
        setAdhaarChecked={setAdhaarChecked}
        // newAdhaar={newAdhaar}
        // setNewAdhaar={setNewAdhaar}
        newDob={newDob}
        setNewDob={setNewDob}
        operatorData={operatorData}
        setOperatorData={setOperatorData}
      />
    );
  } else {
    return (
      <>
        <div id="new-operator" className="mb-16">
          <div class="flex ">
            <p class="inline py-5 pl-5 text-xs font-medium text-slate-700 dark:text-slate-200">
              New Operator
            </p>
          </div>
          <div class="flex">
            <div class="intro-y box basis-1/3 pb-5 bg-white dark:bg-slate-900 rounded-lg mx-2 shadow-md">
              <div class="px-5 text-xs">
                <div title="Operator Name" class="mt-4">
                  <label class="">Name</label>
                  <input
                    onChange={(e) =>

                      {
                        setFormError({...formError, name: false})
                        setOperatorData({ ...operatorData, name: e.target.value })}
                    }
                    type="text"
                    placeholder="e.g. John Doe"
                    className="shadow-none focus:outline-none text-black focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                  />
                  {formError.name && (
                    <p className="text-red-1 font-normal text-xs  mt-4">Name cannot be empty</p>
                  )}
                </div>
                {/* <div class="mt-4">
                  <label class="">Pin</label>
                  <input
                    disabled
                    type="text"
                    placeholder="Not required"
                    className="shadow-none focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                  />
                </div> */}
                {/* Mobile No */}
                <div title="Mobile Number" class="mt-4">
                  <label class="">Mobile Number</label>
                  <input
                  min={0}
                    // onChange={handlePhoneNo}
                    // type="text"
                    // maxLength={10}
                    type="text"
                    
                    maxLength={10}
                    value={operatorData.phone_no || ""}
                    onChange={handlePhoneNo}
                    onKeyDown={preventNonNumeric} // Prevent typing invalid characters
                    onPaste={preventPasteNonNumeric}
                    placeholder="Enter 10-digit mobile no"
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                {phone_number_alert && (
                  <p className="text-red-1 font-normal text-xs  mt-4">
                    {phone_number_alert}
                  </p>
                )}

                {/* Blood Group */}
                {/* <div class="mt-4">
                  <label class="">Blood Group</label>
                  <select
                    onChange={(e) =>
                      setOperatorData({
                        ...operatorData,
                        blood_group: e.target.value,
                      })
                    }
                    type="text"
                    placeholder=""
                    class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                  >
                    <option>A+</option>
                    <option>A-</option>
                    <option>B+</option>
                    <option>B-</option>
                    <option>O+</option>
                    <option>O-</option>
                    <option>AB+</option>
                  </select>
                </div> */}
                {/* Family Name - father/wife */}
                {/* <div class="mt-4">
                  <label class="">Emergency Contact Member Name</label>
                  <input
                    onChange={(e) =>
                      setOperatorData({
                        ...operatorData,
                        family: e.target.value,
                      })
                    }
                    type="text"
                    placeholder="Name"
                    class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                  />
                </div>
             */}

                {/* Emergency Member Contact Number */}
                {/* <div class="mt-4">
                  <label class="">Emergency Mobile No.</label>
                  <input
                    onChange={handleEmergencyPhone}
                    type="number"
                    placeholder="Mobile No."
                    class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                  />
                </div>
                {emergency_mobile_alert && (
                  <p className="text-[#24a062] font-bold  mt-4">
                    {emergency_mobile_alert}
                  </p>
                )} */}
                {/* Photo */}
                {/* <div class="mt-4">
                  <label class="">Add Profile Photo</label>
                  <input
                    onChange={(e) =>
                      setOperatorData({
                        ...operatorData,
                        photo: e.target.value,
                      })
                    }
                    type="file"
                    placeholder="image"
                    class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                  />

                </div> */}
                {/* Adhaar */}
                <div title="Adhaar Number " class="mt-5">
                  <label>Aadhaar no.</label>
                  <input
                    value={
                      operatorData.aadhar_no
                        ? operatorData.aadhar_no
                          .toString()
                          .replace(/\D/g, "") // Remove non-numeric characters
                          .replace(/(\d{4})(\d{4})(\d{4})/, "$1 $2 $3") // Format as 4-4-4
                        : "" // Fallback if Aadhaar number is not provided
                    }
                    type="text"
                    placeholder="xxxx xxxx xxxx"
                    disabled
                    className="shadow-none focus:outline-none text-black focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                  />

                  {/* {console.log(operatorData.aadhar_no)} */}
                </div>
                {validAdhaar !== null && (
                  <p className="text-[#24a062] font-bold mt-4">{validAdhaar}</p>
                )}
                {/* DOB */}
                <div title="Date of birth" class="mt-4">
                  <label class="">Date of Birth</label>
                  <input
                    onChange={(e) =>
                      setOperatorData({
                        ...operatorData,
                        dob: e.target.value,
                      })
                    }
                    value={newDob}
                    type="date"
                    disabled
                    placeholder=""
                    className="shadow-none focus:outline-none text-black focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                  />
                </div>

                <div title="Operator Role" className="mt-4">
                  <label class="">Operator Role</label>
                  <select
                    name=""
                    onChange={(e) => {
                      setOperatorData({
                        ...operatorData,
                        operator_role: e.target.value,
                      });
                    }}
                    placeholder="Operator role"
                    className="shadow-none focus:outline-none text-black focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                    id=""
                  >
                    <option value="">Select role</option>
                    <option value="Operator">Operator</option>
                    <option value="Technician">Technician</option>
                    <option value="Supervisor">Supervisor</option>
                  </select>
                </div>

                <div title="Operator sales person" className="mt-4">
                  <label class="">Operator Sales Person</label>
                  <select
                    name=""
                    onChange={(e) => {
                      setOperatorData({
                        ...operatorData,
                        operator_sale_person_id: e.target.value,
                      });
                    }}
                    id=""
                    placeholder="Operator Sales Person"
                    className="shadow-none focus:outline-none text-black focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                  >
                    <option value="">Select</option>
                    {salesPerson?.map((sale) => (
                      <option value={sale.id} key={sale.id}>

                        {sale.name}
                      </option>
                    ))}
                  </select>

                </div>
              </div>
            </div>
            <div class="intro-y box basis-1/3 bg-white dark:bg-slate-900 rounded-lg mx-2 shadow-md">
              <div title="Bank Account Number" class="p-5 text-xs">
                {/* Bank Account */}
                <div class="">
                  <label class="">Bank Account Number</label>
                  <input
                    // onClick={() => {getAllOperators()}}
                    // value={AccountNo || ''}
                    maxLength={25}
                    onChange={handleCheckAccount}
                    type="text"
                    placeholder="Enter your bank account number"
                    className="shadow-none focus:outline-none text-black focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                  />
                    {formError.account_no === true && (
                    <p className="text-red-1 font-normal text-xs  mt-4">Account no cannot be empty</p>
                  )}
                </div>

                {/* If Account Number is Common */}
                <>
                  {bankAccountExist !== null && (
                    <>
                      <div className="fixed top-[30%] left-[40%] w-[400px] h-[200px] items-center text-justify flex flex-col justify-center z-[9999] bg-white border border-black">
                        <h1 className="text-green-500 text-md font-extrabold p-1">
                          Account No Already Registered:
                        </h1>
                        <h1 className="p-1">
                          Operator:
                          <span className="font-extrabold">
                            {bankAccountExist.name}
                          </span>
                        </h1>

                        <h1 className="p-1">
                          Account No.{" "}
                          <span className="font-extrabold">
                            {bankAccountExist.account}
                          </span>
                        </h1>
                        <span className="text-xs text-red-600 font-extrabold py-2">
                          Please Enter unique account number
                        </span>
                        <button
                          className="bg-light-1 text-white w-[70px] h-7"
                          onClick={() => {
                            setBankAccountExist(null);
                            AccountNo = "";
                          }}
                        >
                          Close
                        </button>
                      </div>
                      <div
                        className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000021] z-50 "
                        onclick={() => {
                          setBankAccountExist(null);

                          AccountNo = "";
                        }}
                      />
                    </>
                  )}
                </>

                {/* Bank IFSC Code */}
                <div title="Bank IFSC code" class="mt-4">
                  <label class="">Bank IFSC Code</label>
                  <input
                    onChange={(e) =>
                      setOperatorData({
                        ...operatorData,
                        ifsc_code: e.target.value,
                      })
                    }
                    type="text"
                    placeholder=" e.g., SBIN0001234"
                    className="shadow-none focus:outline-none text-black focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                  />
                </div>
                {/* <div class="mt-4">
                <label class="">Driving Licence</label>
                <input
                  type="text"
                  placeholder="0"
                  class="input w-full border mt-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                />
              </div> */}

                <div title="PF account number" class="mt-4">
                  <label class="">PF Account Number</label>
                  <input
                    min={0}
                    maxLength={25}
                    onChange={(e) =>
                      setOperatorData({
                        ...operatorData,
                        pf_account_no: e.target.value,
                      })
                    }
                    type="text"
                    placeholder="Enter your PF Account Number"
                    className="shadow-none focus:outline-none text-black focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                  />
                </div>
                <div title="Net in-hand salary" class="">
                  <label class="">Net In-Hand Salary</label>
                  <input
                    min={0}
                    maxLength={25}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Prevent entering negative values
                      if (value === "" || value >= 0) {
                        setOperatorData({
                          ...operatorData,
                          net_inhand_salary: value,
                        });
                      }
                    }}
                    type="number"
                    placeholder="e.g 20,000"
                    className="shadow-none focus:outline-none text-black focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                  />
                </div>
                <div title="Advanced payment information" class="mt-4">
                  <label class="">Advanced Payment Information</label>
                  <input
                    title="Advanced payments"
                    onChange={(e) =>
                      setOperatorData({
                        ...operatorData,
                        advance_paid_amount: e.target.value,
                      })
                    }
                    min={0}
                    maxLength={25}
                    type="number"
                    placeholder="Advanced Payment Information"
                    className="shadow-none focus:outline-none text-black focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                  />
                </div>

              </div>
            </div>
            <div class="intro-y box basis-1/3 bg-white dark:bg-slate-900 rounded-lg mx-2 shadow-md">

              <div class="p-5 text-xs">
                <div title="Date of joining" class="mt-5">
                  <label>Date of joining</label>
                  <input
                    onChange={(e) =>
                      {
                        setFormError({
                          ...formError, joining_date: true
                        })
                        setOperatorData({
                        ...operatorData,
                        joining_date: e.target.value,
                      })}
                    }
                    type="date"
                    placeholder="07-03-2021"
                    className="shadow-none  text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                  />
                  {formError.joining_date && (
                    <p className="text-red-1 font-normal text-xs  mt-4">Joining date cannot be empty</p>
                  )}
                </div>
                {/* <div title="Date of leaving" class="mt-4">
                  <label>Date of leaving</label>
                  <input
                    onChange={(e) =>
                      setOperatorData({
                        ...operatorData,
                        leaving_date: e.target.value,
                      })
                    }
                    type="date"
                    placeholder="07-03-2021"
                    className="shadow-none  text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                  />
                </div>
                */}

                {/* <NewWallet
                  operatorData={operatorData}
                  setOperatorData={setOperatorData}
                /> */}
              </div>
            </div>
          </div>

          {/* <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col items-center p-6">
            <div className="w-full max-w-lg bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
              <h1 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-4">
                Upload Documents
              </h1>
              <p className="text-sm text-gray-600 dark:text-gray-400 mb-6">
                Please upload your documents in PDF format.
              </p>

              
              <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg p-6 mb-2">
                <label
                  htmlFor="fileUpload"
                  className="cursor-pointer flex flex-col items-center justify-center text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 transition"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-12 h-12 mb-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5V19a2 2 0 002 2h14a2 2 0 002-2v-2.5M16 10l-4-4m0 0L8 10m4-4v12"
                    />
                  </svg>
                  <span className="text-sm font-medium">
                    Click to upload Documents
                  </span>
                  <input
                    ref={documentInputRef}
                    id="fileUpload"
                    wfd-id="id57"
                    type="file"
                    className="hidden"
                    multiple
                    accept=".pdf"
                
                    onChange={handleDocumentChange}
                  />
                </label>
              </div>

              
              {showDocument?.length > 0 && (
                <div className="space-y-4">
                  {showDocument.map((doc, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-3 border rounded-lg bg-gray-100 dark:bg-gray-700"
                    >
                      <span className="text-xs text-dark-5">{doc.name}</span>

                      <button
                        className="text-red-500 hover:text-red-600 text-xs"
                        onClick={() => setShowDocument([])}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              )}
              {alreadyUploadedDocuments.length > 0 && (
                <div className="flex items-center justify-between p-3 border rounded-lg bg-gray-100 dark:bg-gray-700">
                  <span className="text-xs text-dark-5">Existing</span>
                  <div className="flex">
                    {alreadyUploadedDocuments.map((doc) => (
                    
                      <Document
                        pdfdocument={doc}
                        handleDelete={deleteDocuments}
                      />
                    ))}
                  </div>
                </div>
              )}
             
            </div>
          </div> */}
          {/* <div class="flex animSlideup relative">
            <div class="basis-full bg-white dark:bg-slate-900 rounded-lg mx-2 mt-5 border justify-between">
              <div class="flex p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base mr-auto ">
                  Operator Documents
                </h2>
                <button
                  onClick={handleDocumentUploadInput}
                  className="button border rounded-lg py-2 px-2 font-medium text-xs text-right text-gray-800 dark:border-gray-400 dark:text-gray-300 hidden sm:flex"
                >
                  <input
                    ref={documentInputRef}
                    type="file"
                    multiple
                    accept=".doc,.png,.jpg,.pdf,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    class="hidden"
                    wfd-id="id57"
                    onChange={handleDocumentChange}
                  />
                  Browse Documents
                </button>
              </div>

              <div class="p-5 text-xs">
                {uploadedDocument && (
                  <div className="flex flex-col">
                    <span className="text-xs">Uploading....</span>
                    {showDocument.length > 0 &&
                      showDocument.map((doc) => <p>{doc.name}</p>)}
                  </div>
                )}
              </div>
              {alreadyUploadedDocuments.length > 0 && (
                <div className="p-5 text-xs">
                  <span className="text-xs">Existing</span>
                  <div className="flex">
                    {alreadyUploadedDocuments.map((doc) => (
                      // <p>{doc.substring(doc.length - 17)}</p>
                      <Document
                        pdfdocument={doc}
                        handleDelete={deleteDocuments}
                      />
                    ))}
                  </div>
                </div>
              )}
              <div class="p-5 text-xs">
              <div class="flex flex-col text-transparent">Documents here</div>
            </div>
            </div>
          </div> */}
        </div>

        <div class="flex mt-5 fixed justify-end gap-3 items-center w-full bottom-0 left-0 right-10 bg-white   border h-10">
          <button
            title="Click to go back to the main operator page"
            onclick="showDiv('main-operator','new-operator');"
            class="  shadow-md text-black bg-white border px-3 text-xs h-7 w-[96px] rounded-[2px]"
            onClick={() => navigate("/operators")}
          >
            Back
          </button>
          <button
            title="Click to create a new operator"
            onClick={handleSubmit}
            className=" shadow-md text-white bg-blue-2 px-3 text-xs h-7 w-[96px] rounded-[2px] transition"
          >
            Save
          </button>
        </div>
      </>
    );
  }
}

export default NewOperator;
