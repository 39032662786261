import axios from "axios";
import React, { useState } from "react";
import { setBaseUrl } from "../../../config";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import SalesParson from "../../tables/SettingsTable/SalesParson";
import { toast } from "react-toastify";

function NewEmployee() {
  const selectOptions = [
    {
      value: "ASSETS.ALL",
      label: "ASSETS.ALL",
    },
    { value: "ASSETS.VIEW", label: "ASSETS.VIEW" },
    { value: "ASSETS.CRU", label: "ASSETS.CRU" },
    { value: "MAINT.ALL", label: "MAINT.ALL" },
    { value: "MAINT.CRU", label: "MAINT.CRU" },
    { value: "MAINT.VIEW", label: "MAINT.VIEW" },

    { value: "LEASE.ALL", label: "LEASE.ALL" },
    { value: "LEASE.CRU", label: "LEASE.CRU" },
    { value: "LEASE.VIEW", label: "LEASE.VIEW" },
    { value: "OPERATOR.ALL", label: "OPERATOR.ALL" },
    { value: "OPERATOR.CRU", label: "OPERATOR.CRU" },
    { value: "OPERATOR.VIEW", label: "OPERATOR.VIEW" },
    { value: "ADMIN.ALL", label: "ADMIN.ALL" },
  ];
  const [selectOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const [employeeDetail, setEmployeeDetail] = useState({ email: "", name: "" });

  const addEmployeePerms = async () => {
    let permissions = selectOption.map((per) => per.value);
    try {
      const { data } = await axios.post(
        `${setBaseUrl}/company/add-permissions`,
        {
          email: employeeDetail.email,
          permissions: permissions,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );
      toast.success("employee permissions added successfully");
    } catch (error) {
      console.error(error);
      toast.error("employee permissions added failed");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${setBaseUrl}/company/add-employee`,
        employeeDetail,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );

      await addEmployeePerms();
      toast.success("Employee added successfully");
    } catch (error) {
      console.error(error);
      toast.error("Employee added failed");
    } finally {
      navigate("/settings");
    }
  };


  const [salesPerson, setSalesPerson] = useState(false)

  return (
    // <div className="h-full w-full dark:text-white">
    //   <div className="flex justify-start items-center mx-10 my-0 mt-5">
    //     <div className="flex justify-between items-center">
    //       <button onClick={() => navigate("/settings")} className="border border-light-2 text-xs rounded-full p-1 px-2">
    //        Back
    //       </button>
         
    //     </div>
    //   </div>
    //   <div class="intro-y mx-10 overflow-auto lg:overflow-visible mt-8 sm:mt-0">
    //     <div className="flex flex-col gap-2 justify-center items-center">
    //       <label for="email">Email:</label>
    //       <input
    //         type="email"
    //         name="email"
    //         required
    //         placeholder="Email"
    //         className="p-2 mx-4 text-xs w-[200px] bg-light-4 border-none focus:border-none focus:outline-none focus:ring-0 rounded"
    //         onChange={(e) =>
    //           setEmployeeDetail({ ...employeeDetail, email: e.target.value })
    //         }
    //       />
    //       <label for="name">Name:</label>
    //       <input
    //         type="text"
    //         name="name"
    //         required
    //         placeholder="Employee Name"
    //         className="p-2 mx-4 text-xs w-[200px] bg-light-4 border-none focus:border-none focus:outline-none focus:ring-0 rounded"
    //         onChange={(e) =>
    //           setEmployeeDetail({ ...employeeDetail, name: e.target.value })
    //         }
    //       />
    //       <label>Choose Permissions: </label>
    //       <Select
    //         isMulti
    //         onChange={setSelectedOption}
    //         options={selectOptions}
    //         styles={{
    //           container: (provided) => ({
    //             ...provided,
    //             width: "200px",
    //             fontSize: 10,
    //           }),
    //           control: (provided, state) => ({
    //             ...provided,
    //             width: "200px",
    //             height: "20px",
    //             border:
    //               state.isFocused || state.isHovered
    //                 ? "none"
    //                 : "1px solid transparent",
    //             borderBottom: "1px solid #D1D5DB",
    //             boxShadow: state.isFocused
    //               ? "0.2px 0.2px 0.2px 0.2px rgba(156, 163, 175, 0.25)"
    //               : null,
    //             outline: "none",
    //             borderRadius: 0,
    //           }),
    //         }}
    //       />
    //       <button
    //         type="submit"
    //         className="bg-blue-2 text-white px-3 py-1 text-xs rounded"
    //         onClick={handleSubmit}
    //       >
    //         Submit
    //       </button>
    //     </div>
    //   </div>
    // </div>
    <>
  <div className="h-full w-full dark:text-white">
  {/* Header Section */}
  <div className="flex justify-between items-center px-8 py-4 border-b border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800">
    <button
      onClick={() => navigate("/settings")}
      className="bg-white hover:bg-gray-200 text-black px-4 py-2 rounded-md shadow-md transition"
    >
      Back
    </button>


    <div className="flex items-center gap-2">
      <button className={`buttons ${salesPerson? 'bg-white text-black':'bg-blue-2 text-white'}` } onClick={() => setSalesPerson(false)}>
        Add Employee
      </button>
      <button className={`buttons ${!salesPerson? 'bg-white text-black':'bg-blue-2 text-white'}` } onClick={() => setSalesPerson(true)}>
       Sales & Service
      </button>
    </div>
  </div>

  <div className="flex flex-col lg:flex-row gap-8 px-8 py-6">
    {/* Employee Details Section */}
    {!salesPerson ?   <div className="w-full lg:w-1/2 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <h2 className="text-lg font-semibold text-gray-800 dark:text-white mb-4">
        Employee Details
      </h2>
      
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        {/* Email Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Email
          </label>
          <input
            type="email"
            name="email"
            required
            placeholder="Enter Email"
            className="w-full p-2 text-sm border border-gray-300 dark:border-gray-600 rounded-md focus:ring-2 focus:ring-blue-400 dark:bg-gray-700 dark:text-white"
            onChange={(e) =>
              setEmployeeDetail({ ...employeeDetail, email: e.target.value })
            }
          />
        </div>

        {/* Name Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Name
          </label>
          <input
            type="text"
            name="name"
            required
            placeholder="Enter Name"
            className="w-full p-2 text-sm border border-gray-300 dark:border-gray-600 rounded-md focus:ring-2 focus:ring-blue-400 dark:bg-gray-700 dark:text-white"
            onChange={(e) =>
              setEmployeeDetail({ ...employeeDetail, name: e.target.value })
            }
          />
        </div>

        {/* Permissions Dropdown */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Permissions
          </label>
          <Select
            isMulti
            onChange={setSelectedOption}
            options={selectOptions}
            styles={{
              container: (provided) => ({
                ...provided,
                width: "100%",
                fontSize: "12px",
              }),
              control: (provided, state) => ({
                ...provided,
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                boxShadow: state.isFocused
                  ? "0px 0px 5px rgba(156, 163, 175, 0.5)"
                  : "none",
                "&:hover": {
                  borderColor: "#2563EB",
                },
              }),
            }}
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 rounded-md transition-all duration-200 shadow-md"
        >
          Submit
        </button>
      </form>
    </div> :   <div className="w-full lg:w-1/2">
      <SalesParson />
    </div>}
  

    {/* Sales Person Section */}
  
  </div>
</div>

    </>
  );
}

export default NewEmployee;
