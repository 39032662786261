import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { setBaseUrl } from "../../config";
import BasicDetails from "./NewAsset/BasicDetails";
import ConfigDetails from "./NewAsset/ConfigDetails";
import CommercialDetails from "./NewAsset/CommercialDetails";
import Loader from "../Loader";
import { useNavigate, useParams } from "react-router-dom";
import Image from "../viewer/Image";
import Document from "../viewer/Document";
import deleteDocument from "../../apis/files/deleteDocument";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";

const UpdateAssets = ({ showCurrentTab }) => {
  const { id } = useParams();

  const navigate = useNavigate();
  // set loader state
  const [loader, setLoader] = useState(false);
  //setting state for allowing option for commercial details

  const [commToggle, setCommToggle] = useState(false);
  const [assetBasicDetails, setAssetBasicDetails] = useState({});
  const [assetConfigDetails, setAssetConfigDetails] = useState({
    // used_or_new: "",
    ansi_or_new: "",
    machine_ownership_type: "",
    battery_type: "",
    engine_serial_no: "",
    two_or_four_wd: "",
    accessories: "",
    tyres: "",
    asset_id: "",
  });

  const [commercialDetails, setCommercialDetails] = useState({
    purchase_order_no: "",
    purchase_order_date: "",
    invoice_no: "",
    invoice_date: "",
    payment_terms: "",
    amount_rem_to_oem: "",
    date_of_rem_to_oem: "",
    exchange_rate_rem: "",
    custom_duty_payment: "",
    exworks_price: "",
    cif_charges: "",
    total_cost: "",
    boe_no: "",
    custom_duty_value: "",
    gst_amount: "",
    exrate_boe: "",
    clearing_charges: "",
    cha_charges: "",
    transportation_charges: "",
    port_of_dispatch: "",
    port_of_clearance: "",
    period_of_insurance: "",
    insurance_renewal: "",
    total_landed_cost: "",
    total_landed_cost_with_gst: "",
    asset_id: "",
    value_of_sold_asset: "",
    sold_customer_name: "",
  });
  const [
    // ifCommercialDetailsExists,
    setIfCommercialDetailsExists,
  ] = useState(true);

  const documentInputRef = useRef(null);
  const imageInputRef = useRef(null);
  //set document
  const [uploadedDocument, setUploadedDocument] = useState(null);
  //set image
  const [uploadedImage, setUploadedImage] = useState();
  //for showing the list of uploaded images
  const [showImage, setShowImage] = useState(null);
  //while updating, set existing images if already uploaded
  const [alreadyUploadedImage, setAlreadyUploadedImage] = useState(null);

  //for showing the list of documents
  const [showDoc, setShowDoc] = useState(null);
  // while updating, set existing documents if already uploaded
  const [alreadyUploadedDoc, setAlreadyUploadedDoc] = useState(null);

  const getAllAssetData = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/asset/get_data_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
        },
      });
      // console.log(data);
      if (Object.keys(data.commercial_detail).length === 0) {
        setIfCommercialDetailsExists(false);
      }

      setAssetBasicDetails({
        asset_no: data.asset_no,
        make: data.make,
        model: data.model,
        category: data.category,
        yom: data.yom,
        capacity: data.capacity,
        hieght_machine: data.hieght_machine,
        rating: data.rating,
        description: data.description,
        serial_no: data.serial_no,
        purchased_from: data.purchased_from,
        rfid: data.rfid,
        device_no: data.device_no,
        site_location: data.site_location,
        //company_id:
        is_sold: data.is_sold,
        doc_expiry_date: new Date(),
        doc_types: "doc",
        asset_sold_date: data.asset_sold_date,
        installation_date: data.installation_date,
        custom_duty_date_of_clearance: data.custom_duty_date_of_clearance,
      });
      if (data.photo_data.length > 0) {
        let getOnlyImageUris = data.photo_data.map((item) => item.image_uri);
        setAlreadyUploadedImage(getOnlyImageUris);
      }
      if (data.attachment_data.length > 0) {
        let getOnlyAttachmentUris = data.attachment_data.map(
          (item) => item.doc_uri
        );
        setAlreadyUploadedDoc(getOnlyAttachmentUris);
      }
      if (
        Object.keys(data.config_data).length !== 0 &&
        data.config_data.constructor === Object
      ) {
        setAssetConfigDetails((prevDetails) => ({
          used_or_new:
            data?.config_data?.used_or_new || prevDetails.used_or_new,
          ansi_or_new:
            data?.config_data?.ansi_or_new || prevDetails.ansi_or_new,
          machine_ownership_type:
            data?.config_data?.machine_ownership_type ||
            prevDetails.machine_ownership_type,

          battery_type:
            data?.config_data?.battery_type || prevDetails.battery_type,
          engine_serial_no:
            data?.config_data?.engine_serial_no || prevDetails.engine_serial_no,
          two_or_four_wd:
            data?.config_data?.two_or_four_wd || prevDetails.two_or_four_wd,
          accessories:
            data?.config_data?.accessories || prevDetails.accessories,
          tyres: data?.config_data?.tyres || prevDetails.tyres,
          asset_id: id,
        }));
      }
      setCommercialDetails({ ...data.commercial_detail[0] });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getAllAssetData();
  }, []);

  //invoke the document input tag on button click
  const handleDocumentUploadInput = () => {
    documentInputRef.current.click();
  };

  // console.log(commercialDetails)
  const handleDocumentChange = (e) => {
    setUploadedDocument(e.target.files);
    let docFiles = e.target.files;
    let docArray = [];
    for (const key in docFiles) {
      if (docFiles.hasOwnProperty(key)) {
        docArray.push(docFiles[key]);
      }
    }
    setShowDoc(docArray);
  };

  //invoke the image input tag on button click
  const handleImageUploadInput = () => {
    imageInputRef.current.click();
  };

  // input image
  const handleImageUploadChange = (e) => {
    setUploadedImage(e.target.files);
    let photoFiles = e.target.files;
    let photoArray = [];
    for (const key in photoFiles) {
      if (photoFiles.hasOwnProperty(key)) {
        photoArray.push(photoFiles[key]);
      }
    }

    setShowImage(photoArray);
  };

  const [uploadImage, setUploadImage] = useState(false);
  const [uploadImageLoading, setUploadImageLoading] = useState(false);

  const handleUploadImageToDatabase = async (asset_id) => {
    setUploadImageLoading(true);
  
    if (!uploadedImage || uploadedImage.length === 0) {
      toast.warn("No image selected for upload!");
      setUploadImageLoading(false);
      return;
    }
  
    // Validate file size (5MB max)
    const oversizedFiles = uploadedImage.filter((photo) => photo.size > 5 * 1024 * 1024);
    if (oversizedFiles.length > 0) {
      toast.warn("Some images exceed 5MB! Please remove large files.");
      setUploadImageLoading(false);
      return;
    }
  
    const formData = new FormData();
    uploadedImage.forEach((photo) => {
      formData.append("photo", photo);
    });
  
    formData.append("types", "asset");
    formData.append("asset_id", asset_id);
  
    try {
      const response = await axios.post(`${setBaseUrl}/asset/upload_photo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
  
      if (response.status === 200 || response.status === 201) {
        toast.success("Photo uploaded successfully!");
        setUploadImage(true);
      } else {
        throw new Error("Unexpected response from server.");
      }
    } catch (error) {
      console.error("Upload Error:", error);
      toast.error("Photo upload failed! Please try again.");
    } finally {
      setUploadImageLoading(false);
    }
  };
  
  // upload the document to the database
  async function handleDocument(asset_id) {
    // console.log(asset_id);
    // console.log(uploadedDocument);
    if (uploadedDocument) {
      const formData = new FormData();
      for (const key in uploadedDocument) {
        if (uploadedDocument.hasOwnProperty(key)) {
          formData.append("attachment", uploadedDocument[key]);
        }
      }
      formData.append("types_section", "asset");
      formData.append("asset_id", asset_id);
      formData.append("doc_types", "document");
      formData.append("doc_expiry_date", new Date());
      formData.append("serial_no", assetBasicDetails?.serial_no);

      await axios
        .post(`${setBaseUrl}/asset/upload_attachment`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  const [updateAssetDetails, setUpdateAssetDetails] = useState({});
  const [updateAssetConfig, setUpdateAssetConfig] = useState({});
  const [updateAssetCommercial, setUpdateAssetCommercial] = useState({});

  // const handleSubmit = async () => {
  //   try {
  //     setLoader(true);

  //     const data1 = await axios.put(
  //       `${setBaseUrl}/asset/update`,

  //       {
  //         id: id,
  //         ...updateAssetDetails,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-tokens": sessionStorage.getItem("token"),
  //         },
  //       }
  //     );
  //     await handleDocument(id);
  //     // alert("Saved!!!")
  //     toast.success("Asset details updated successfully!");

  //     //send request to the create config details with the above id
  //     if (updateAssetConfig) {
  //       try {
  //         const data2 = await axios.put(
  //           `${setBaseUrl}/asset/update_config`,
  //           {
  //             asset_id: id,
  //             ...updateAssetConfig,
  //           },
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //               "x-access-tokens": sessionStorage.getItem("token"),
  //             },
  //           }
  //         );

  //         toast.success("Asset configuration updated successfully!");
  //       } catch (error) {
  //         console.error("Error updating asset configurations", error);

  //         // Show error toast message
  //         toast.error("Configuration Update failed");
  //       }
  //     }

  //     //send request to the create commercial details with the above id only if commToggle is true

  //     if (updateAssetCommercial) {
  //       const data3 = await axios.put(
  //         `${setBaseUrl}/asset/update_details`,
  //         {
  //           ...updateAssetCommercial,
  //           asset_id: id,
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             "x-access-tokens": sessionStorage.getItem("token"),
  //           },
  //         }
  //       );
  //       toast.success("Asset commercial details updated successfully!");
  //     }

  //     // console.log(updateAssetCommercial)
  //     // navigate(`/assets/AssetDetails/${id}`);
  //   } catch (error) {
  //     console.error(error);
  //     toast.error("An error occurred while updating the asset.");
  //   } finally {
  //     setLoader(false);
  //   }
  // };
  //function for document delete

  // const handleSubmit = async () => {
  //   try {
  //     setLoader(true);

  //     const headers = {
  //       "Content-Type": "application/json",
  //       "x-access-tokens": sessionStorage.getItem("token"),
  //     };

  //     // Update asset details
  //     const updateDetails = axios.put(
  //       `${setBaseUrl}/asset/update`,
  //       { id, ...updateAssetDetails },
  //       { headers }
  //     );

  //     // Update asset configurations if they exist
  //     const updateConfig = Object.keys(updateAssetConfig).length
  //       ? axios.put(
  //           `${setBaseUrl}/asset/update_config`,
  //           { asset_id: id, ...updateAssetConfig },
  //           { headers }
  //         )
  //       : null;

  //     // Update commercial details if they exist
  //     const updateCommercial = Object.keys(updateAssetCommercial).length
  //       ? axios.put(
  //           `${setBaseUrl}/asset/update_details`,
  //           { asset_id: id, ...updateAssetCommercial },
  //           { headers }
  //         )
  //       : null;

  //     // Handle document upload
  //     const handleDoc = handleDocument(id);

  //     // Execute all necessary calls in parallel
  //     const results = await Promise.allSettled([
  //       updateDetails,
  //       updateConfig,
  //       updateCommercial,
  //       handleDoc,
  //     ]);

  //     // Handle results
  //     results.forEach((result, index) => {
  //       if (result.status === "fulfilled") {
  //         const messages = [
  //           "Asset details updated successfully!",
  //           "Asset configuration updated successfully!",
  //           "Asset commercial details updated successfully!",
  //           "Document upload handled successfully!",
  //         ];
  //         // toast.success(messages[index]);
  //         toast.success("Asset Updated Successfully")
  //       } else if (result.status === "rejected") {
  //         const errors = [
  //           "Failed to update asset details.",
  //           "Failed to update asset configuration.",
  //           "Failed to update asset commercial details.",
  //           "Failed to handle document upload.",
  //         ];
  //         console.error(errors[index], result.reason);
  //         toast.error(errors[index]);
  //       }
  //     });

  //     // Navigate to asset details page only if the first request succeeds
  //     if (results[0].status === "fulfilled") {
  //       navigate(`/assets/asset-details/${id}`);
  //     }
  //   } catch (error) {

  //     toast.error("An unexpected error occurred.");
  //   } finally {
  //     setLoader(false);
  //   }
  // };
 
 
  const handleSubmit = async () => {
    try {
      setLoader(true);

      const headers = {
        "Content-Type": "application/json",
        "x-access-tokens": sessionStorage.getItem("token"),
      };

      // Prepare API calls
      const updateDetails = axios.put(
        `${setBaseUrl}/asset/update`,
        { id, ...updateAssetDetails },
        { headers }
      );

      const updateConfig = Object.keys(updateAssetConfig).length
        ? axios.put(
            `${setBaseUrl}/asset/update_config`,
            { asset_id: id, ...updateAssetConfig },
            { headers }
          )
        : Promise.resolve(); // Resolve immediately if no config to update

      const updateCommercial = Object.keys(updateAssetCommercial).length
        ? axios.put(
            `${setBaseUrl}/asset/update_details`,
            { asset_id: id, ...updateAssetCommercial },
            { headers }
          )
        : Promise.resolve(); // Resolve immediately if no commercial details to update

      const handleDoc = handleDocument(id);

      // Execute all calls in parallel
      const results = await Promise.allSettled([
        updateDetails,
        updateConfig,
        updateCommercial,
        handleDoc,
      ]);

      // Check for failures
      const failedResults = results.filter(
        (result) => result.status === "rejected"
      );

      if (failedResults.length === 0) {
        toast.success("Asset updated successfully!");
        navigate(`/assets/asset-details/${id}`);
      } else {
        toast.error("Some updates failed. Please check and try again.");
        console.error(
          "Failed results:",
          failedResults.map((r) => r.reason)
        );
      }
    } catch (error) {
      toast.error("An unexpected error occurred.");
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const deleteDocuments = async (id) => {
    const data = await deleteDocument(id, "maintenance");
    if (data !== false) {
      let newDocList = alreadyUploadedDoc.filter((doc) => doc.id !== id);
      setAlreadyUploadedDoc(newDocList);
    } else {
      alert("Document not deleted");
    }
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <div id="new-asset" className="animfadein dark:text-white">
        <div class="flex mt-2 h-7   items-center">
          <p class=" pl-5  font-semibold text-dark-6 text-sm">Update Asset</p>
        </div>
        <BasicDetails
          assetBasicDetails={assetBasicDetails}
          setAssetBasicDetails={setAssetBasicDetails}
          id={id}
          updateAssetDetails={updateAssetDetails}
          setUpdateAssetDetails={setUpdateAssetDetails}
        />

        <ConfigDetails
          assetConfigDetails={assetConfigDetails}
          setAssetConfigDetails={setAssetConfigDetails}
          updateAssetConfig={updateAssetConfig}
          setUpdateAssetConfig={setUpdateAssetConfig}
        />

        <CommercialDetails
          className=""
          commToggle={commToggle}
          setCommToggle={setCommToggle}
          commercialDetails={commercialDetails}
          setCommercialDetails={setCommercialDetails}
          updateAssetCommercial={updateAssetCommercial}
          setUpdateAssetCommercial={setUpdateAssetCommercial}
          assetBasicDetails={assetBasicDetails}
          setAssetBasicDetails={setAssetBasicDetails}
          updateAssetDetails={updateAssetDetails}
          setUpdateAssetDetails={setUpdateAssetDetails}
        />

        {/* <div class="flex animSlideup relative  ">
          <div class="basis-full bg-white dark:bg-slate-900 rounded-lg mx-2 mt-5 border justify-between">
            <div class="flex p-5 border-b border-gray-200 dark:border-dark-5">
              <h2 class="font-medium text-xs mr-auto ">Asset Photos</h2>
              <button
              title="Click to upload images"
                className="shadow-md bg-blue-2 text-white px-3 text-xs h-7 w-[120px] rounded-[2px] transition border"
                onClick={handleImageUploadInput}
              >
                <input
                  ref={imageInputRef}
                  type="file"
                  accept="image/jpeg, image/png"
                  multiple
                  onChange={handleImageUploadChange}
                  wfd-id="id57"
                  className="hidden"
                />
                Browse Images
              </button>
              <span></span>
            </div>

            {uploadedImage && (
              <div className="p-5 text-sm">
                
                <div className="flex flex-col text-base">
                  {showImage.length > 0 &&
                    showImage.map((img) => (
                      <p>
                        <p>{img.name}</p>
                      </p>
                    ))}
                </div>
              </div>
            )}
            {alreadyUploadedImage != null && (
              <div className="p-5 text-sm">
                <span className="text-xs">Existing</span>
                <div className="flex  text-base">
                  {alreadyUploadedImage?.map((item) => (
                    // <p>{item.substring(item.length - 17)}</p>
                    <Image image_uri={item} />
                  ))}
                </div>
              </div>
            )}
          

            <button
              title="Click to save"
              className="shadow-md text-white bg-blue-2 px-3 text-xs h-7 w-[116px] rounded-[2px] transition border"
              onClick={() => {
                handleUploadImageToDatabase(id);
              }}
            >
             {uploadImageLoading ? 'Uploading..' : 'Click to Save'} 
            </button>
            {uploadImage && (
              <>
                {" "}
                <div className="text-green-500 text-xs mx-4 p-1">
                  Image Uploaded Successfully
                </div>{" "}
              </>
            )}
          </div>
        </div> */}
        <div className="flex flex-col lg:flex-row animSlideup relative mt-2">
          <div className="w-full bg-white dark:bg-slate-900 rounded-lg shadow-md border p-5">
            {/* Header */}
            <div className="flex items-center justify-between border-b border-gray-200 dark:border-dark-5 pb-3">
              <h2 className="font-medium text-sm text-gray-700 dark:text-white">
                Asset Photos
              </h2>

              <button
                title="Click to upload images"
                className="shadow-md bg-blue-2 hover:bg-blue-600 text-white px-4 py-2 text-xs rounded-md transition border"
                onClick={handleImageUploadInput}
              >
                <input
                  ref={imageInputRef}
                  type="file"
                  accept="image/jpeg, image/png"
                  multiple
                  onChange={handleImageUploadChange}
                  className="hidden"
                />
                Browse Images
              </button>
            </div>

            {/* Tagline */}
            <p className="text-xs text-gray-500 mt-2">
              Images must be lower than 5MB
            </p>

            {/* Selected Images Preview */}
            {uploadedImage && (
              <div className="mt-4">
                <h3 className="text-xs font-medium text-gray-700 dark:text-white">
                  Selected Images
                </h3>
                <div className="flex flex-wrap gap-2 mt-2">
                  {showImage.length > 0 &&
                    showImage.map((img, index) => (
                      <p
                        key={index}
                        className="text-xs bg-gray-100 dark:bg-gray-700 px-2 py-1 rounded-md"
                      >
                        {img.name}
                      </p>
                    ))}
                </div>
              </div>
            )}

            {/* Already Uploaded Images */}
            {alreadyUploadedImage && alreadyUploadedImage.length > 0 && (
              <div className="mt-4">
                <h3 className="text-xs font-medium text-gray-700 dark:text-white">
                  Existing Images
                </h3>
                <div className="flex flex-wrap gap-2 mt-2">
                  {alreadyUploadedImage.map((item, index) => (
                    <Image
                      key={index}
                      image_uri={item}
                      className="w-16 h-16 object-cover rounded-md border"
                    />
                  ))}
                </div>
              </div>
            )}

            {/* Save Button */}
            <div className="mt-4 flex items-center gap-3">
              <button
                title="Click to save"
                className="shadow-md text-white bg-blue-2 hover:bg-blue-600 px-4 py-2 text-xs rounded-md transition border"
                onClick={() => handleUploadImageToDatabase(id)}
              >
                {uploadImageLoading ? "Uploading.." : "Click to Save"}
              </button>

              {uploadImage && (
                <div className="text-green-500 text-xs">
                  Image Uploaded Successfully
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="basis-full bg-white dark:bg-slate-900 rounded-lg mx-2 mt-5 mb-24 border justify-between">
          <div class="flex p-5 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-xs mr-auto ">Assets Documents</h2>
            <button
              onClick={handleDocumentUploadInput}
              class="text-white bg-blue-2 buttons"
            >
              <input
                title="Click to upload documents"
                ref={documentInputRef}
                type="file"
                accept=".doc,.pdf,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                multiple
                className="hidden"
                wfd-id="id57"
                onChange={handleDocumentChange}
              />
             Documents
            </button>
          </div>
          <div class="p-5 text-sm">
            {uploadedDocument && (
              <div class="flex flex-col">
                <span className="text-lg">Uploading...</span>
                {showDoc.length > 0 && showDoc.map((doc) => <p>{doc.name}</p>)}
              </div>
            )}
          </div>
          {alreadyUploadedDoc != null && (
            <div class="p-5 text-sm">
              <span className="text-lg">Existing</span>
              <div class="flex ">
                {alreadyUploadedDoc.map((item) => (
                  // <p>{item.substring(item.length - 17)}</p>
                  <Document pdfdocument={item} handleDelete={deleteDocuments} />
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="h-12 bottom-0 border-t items-center flex justify-end  left-10 right-0 fixed z-50  bg-white shadow-2xl ">
          <div className="flex mr-6 justify-between items-center gap-3 text-xs">
            <button
              title="Go back to assets"
              className="buttons border text-dark-10"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
            <button
              title="Click to save"
              className="buttons bg-blue-2 text-white"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default UpdateAssets;
