import React, { useEffect, useRef, useState } from "react";
import { setBaseUrl } from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import UpdateForm from "./components/UpdateForm";
import { toast } from "react-toastify";

const UpdateLogistics = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const hasGetLogistics = useRef(false)

  // Fetch logistics data by ID
  const getLogisticsData = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-logistic-id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
        },
      });

      if (res.ok) {
        const data = await res.json();
        setFormData(data); // Initialize formData with fetched data
      } else {
        console.error("Failed to fetch logistics data.");
      }
    } catch (error) {
      console.error("Error fetching logistics data:", error);
    }
  };

  // Update logistics data
  const updateLogisticsData = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${setBaseUrl}/lease/update-logistic`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify({ formData, id }),
      });

      if (res.ok) {
        toast.success("Logistics data updated successfully!");
      } else {
        toast.error("Failed to update logistics data wrong response!");
      }
      navigate("/logistics");
    } catch (error) {
      setLoading(false);
      toast.error("Failed to update logistics data!");
    }
  };

  useEffect(() => {
    if (!hasGetLogistics.current) {
      hasGetLogistics.current = true;
      getLogisticsData();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateLogisticsData();
  };

  return (
    <>
      <Layout>
        <h1 className="text-sm font-extrabold text-center mb-3 text-dark-6 px-4">
          Update Logistics Data
        </h1>
        <form
          action=""
          className=" w-full h-full px-4 gap-4 flex flex-col justify-between bg-white"
          onSubmit={handleSubmit}
        >
          <div className="flex justify-center gap-4">
            <div className="flex justify-between flex-col gap-2">
              <UpdateForm
                setFormData={setFormData}
                title="Asset No."
                keyValueType="assets_no"
                value={formData?.assets_no || "N/A"}
              />
              <UpdateForm
                title="RSO No"
                keyValueType="lease_RSO_nos"
                value={formData?.lease_RSO_nos || "N/A"}
                formData={formData}
                setFormData={setFormData}
              />

              {/* Mob Demob State */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Mob Demob State
                </h1>
                <select
                  name=""
                  id=""
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      mob_demob_state: e.target.value,
                    });
                  }}
                  value={formData?.mob_demob_state || ""}

                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                >
                  <option value="mob">Mob</option>
                  <option value="demob">Demob</option>
                </select>
              </div>


              {/* Date of Dispatch */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Date Of Dispatch
                </h1>
                <input
                  type="date"
                  value={formData?.date_of_dispatch || ""}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      date_of_dispatch: e.target.value,
                    });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>

              {/* Source Location */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Source Location
                </h1>
                <input
                  type="text"
placeholder="e.g. Delhi"
                  maxLength={25}
                  value={formData?.origin || ""}
                  onChange={(e) => {
                    setFormData({ ...formData, origin: e.target.value });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>

              {/* Destination */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Destination
                </h1>
                <input
                  type="text"
placeholder="e.g. Kolkata"
                  maxLength={25}
                  value={formData?.destination || ""}
                  onChange={(e) => {
                    setFormData({ ...formData, destination: e.target.value });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>


              {/* Transporter Name */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Transporter Name
                </h1>
                <input
                  type="text"
placeholder="e.g. TEREX"
                  maxLength={25}
                  value={formData?.courier_company || ""}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      courier_company: e.target.value,
                    });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>

              {/* Transporter Bill No */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Transporter Bill No
                </h1>
                <input
                  type="text"
placeholder="e.g. ES245"
                  maxLength={25}
                  value={formData?.transporter_bill_no || ""}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      transporter_bill_no: e.target.value,
                    });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>

              {/* Transporter Bill Date */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Transporter Bill Date
                </h1>
                <input
                  type="date"
                  value={formData?.transporter_bill_date || ""}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      transporter_bill_date: e.target.value,
                    });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>

              {/* Total Distance (KM) */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Total Distance (km)
                </h1>
                <input
                  type="number"
                  placeholder="e.g. 1000km"
                  min={0}
                  maxLength={25}
                  value={formData?.total_km || ""}
                  onChange={(e) => {
                    setFormData({ ...formData, total_km: e.target.value });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>

              {/* E-Way Bill Number */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  E-Way Bill Number
                </h1>
                <input
                  type="number"
                  placeholder="e.g. E2SPES"
                  min={0}
                  maxLength={25}
                  value={formData?.awb_number || ""}
                  onChange={(e) => {
                    setFormData({ ...formData, awb_number: e.target.value });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>

            </div>
            <div className="flex justify-between flex-col gap-2">

              {/* Bill Amount */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Bill Amount
                </h1>
                <input
                  type="number"
                  placeholder="e.g. ₹4000/-"
                  min={0}
                  maxLength={25}
                  value={formData?.bill_amount || ""}
                  onChange={(e) => {
                    setFormData({ ...formData, bill_amount: e.target.value });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>

              {/* Transportation Cost */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Transportation Cost (₹)
                </h1>
                <input
                  type="number"
                  placeholder="e.g. ₹1000/-"
                  min={0}
                  maxLength={25}
                  value={formData?.transportation_cost || ""}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      transportation_cost: e.target.value,
                    });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>

              {/* Amount Paid by Customer */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Amount Paid By Customer
                </h1>
                <input
                  type="number"
                  placeholder="e.g. ₹4000/-"
                  min={0}
                  maxLength={25}
                  value={formData?.amount_paid_by_customer || ""}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      amount_paid_by_customer: e.target.value,
                    });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>

              {/* Rate/KM */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Rate/km
                </h1>
                <input
                  type="number"
                  placeholder="e.g. 5/km"
                  min={0}
                  maxLength={25}
                  value={formData?.rate_per_km || ""}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    // Prevent setting negative values in the state
                    setFormData({
                      ...formData,
                      rate_per_km: value >= 0 ? value : 0, // Ensure value is not less than 0
                    });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8"
                />
              </div>

              {/* Arrival Date */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Arrival Date
                </h1>
                <input
                  type="date"
                  value={formData?.arrival_date || ""}
                  onChange={(e) => {
                    setFormData({ ...formData, arrival_date: e.target.value });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>


              {/* DC number */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Dc No
                </h1>
                <input
                  type="text"
            
                  maxLength={25}
                  value={formData?.dc_no}
                  placeholder="dc number"
                  onChange={(e) => {
                    setFormData({ ...formData, dc_no: e.target.value });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>

              {/* Dc Date */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Dc Date
                </h1>
                <input
                  type="date"
                  value={formData?.dc_date || ""}
                  onChange={(e) => {
                    setFormData({ ...formData, dc_date: e.target.value });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>

              {/* Truck Number */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Truck No
                </h1>
                <input
                  type="text"
                  placeholder="e.g. truck number"
                  maxLength={25}
                  value={formData?.truck_no || ""}
                  onChange={(e) => {
                    setFormData({ ...formData, truck_no: e.target.value });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>


              {/* Truck Ft */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Truck ft
                </h1>
                <input
                  type="number"
                  placeholder="e.g. 12ft"
                  min={0}
                  maxLength={25}
                  value={formData?.truck_ft || ""}
                  onChange={(e) => {
                    setFormData({ ...formData, truck_ft: e.target.value });
                  }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>


              {/* MOB Invoice */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Mob as per invoice
                </h1>
                <input
                  type="file"
                  disabled
                  // min={0}
                  // maxLength={25}
                  // value={formData?.mob_as_per_invoice || "N/A"}
                  // onChange={(e) => {
                  //   setFormData({
                  //     ...formData,
                  //     mob_as_per_invoice: e.target.value,
                  //   });
                  // }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>

              {/* DEMOB Invoice */}
              <div className="flex flex-col items-start justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Demob as per Invoice
                </h1>
                <input
                  type="file"

                  disabled
                  // min={0}
                  // maxLength={25}
                  // value={formData?.demob_as_per_invoice || "N/A"}
                  // onChange={(e) => {
                  //   setFormData({
                  //     ...formData,
                  //     demob_as_per_invoice: e.target.value,
                  //   });
                  // }}
                  className="w-[400px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
            </div>


          </div>
          <div className="flex gap-3 items-center justify-end">
            <button
              className="bg-white text-[10px] h-7 w-[96px] shadow-md border"
              onClick={() => navigate(-1)} 
            >
              Back
            </button>
            <button className="shadow-md text-white bg-blue-2 px-3 text-xs h-7 w-[96px] rounded-[2px] transition">
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default UpdateLogistics;

