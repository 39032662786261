import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { IoIosClose } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { capitalizeFirstLetter } from "../../Modals/AssetDetails/Tabs/FormatDate";

const NewLeaseTable = ({
  handleExport,
  filteredData,
  setFilteredData,
  leaseDetails,
}) => {
  const [page, setPage] = useState(0); // Current page
  const [pageSize, setPageSize] = useState(10); // Rows per page
  const [searchQuery, setSearchQuery] = useState(""); // Search query
  const [selectedFilter, setSelectedFilter] = useState("All Lease"); // Default to "All Lease"

  const selected = [
    { title: "All Lease", label: "all" },
    { title: "Active", label: ["active", "expiring"] },
    { title: "Closed", label: ["inactive", "never assigned"] },
    { title: "Expired", label: ["expired"] },
  ];

  const applyFilters = () => {
    let data = [...leaseDetails];

    // Apply filter
    if (selectedFilter !== "All Lease") {
      const selectedLabels =
        selected.find((item) => item.title === selectedFilter)?.label || [];
      data = data.filter((item) =>
        Array.isArray(selectedLabels)
          ? selectedLabels.includes(item.lease_status)
          : true
      );
    }

    // Apply search
    if (searchQuery.trim()) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      data = data.filter((item) =>
        Object.values(item).some(
          (value) =>
            value && value.toString().toLowerCase().includes(lowerCaseQuery)
        )
      );
    }

    setFilteredData(data);
    setPage(0); // Reset to the first page
  };

  useEffect(() => {
    applyFilters(); // Initial filter and search application
  }, [leaseDetails, selectedFilter, searchQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  const navigate = useNavigate()

  const columns = [
    { field: "asset_no", headerName: "Asset No", width: 120 },
    { field: "odoo_order_id", headerName: "RSO NO", width: 120 },
    { field: "customer_name", headerName: "Customer Name", width: 120 },
    { field: "gst_no", headerName: "GST No", width: 125 },
    { field: "total_claimable_amount", headerName: "Total Amount", width: 120 },
    { field: "rental_start_date", headerName: "Rental Start Date", width: 120 },
    { field: "rental_end_date", headerName: "Rental End Date", width: 120 },
    {
      field: "lease_status",
      headerName: "Lease Status",
      width: 120,
      renderCell: (params) => {
        let status = params.value;
        const color =
          status === "inactive"
            ? "gray"
            : status === "expired"
            ? "red"
            : "green";
        return (
          <button className={`buttons text-xs border`} style={{ color }}>
            {status === "inactive" ? "Closed" : capitalizeFirstLetter(status)}
          </button>
        );
      },
    },
    {
      field: "details",
      headerName: "See More",
      width: 120,
      renderCell: (params) => (
        // <Button
        // variant="contained"

        //   // to={`/lease/leaseDetails/${params.row.id}`}
        //   // className="bg-blue-2 text-white  hover:bg-blue-1 buttons"
        // >
        //   See Details
        // </Button>
        <Button
        onClick={() => navigate(`/lease/leaseDetails/${params.row.id}`)}
          variant="contained"
          sx={{
            height: "32px",
            width: "96px",
            fontSize: 12,
            // backgroundColor: "blue", // Optional customizations
            "&:hover": {
              // backgroundColor: "darkblue", // Hover effect
            },
          }}
        >
          See Details
        </Button>
      ),
    },
  ];

  return (
    <div className="fixed right-3 left-[5%] bg-white">
      <Paper
        style={{
          padding: 10,
          height: "79vh",
          width: "100%",
          border: "hidden",
        }}
        elevation={0}
      >
        <div className="flex justify-between items-center my-2  ">
          {/* Search and Filter */}
          <div className="flex gap-2 items-center">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && applyFilters()}
              placeholder="Search leases"
              style={{
                height: 30,
                width: 200,
                padding: "5px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: 12,
              }}
            />
            <FormControl
              fullWidth
              sx={{
                "& .MuiInputLabel-root": { fontSize: "12px" },
                "& .MuiSelect-select": { fontSize: "12px" },
                "& .MuiMenuItem-root": { fontSize: "12px" },
                "& .MuiInputBase-root": {
                  backgroundColor: "#fff",
                },
                "& .MuiInputBase-root:hover": {
                  backgroundColor: "#fff",
                },
              }}
            >
              <Select
                style={{
                  height: 30,
                  width: 300,
                  marginTop: 5,
                  fontSize: 10,
                  marginBottom: 2,
                }}
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
              >
                {selected.map((option) => (
                  <MenuItem key={option.title} value={option.title}>
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="flex gap-2 items-center">
            <TablePagination
              component="div"
              count={filteredData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 30, 50, filteredData.length]}
            />
            <NewAssetBtn tabName="lease" />
            <button
              variant="contained"
              onClick={handleExport}
              className="buttons border "
            >
              Export CSV
            </button>
          </div>
        </div>

        <DataGrid
          rows={filteredData.slice(page * pageSize, page * pageSize + pageSize)}
          columns={columns}
          checkboxSelection
          pageSize={pageSize}
          pagination={false} // Disable default pagination
          sx={{
            "& .MuiDataGrid-root": { fontSize: "12px", height: 30 }, // Set font size for the entire grid
            "& .MuiDataGrid-columnHeaders": {
              fontSize: "14px", // Header font size
              backgroundColor: "#000", // Header background color
              fontWeight: "bold", // Bold text for headers
              color: "#006DB6", // Header text color
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold", // Ensures text in header is bold
              height: 20,
            },
            "& .MuiDataGrid-cell": { fontSize: "12px" }, // Set font size for cell text
            "& .MuiDataGrid-virtualScroller": {
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            },
          }}
        />
      </Paper>
    </div>
  );
};

export default NewLeaseTable;
