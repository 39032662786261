import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../config";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Button, Card, CardContent, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Layout from "../components/Layout";
import SeeBookingDetails from "../components/Modals/Booking/SeeBookingDetails";
import ApprovalForm from "../components/Modals/Booking/ApprovalForm";
import DiscardApprovedForm from "../components/Modals/Booking/DiscardApprovedForm";

const BookingData = () => {
  const [bookingData, setBookingData] = useState([]); // Original data from API
  const [filteredData, setFilteredData] = useState([]); // Filtered data based on search
  const [searchQuery, setSearchQuery] = useState(""); // Search input
  const [page, setPage] = useState(0); // Current page
  const [pageSize, setPageSize] = useState(10);

  const [selectedAsset, setSelectedAsset] = useState(null); // Selected asset for modal
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const navigate = useNavigate();

  const getBookingData = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-asset-booking-data`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });

      const data = await res.json();
      setBookingData(data);
      setFilteredData(data); // Set both original and filtered data initially
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBookingData();
  }, []);

  // Handle search filtering
  useEffect(() => {
    if (!searchQuery) {
      setFilteredData(bookingData); // Reset data if search is cleared
    } else {
      const lowerCaseQuery = searchQuery.toLowerCase();
      setFilteredData(
        bookingData.filter((row) =>
          Object.values(row).some(
            (value) =>
              value && value.toString().toLowerCase().includes(lowerCaseQuery)
          )
        )
      );
    }
  }, [searchQuery, bookingData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let value = event.target.value;
    setPageSize(value === "All data" ? filteredData.length : value);
    setPage(0); // Reset to first page
  };

  // const columns = [
  //   { field: "counter", headerName: "#", width: 80, renderCell: (params) => <>{params.row.counter}</> },
  //   { field: "asset_no", headerName: "Asset No", width: 120 },
  //   { field: "capacity", headerName: "Capacity", width: 120 },
  //   { field: "category", headerName: "Category", width: 120 },
  //   { field: "hieght_machine", headerName: "Machine Height", width: 120 },
  //   { field: "make", headerName: "Brand", width: 120 },
  //   { field: "monthly_rental_amount", headerName: "Monthly Rental Amount", width: 120 },
  //   {
  //     field: "rental_end_date",
  //     headerName: "Rental End Date",
  //     width: 120,
  //     renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
  //   },
  //   { field: "total_working_days", headerName: "Working Days", width: 120 },
  //   { field: "yom", headerName: "Production Year", width: 120 },
  //   { field: "description", headerName: "Description", width: 160 },
  // ];

  // Open Modal for Booking
  const openBookingModal = (asset) => {
    setSelectedAsset(asset);
    setIsBookingModalOpen(true);
  };

  // Open Modal for Viewing Details
  const openDetailsModal = (asset) => {
    setSelectedAsset(asset);
    setIsDetailsModalOpen(true);
  };

  const openStatusModal = (asset) => {
    setSelectedAsset(asset);
    setIsStatusModalOpen(true);
  };

  const openDiscardModal = (asset) => {
    setSelectedAsset(asset);
    setIsDiscardModalOpen(true);
  };

  // Close Modals
  const closeBookingModal = () => setIsBookingModalOpen(false);
  const closeDetailsModal = () => setIsDetailsModalOpen(false);
  const closeStatusModal = () => setIsStatusModalOpen(false);
  const closeDiscardModal = () => setIsDiscardModalOpen(false);

  const columns = [
    {
      field: "counter",
      headerName: "#",
      width: 50,
      renderCell: (params) => <>{params.row.counter}</>,
    },
    { field: "asset_no", headerName: "Asset No", width: 120 },
    { field: "make", headerName: "Brand", width: 120 },
    { field: "category", headerName: "Category", width: 120 },
    {
      field: "monthly_rental_amount",
      headerName: "Monthly Rental",
      width: 140,
    },
    { field: "yom", headerName: "Production Year", width: 120 },
    { field: "capacity", headerName: "Capacity", width: 120 },
    { field: "hieght_machine", headerName: "Machine Height", width: 120 },
    {
      field: "rental_end_date",
      headerName: "Rental End Date",
      width: 130,
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    {
      headerName: "Status",
      field: "booking",
      width: 180,
      renderCell: (params) => {
        const bookings = params.row.booking || [];
        const isApproved = bookings.some((b) => b.booking_is_approved === true);
        const isAvailable = bookings.length === 0; // No bookings mean available
    
        return (
          <Button
            variant="outlined"
            color={isApproved ? "primary" : isAvailable ? "success" : "error"} // Change color for "Booked"
            size="small"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              padding: "6px 12px",
              fontSize: "12px", // Equivalent to text-xs
              justifyContent: "center",
              border: "none",
              marginTop: "8px",
              textTransform: "capitalize",
            }}
          >
            {isApproved ? "Alloted" : isAvailable ? "Available for Booking" : "Pending"}
          </Button>
        );
      },
    },
    
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 200,
    //   renderCell: (params) => {
    //     const isAvailable = params.row.booking === null;
    //     return (
    //       <div className="flex gap-2">
    //         {isAvailable ? (
    //           <Button
    //             variant="contained"
    //             color="primary"
    //             onClick={() => openBookingModal(params.row)}
    //           >
    //             Book Now
    //           </Button>
    //         ) : (
    //           <Button
    //             variant="outlined"
    //             color="secondary"
    //             onClick={() => openDetailsModal(params.row)}
    //           >
    //             Request Arrived
    //           </Button>
    //         )}
    //       </div>
    //     );
    //   },
    // },

    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => {
        const isAvailable = params.row.booking === null;
        const bookings = params.row.booking || [];
        const isApproved = bookings.some((b) => b.booking_is_approved === true);
    
        return (
          <div className="flex gap-2">
            {!isAvailable && (
              <>
                {!isApproved ? (
                  <Button
                    sx={{
                      fontSize: "12px",
                      marginTop: "6px",
                      textTransform: "capitalize",
                    }}
                    variant="outlined"
                    color="secondary"
                    onClick={() => openDetailsModal(params.row)}
                  >
                    Request Arrived
                  </Button>
                ) : <Button
                sx={{
                  fontSize: "12px",
                  marginTop: "6px",
                  textTransform: "capitalize",
                }}
                variant="outlined"
                color="secondary"
                onClick={() => openDiscardModal(params.row)}
              >
             Edit Approval
              </Button>}
    
                <Button
                  sx={{
                    fontSize: "12px",
                    marginTop: "6px",
                    textTransform: "capitalize",
                  }}
                  variant="contained"
                  color="info"
                  onClick={() => openStatusModal(params.row)}
                >
                  See Status
                </Button>

              </>
            ) }
          </div>
        );
      },
    }
    
  ];

  return (
    <Layout>
      <div>
        <Paper sx={{ width: "100%", marginBottom: 24 }}>
              <div className="flex justify-between p-4">
                {/* Global Search Input */}
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search booking details..."
                  className="border border-gray-300 bg-gray-50 h-[30px] w-[250px] p-2 rounded text-sm"
                />

                <TablePagination
                  component="div"
                  count={filteredData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[10, 30, 50, "All data"]}
                  labelRowsPerPage="Leases:"
                  SelectProps={{
                    renderValue: (value) =>
                      value === "All data" ? "All data" : value,
                  }}
                />
              </div>
          {filteredData.length > 0 && (
            <>

              <DataGrid
                rows={
                  pageSize === filteredData.length
                    ? filteredData.map((row, index) => ({
                      ...row,
                      counter: index + 1,
                    }))
                    : filteredData
                      .slice(page * pageSize, page * pageSize + pageSize)
                      .map((row, index) => ({
                        ...row,
                        counter: page * pageSize + index + 1,
                      }))
                }
                columns={columns}
                pageSize={pageSize}
                paginationMode="server"
                pagination={false}
                disableSelectionOnClick
                disableColumnMenu
                disableColumnReordering
                sx={{
                  "& .MuiDataGrid-footerContainer": {
                    display: "none", // Hides the footer container
                  },

                  "& .MuiDataGrid-root": { fontSize: "12px", height: 30 },
                  "& .MuiDataGrid-columnHeaders": {
                    fontSize: "12px",

                    fontWeight: "bold",
                    color: "#006DB6",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "bold",
                    height: 20,
                  },
                  "& .MuiDataGrid-cell": {
                    fontSize: "12px",
                    cursor: "pointer",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    "-ms-overflow-style": "none",
                    "scrollbar-width": "none",
                  },
                  "& .MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                }}
              />
            </>
          )}

          <Modal open={isBookingModalOpen} onClose={closeBookingModal}>
            <Paper className="p-6 w-[500px] mx-auto mt-20">
              No Booking Updated Yet
              {/* Booking Form Here */}
              <Button
                sx={{
                  fontSize: "12px",
                }}
                onClick={closeBookingModal}
              >
                Close
              </Button>
            </Paper>
          </Modal>


          <Modal open={isDiscardModalOpen} onClose={closeDiscardModal}>
                <Paper className="p-6 w-fit mx-auto mt-20">
                  <span variant="h5 " sx={{ fontSize: "12px" }} className="font-medium mb-4 text-base">
                    Edit Approval for {selectedAsset?.asset_no}
                  </span>
                  <DiscardApprovedForm
                    assetNo={selectedAsset?.asset_no}
                    id={selectedAsset?.id}
                    bookingDetails={selectedAsset?.booking}
                  />
                  <Button onClick={() => closeDiscardModal()}>Close</Button>
                  </Paper>
          </Modal>

          {/* Status Modal */}
          <Modal
            open={isStatusModalOpen}
            onClose={() => setIsStatusModalOpen(false)}
          >
            <Paper className="p-6 w-fit mx-auto mt-20">
              <span variant="h5" sx={{ fontSize: "12px" }} className="font-bold mb-4 text-base">
                Booking Status for {selectedAsset?.asset_no}
              </span>
              <SeeBookingDetails
                assetNo={selectedAsset?.asset_no}
                bookingDetails={selectedAsset?.booking}
              />


              <Button onClick={() => setIsStatusModalOpen(false)}>Close</Button>
            </Paper>
          </Modal>

          {/* Details Modal */}
          <Modal open={isDetailsModalOpen} onClose={closeDetailsModal}>
            <Paper className="p-6 w-[70rem] mx-auto mt-20">
              <ApprovalForm
                assetNo={selectedAsset?.asset_no}
                id={selectedAsset?.id}
                bookingDetails={selectedAsset?.booking}
              />

              <Button
                variant=""
                color=""

                sx={{
                  fontSize: "12px",
                }}
                onClick={closeDetailsModal}
              >
                Close
              </Button>
            </Paper>
          </Modal>
        </Paper>
      </div>
    </Layout>
  );
};

export default BookingData;
